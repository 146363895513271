.with-submenu .submenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: var(--sally-submenu-background);
  border-bottom: 1px solid var(--sally-submenu-border);
  box-shadow: 0 2px 6px 0 rgba(206, 46, 42, 0.04);
}
.with-submenu .submenu a {
  display: inline-block;
  height: 50px;
  line-height: 44px;
  margin-right: 36px;
  padding: 0 4px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-submenu-item-text);
}
.with-submenu .submenu a:hover {
  text-decoration: none;
  color: var(--sally-submenu-item-hover-text);
}
.with-submenu .submenu a.active {
  border-bottom-color: var(--sally-submenu-item-active-border);
  color: var(--sally-submenu-item-active-text);
  font-weight: var(--sally-submenu-active-weight);
}
.with-submenu .submenu a:last-child {
  margin-right: 0;
}
.with-submenu .with-submenu-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 55px 0 0 0;
}

.with-submenu .submenu .approval-count, .main-menu .ant-menu .approval-count {
  margin-left: 5px;
  border-radius: 20px;
  padding: 7px 10px;
  background: var(--sally-menu-nubbin-background);
  color: var(--sally-menu-nubbin-text);
}

body .ant-card .ant-card-body .ant-table-wrapper .ant-table, body .subcard .ant-card-body .ant-table-wrapper .ant-table {
  margin: 0 -30px 0 -30px;
}
body .ant-card .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table, body .subcard .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table {
  padding: 0;
}
body .ant-card .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr td:first-child, body .ant-card .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr th:first-child, body .subcard .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr td:first-child, body .subcard .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr th:first-child {
  padding-left: 30px;
}
body .ant-card .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr td:last-child, body .ant-card .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr th:last-child, body .subcard .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr td:last-child, body .subcard .ant-card-body .ant-table-wrapper .ant-table > .ant-table-content > .ant-table-body > table tr th:last-child {
  padding-right: 30px;
}

