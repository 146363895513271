body .login-register {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 788px;
  min-height: 458px;
  margin: -229px 0 0 -394px;
  display: flex;
  flex-direction: row;
}
body .login-register .register-form {
  width: 412px;
  padding: 0 29px 5px;
}
body .login-register .register-form svg.logo {
  height: 50px;
  width: 160px;
}
body .login-register .register-form svg.logo .logo-fill {
  fill: var(--sally-text-header);
}
body .login-register .register-form .h1 {
  font-size: 20pt;
  margin-bottom: 5px;
  margin-right: -20px;
}
body .login-register .register-form > p {
  margin: 5px auto 20px auto;
  width: 280px;
  max-width: 100%;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
}
body .login-register .register-form .ant-checkbox-wrapper {
  margin-top: 3px;
  font-size: 14px;
}
body .login-register .register-form .ant-btn[type=submit] {
  margin-top: 8px;
  width: 100%;
}
body .login-register .register-form .ant-form .ant-row + .ant-row, body .login-register .register-form .ant-form .ant-subrow + .ant-row {
  margin-top: 4px;
}
body .login-register .register-form .ant-form-item-control-wrapper {
  padding: 0 2px 0 11px;
}
body .login-register .register-form .ant-form-item-control label {
  margin: 0;
}
body .login-register .register-presentation {
  width: 410px;
  background: var(--sally-registation-background);
  color: var(--sally-registation-text);
  padding: 50px 25px;
  font-size: 12pt;
  border-radius: 10px;
}
body .login-register .register-presentation .h1 {
  color: var(--sally-registation-text);
  font-size: 22pt;
  margin-bottom: 15px;
}
body .login-register .register-presentation ol {
  list-style: outside;
  padding: 0;
  margin-left: 15px;
}
@media screen and (max-width: 780px) {
  body .login-register .ant-card-body .register-form {
    width: 100%;
    padding: 45px 10px 5px;
  }
  body .login-register .ant-card-body .register-form .ant-form .ant-row {
    margin-right: 0;
  }
  body .login-register .ant-card-body .register-presentation {
    width: 100%;
    padding: 5px 10px;
  }
}
body .terms {
  position: relative;
  padding: 30px 34px 65px 34px;
  text-align: right;
}
body .terms .terms-text {
  overflow-y: scroll;
  max-height: 470px;
  padding-bottom: 15px;
  text-align: left;
}
body .terms .terms-text .h3 {
  margin-top: 20px;
}
body .terms .terms-text p {
  line-height: 22px;
  font-size: 14px;
  color: var(--sally-grey-shaded);
}
body .terms .terms-footer {
  position: absolute;
  top: 485px;
  right: 34px;
  left: 34px;
  padding-top: 20px;
  box-shadow: 0 -4px 6px 0 #edf0f4;
}
body .terms .terms-footer:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: -5px;
  display: block;
  height: 100%;
  background-color: #fff;
}
body .terms.terms-scrolled .terms-footer {
  box-shadow: none;
}
body .terms.full-height {
  height: 100%;
  background-color: #fff;
}
body .terms.full-height .terms-text {
  overflow-y: auto;
  max-height: none;
}

