.ant-input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: var(--sally-input-m-height);
  font-size: 14px;
  line-height: 1.5;
  color: var(--sally-input-text);
  background-color: var(--sally-input-background);
  background-image: none;
  border: 1px solid var(--sally-input-border);
  border-radius: var(--sally-radius-4);
  transition: all 0.3s;
  font-weight: 400;
}
.ant-input::-moz-placeholder, .ant-input:-ms-input-placeholder, .ant-input::-webkit-input-placeholder {
  color: var(--sally-input-placeholder-text);
  opacity: 1;
}
.ant-input:-webkit-autofill, .ant-input:-webkit-autofill:hover, .ant-input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--sally-input-background) inset;
}
.ant-input:hover {
  border-color: var(--sally-input-hover-border);
}
.ant-input:focus {
  border-color: var(--sally-input-focus-border);
  box-shadow: 0 2px 4px 0 var(--sally-input-focus-shadow);
  outline: 0;
}
.ant-input.ant-input-disabled {
  color: var(--sally-input-disabled-text);
  background-color: var(--sally-input-disabled-background);
  border-color: var(--sally-input-disabled-border);
}
.ant-input.ant-input-xl {
  padding: 8px 12px 8px 12px;
  height: var(--sally-input-xl-height);
}
.ant-input.ant-input-l {
  padding: 8px 12px 8px 12px;
  height: var(--sally-input-l-height);
}

.ant-input-search .ant-input-search-icon {
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
.ant-input-group[class*=col-] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.ant-input-group > [class*=col-] {
  padding-right: 8px;
}
.ant-input-group > [class*=col-]:last-child {
  padding-right: 0;
}

.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group {
  height: var(--sally-input-m-height);
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  height: var(--sally-input-m-height);
}
.ant-input-group .ant-input:focus {
  z-index: 1;
}
.ant-input-group > .ant-input:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.ant-input-group > .ant-input:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.ant-input-group.ant-input-group-l {
  height: var(--sally-input-l-height);
}
.ant-input-group.ant-input-group-l .ant-input, .ant-input-group.ant-input-group-l .ant-select-selection--single {
  height: var(--sally-input-l-height);
}
.ant-input-group.ant-input-group-xl {
  height: var(--sally-input-xl-height);
}
.ant-input-group.ant-input-group-xl .ant-input, .ant-input-group.ant-input-group-xl .ant-select-selection--single {
  height: var(--sally-input-xl-height);
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: left;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact:before, .ant-input-group.ant-input-group-compact:after {
  content: " ";
  display: table;
}
.ant-input-group.ant-input-group-compact:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-right-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
  z-index: auto;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-right-width: 0;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}

.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: var(--sally-input-background);
}
.ant-input-affix-wrapper .ant-input {
  z-index: 2;
  background: transparent;
  min-height: 100%;
}
.ant-input-affix-wrapper .ant-input.ant-input-right-align {
  text-align: right;
}
.ant-input-affix-wrapper .ant-input.ant-input-right-align:not(:last-child) {
  padding-right: 48px;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--sally-input-border);
}
.ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  line-height: 0;
  color: var(--sally-input-text);
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 7px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 7px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 24px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 24px;
}

