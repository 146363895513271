@use 'sass:math';

body {
    .login-register {
        $width: 788px;
        $height: 458px;
        position: fixed;
        top: 50%;
        left: 50%;
        width: $width;
        min-height: $height;
        margin: math.div(-$height, 2) 0 0 math.div(-$width, 2);
        display: flex;
        flex-direction: row;

        .register-form {
            width: 412px;
            padding: 0 29px 5px;

            svg.logo {
                height: 50px;
                width: 160px;

                .logo-fill {
                    fill: var(--sally-text-header);
                }
            }

            .h1 {
                font-size: 20pt;
                margin-bottom: 5px;
                margin-right: -20px;
            }
            > p {
                margin: 5px auto 20px auto;
                width: 280px;
                max-width: 100%;
                text-align: center;
                line-height: 22px;
                font-size: 14px;
            }
            .ant-checkbox-wrapper {
                margin-top: 3px;
                font-size: 14px;
            }
            .ant-btn[type="submit"] {
                margin-top: 8px;
                width: 100%;
            }
            .ant-form .ant-row + .ant-row, .ant-form .ant-subrow + .ant-row {
                margin-top: 4px;
            }
            .ant-form-item-control-wrapper {
                padding: 0 2px 0 11px;
            }
            .ant-form-item-control label {
                margin: 0;
            }
        }
        .register-presentation {
            width: 410px;
            background: var(--sally-registation-background);
            color: var(--sally-registation-text);
            padding: 50px 25px;
            font-size: 12pt;
            border-radius: 10px;

            .h1 {
                color: var(--sally-registation-text);
                font-size: 22pt;
                margin-bottom: 15px;
            }
            ol {
                list-style: outside;
                padding: 0;
                margin-left: 15px;
            }
        }
        @media screen and (max-width: 780px) {
            .ant-card-body {
                .register-form {
                    width: 100%;
                    padding: 45px 10px 5px;
                    .ant-form .ant-row {
                        margin-right: 0;
                    }
                }
                .register-presentation {
                    width: 100%;
                    padding: 5px 10px;
                }
            }
        }
    }
    .terms {
        position: relative;
        padding: 30px 34px 65px 34px;
        text-align: right;

        .terms-text {
            overflow-y: scroll;
            max-height: 470px;
            padding-bottom: 15px;
            text-align: left;

            .h3 {
                margin-top: 20px;
            }
            p {
                line-height: 22px;
                font-size: 14px;
                color: var(--sally-grey-shaded);
            }
        }
        .terms-footer {
            position: absolute;
            top: 485px;
            right: 34px;
            left: 34px;
            padding-top: 20px;
            box-shadow: 0 -4px 6px 0 rgba(237, 240, 244, 1);

            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                right: -5px;
                bottom: 0;
                left: -5px;
                display: block;
                height: 100%;
                background-color: #fff;
            }
        }
        &.terms-scrolled {
            .terms-footer {
                box-shadow: none;
            }
        }

        &.full-height {
            height: 100%;
            background-color: #fff;

            .terms-text {
                overflow-y: auto;
                max-height: none;
            }
        }
    }
}