body .user-survey {
  z-index: 1;
  position: fixed;
  left: 15px;
  bottom: 15px;
  width: 400px;

  &.ant-card.ant-card-bordered {
    border-radius: 30px;

    & .ant-card-body {
      padding: 45px 30px;

      p {
        font-size: 14px;
        line-height: 22px;

        &:first-child {
          margin-bottom: 15px;
        }
      }

      .ant-radio-group {
        margin-top: 10px;
        text-align: center;
        display: block;

        label {
          font-size: 12px;
        }

        .ant-radio-button-wrapper {
          padding: 0 10px;

          & + .ant-radio-button-wrapper {
            border-left-width: 0;
          }
        }
      }

      textarea.ant-input {
        height: 70px;
      }

      .user-survey-button-row {
        margin-top: 25px;

        .ant-btn {
          min-width: 75px;
          padding: 4px 12px;
          font-size: 12px;
          min-height: 32px;
          font-weight: normal;

          & + .ant-btn {
            margin-left: 15px;
          }
        }
      }
    }
    .user-survey-help-row {
      font-size: 8pt;
      color: var(--sally-grey-shaded);
      display: flex;
      flex-direction: row;
      margin: 0 -5px;
      justify-content: space-between;
    }
  }
}