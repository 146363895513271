.freelancers-single {}

.ant-card.ant-card-bordered.no-contract-card, .ant-card.ant-card-bordered.no-employment-card {
  .ant-card-body {
    background-position: 30px center;
    background-repeat: no-repeat;
    background-size: 40px 40px;

    p {
      margin: 0;
      font-size: 14px;
      color: var(--sally-grey-shaded);
    }
    button {
      position: absolute;
      top: 45%;
      right: 40px;
      display: inline-block;
      line-height: 20px;
      margin-top: -10px;
      padding-right: 15px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .ant-card-body, &:hover .ant-card-body {
    padding: 25px 110px 25px 90px;
  }
}