.freelancers-overview {

  .title-menu{
    display: flex;
      margin-bottom: 2em;
  }
  .h1 {
    margin-bottom: 10px;

    & + p {
      font-size: 14px;
    }
  }
  .h3 {
    line-height: inherit;

    > .user-image {
      float: left;
      margin: 3px 18px 0 0;
    }
  }
  .ant-table .ant-table-row {
    cursor: pointer;

    td > a {
      display: inline-block;
      padding: 5px 0;
      color: var(--sally-orange);
    }
  }
}

.title-and-back-btn {
  display: flex;
  align-content:center;
  justify-content: space-between;
}