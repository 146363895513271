body .user-survey {
  z-index: 1;
  position: fixed;
  left: 15px;
  bottom: 15px;
  width: 400px;
}
body .user-survey.ant-card.ant-card-bordered {
  border-radius: 30px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body {
  padding: 45px 30px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body p {
  font-size: 14px;
  line-height: 22px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body p:first-child {
  margin-bottom: 15px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .ant-radio-group {
  margin-top: 10px;
  text-align: center;
  display: block;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .ant-radio-group label {
  font-size: 12px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .ant-radio-group .ant-radio-button-wrapper {
  padding: 0 10px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .ant-radio-group .ant-radio-button-wrapper + .ant-radio-button-wrapper {
  border-left-width: 0;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body textarea.ant-input {
  height: 70px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .user-survey-button-row {
  margin-top: 25px;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .user-survey-button-row .ant-btn {
  min-width: 75px;
  padding: 4px 12px;
  font-size: 12px;
  min-height: 32px;
  font-weight: normal;
}
body .user-survey.ant-card.ant-card-bordered .ant-card-body .user-survey-button-row .ant-btn + .ant-btn {
  margin-left: 15px;
}
body .user-survey.ant-card.ant-card-bordered .user-survey-help-row {
  font-size: 8pt;
  color: var(--sally-grey-shaded);
  display: flex;
  flex-direction: row;
  margin: 0 -5px;
  justify-content: space-between;
}

