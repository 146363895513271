body.theme-classic {
  // --------- Standard colour usage
  --sally-background: var(--sally-white-faded);
  --sally-background-layer-1: var(--sally-white);
  --sally-text: var(--sally-black);
  --sally-paragraph-text: var(--sally-grey-shaded);
  --sally-warning-text: var(--sally-orange);
  --sally-error-text: var(--sally-red);
  --sally-link: var(--sally-orange);
  --sally-link-hover: var(--sally-orange-faded);
  --sally-subdued-link: var(--sally-grey);
  --sally-subdued-link-hover: var(--sally-grey-shaded);
  --sally-alert-background: var(--sally-red);
  --sally-alert-text: var(--sally-white);
  --sally-code-border: var(--sally-grey-shaded);

  // --- Global message
  --sally-global-message-background: var(--sally-orange);
  --sally-global-message-text: var(--sally-white);

  // --- Dashboard
  --sally-dashboard-arrow-background: var(--sally-black);
  --sally-dashboard-arrow: var(--sally-white);

  // --- Header menu
  --sally-header-background: var(--sally-green);
  --sally-header-border: var(--sally-green);
  --sally-header-submenu-background: var(--sally-green-faded);
  --sally-header-submenu-divider: var(--sally-green-shaded);
  --sally-header-text: var(--sally-white);
  --sally-header-line-height: 52px;
  --sally-header-weight: 500;
  --sally-logo-text: var(--sally-white);
  --sally-header-item-vertical-margin: 0;
  --sally-header-item-horizontal-margin: 0;
  --sally-header-item-horizontal-padding: 12px;
  --sally-header-item-radius: 0;
  --sally-header-item-hover-background: var(--sally-green-shaded);
  --sally-header-item-hover-text: var(--sally-white);
  --sally-header-item-selected-background: var(--sally-green-shaded);
  --sally-header-user-image-background: var(--sally-orange);
  --sally-header-user-image-text: var(--sally-white);

  // --- Left menu -- TODO Fill in these colours
  --sally-menu-background: var(--sally-white);
  --sally-menu-item: var(--ageras-white);
  --sally-menu-item-text: var(--ageras-black);
  --sally-menu-shadow: var(--ageras-black);
  --sally-menu-submenu-item-text: var(--ageras-grey-90);
  --sally-menu-item-hover: var(--ageras-grey-10);
  --sally-menu-item-hover-text: var(--ageras-black);
  --sally-menu-item-active: var(--ageras-deep-sea-green);
  --sally-menu-item-active-text: var(--ageras-white);
  --sally-menu-divider: var(--ageras-grey-10);

  // --- Submenu
  --sally-submenu-active-weight: 500;
  --sally-submenu-background: var(--sally-white);
  --sally-submenu-border: var(--sally-white-shaded);
  --sally-submenu-item-text: var(--sally-grey-shaded);
  --sally-submenu-item-hover-text: var(--sally-black);
  --sally-submenu-item-active-text: var(--sally-black);
  --sally-submenu-item-active-border: var(--sally-green-shaded);
  --sally-menu-nubbin-text: var(--sally-grey-shaded);
  --sally-menu-nubbin-background: var(--sally-white-shaded);

  // --- User image
  --sally-user-image-background: var(--sally-orange);
  --sally-user-image-text: var(--sally-white);

  // --- Table
  --sally-table-background: var(--sally-white);
  --sally-table-text: var(--sally-black);
  --sally-table-secondary-text: var(--sally-grey-shaded);
  --sally-table-border: var(--sally-white-shaded);
  --sally-table-header-background: var(--sally-white);
  --sally-table-header-text: var(--sally-grey-shaded);
  --sally-table-selected-row: var(--sally-grey-faded);
  --sally-table-loading-background: var(--sally-white);
  --sally-table-divider: var(--sally-white-shaded);
  --sally-table-sorter: var(--sally-grey-shaded);
  --sally-table-sorter-active: var(--sally-black-faded);
  --sally-table-empty-text: var(--sally-grey);
  // Pagination
  --sally-pagination-background: var(--sally-white);
  --sally-pagination-item-text: var(--sally-grey-shaded);
  --sally-pagination-item-background: var(--sally-white);
  --sally-pagination-item-border: var(--sally-white-shaded);
  --sally-pagination-item-active-text: var(--sally-white);
  --sally-pagination-item-active-background: var(--sally-orange);
  --sally-pagination-item-border-radius: 4px;

  // --- Button
  --sally-button-box-shadow: var(--sally-white-shaded);
  // Text
  --sally-button-text-text: var(--sally-black);
  --sally-button-text-background: var(--sally-white);
  --sally-button-text-border: var(--sally-grey);
  --sally-button-text-danger-text: var(--sally-white);
  --sally-button-text-danger-background: var(--sally-red);
  --sally-button-text-hover-background: var(--sally-white-faded);
  --sally-button-text-danger-hover-text: var(--sally-white);
  --sally-button-text-danger-hover-background: var(--sally-red-faded);
  --sally-button-text-focus-border: var(--sally-black);
  --sally-button-text-active-text: var(--sally-black);
  --sally-button-text-active-background: var(--sally-white-shaded);
  --sally-button-text-danger-active-background: var(--sally-red-shaded);
  --sally-button-text-loading-text: var(--sally-black-faded);
  --sally-button-text-disabled-text: var(--sally-black-faded);
  --sally-button-text-disabled-background: var(--sally-grey-shaded);
  // Tertiary
  --sally-button-tertiary-text: var(--sally-black);
  --sally-button-tertiary-background: var(--sally-white);
  --sally-button-tertiary-border: var(--sally-grey);
  --sally-button-tertiary-danger-text: var(--sally-white);
  --sally-button-tertiary-danger-background: var(--sally-red);
  --sally-button-tertiary-danger-border: var(--sally-red);
  --sally-button-tertiary-hover-background: var(--sally-white-faded);
  --sally-button-tertiary-danger-hover-text: var(--sally-white);
  --sally-button-tertiary-danger-hover-background: var(--sally-red-faded);
  --sally-button-tertiary-focus-border: var(--sally-black);
  --sally-button-tertiary-active-background: var(--sally-white-shaded);
  --sally-button-tertiary-danger-active-background: var(--sally-red-shaded);
  --sally-button-tertiary-loading-text: var(--sally-black-faded);
  --sally-button-tertiary-loading-background: var(--sally-white);
  --sally-button-tertiary-danger-loading-background: var(--sally-red-faded);
  --sally-button-tertiary-disabled-text: var(--sally-black-faded);
  --sally-button-tertiary-disabled-background: var(--sally-grey-shaded);
  // Secondary
  --sally-button-secondary-text: var(--sally-white);
  --sally-button-secondary-background: var(--sally-green);
  --sally-button-secondary-border: var(--sally-green-shaded);
  --sally-button-secondary-danger-text: var(--sally-white);
  --sally-button-secondary-danger-background: var(--sally-red);
  --sally-button-secondary-danger-border: var(--sally-red);
  --sally-button-secondary-hover-background: var(--sally-green-faded);
  --sally-button-secondary-danger-hover-text: var(--sally-white);
  --sally-button-secondary-danger-hover-background: var(--sally-red-faded);
  --sally-button-secondary-focus-border: var(--sally-black);
  --sally-button-secondary-active-background: var(--sally-green-shaded);
  --sally-button-secondary-danger-active-background: var(--sally-red-shaded);
  --sally-button-secondary-loading-text: var(--sally-grey-faded);
  --sally-button-secondary-loading-background: var(--sally-white-faded);
  --sally-button-secondary-danger-loading-background: var(--sally-red-faded);
  --sally-button-secondary-disabled-text: var(--sally-white-faded);
  --sally-button-secondary-disabled-background: var(--sally-green-faded);
  // Primary
  --sally-button-primary-text: var(--sally-white);
  --sally-button-primary-background: var(--sally-orange);
  --sally-button-primary-border: var(--sally-orange);
  --sally-button-primary-danger-text: var(--sally-white);
  --sally-button-primary-danger-background: var(--sally-red);
  --sally-button-primary-hover-text: var(--sally-white);
  --sally-button-primary-hover-background: var(--sally-orange-faded);
  --sally-button-primary-danger-hover-background: var(--sally-red-faded);
  --sally-button-primary-focus-border: var(--sally-black);
  --sally-button-primary-active-text: var(--sally-white);
  --sally-button-primary-active-background: var(--sally-orange-shaded);
  --sally-button-primary-danger-active-background: var(--sally-red-shaded);
  --sally-button-primary-loading-text: var(--sally-grey-faded);
  --sally-button-primary-loading-background: var(--sally-white-faded);
  --sally-button-primary-danger-loading-text: var(--sally-white);
  --sally-button-primary-danger-loading-background: var(--sally-red-faded);
  --sally-button-primary-disabled-text: var(--sally-white-faded);
  --sally-button-primary-disabled-background: var(--sally-orange-faded);

  // --- Input
  --sally-input-text: var(--sally-black);
  --sally-input-placeholder-text: var(--sally-grey);
  --sally-input-background: var(--sally-white);
  --sally-input-border: var(--sally-white-shaded);
  --sally-input-hover-border: var(--sally-grey-shaded);
  --sally-input-focus-border: var(--sally-orange);
  --sally-input-focus-shadow: var(--sally-white-faded);
  --sally-input-disabled-text: var(--sally-grey-shaded);
  --sally-input-disabled-background: var(--sally-white-shaded);
  --sally-input-disabled-border: var(--sally-white-shaded);
  --sally-input-error-text: var(--sally-black);
  --sally-input-error-background: var(--sally-white);
  --sally-input-error-border: var(--sally-red);

  // --- Select
  --sally-select-padding: 0;
  --sally-select-item-radius: 0;
  --sally-select-item-hover-text: var(--sally-black);
  --sally-select-item-hover-background: var(--sally-white-shaded);
  --sally-select-item-selected-background: var(--sally-grey-faded);
  --sally-select-item-divider: var(--sally-white-shaded);

  // --- Toggle
  --sally-toggle-text: var(--sally-white);
  --sally-toggle-hover-text: var(--sally-white);
  --sally-toggle-hover-background: var(--sally-white);
  --sally-toggle-focus-text: var(--sally-white);
  --sally-toggle-focus-background: var(--sally-white);
  --sally-toggle-focus-border: var(--sally-white-shaded);
  --sally-toggle-checked-text: var(--sally-white);
  --sally-toggle-checked-background: var(--sally-green);
  --sally-toggle-checked-border: var(--sally-green-shaded);
  --sally-switch-background: var(--sally-grey);
  --sally-switch-nubbin: var(--sally-white);
  --sally-switch-nubbin-shadow: var(--sally-grey-faded);
  --sally-switch-checked-nubbin-shadow: var(--sally-green-shaded);
  --sally-switch-hover-background: var(--sally-grey-shaded);
  --sally-toggle-checked-hover-background: var(--sally-green-shaded);
  --sally-switch-disabled-background: var(--sally-grey-faded);

  // --- Navigator
  --sally-navigator-background: var(--sally-white-shaded);
  --sally-navigator-text: var(--sally-black-faded);
  --sally-navigator-selected-background: var(--sally-green);
  --sally-navigator-selected-text: var(--sally-white);
  --sally-navigator-hover-background: var(--sally-green-faded);
  --sally-navigator-hover-text: var(--sally-white);

  // --- Notification levels
  --sally-status-awaiting-user-text-on-white: var(--sally-yellow);
  --sally-status-awaiting-user-text: var(--sally-white);
  --sally-status-awaiting-user-background: var(--sally-yellow-faded);
  --sally-status-awaiting-user-border: var(--sally-yellow);
  --sally-status-awaiting-system-text-on-white: var(--sally-grey);
  --sally-status-awaiting-system-text: var(--sally-black);
  --sally-status-awaiting-system-background: var(--sally-grey-faded);
  --sally-status-awaiting-system-border: var(--sally-grey);
  --sally-status-info-text-on-white: var(--sally-grey);
  --sally-status-info-text: var(--sally-black);
  --sally-status-info-background: var(--sally-grey-faded);
  --sally-status-info-border: var(--sally-grey);
  --sally-status-success-text-on-white: var(--sally-green);
  --sally-status-success-text: var(--sally-white);
  --sally-status-success-background: var(--sally-green-faded);
  --sally-status-success-border: var(--sally-green);
  --sally-status-warning-text-on-white: var(--sally-orange);
  --sally-status-warning-text: var(--sally-white);
  --sally-status-warning-background: var(--sally-orange-faded);
  --sally-status-warning-border: var(--sally-orange);
  --sally-status-failure-text-on-white: var(--sally-red);
  --sally-status-failure-text: var(--sally-white);
  --sally-status-failure-background: var(--sally-red-faded);
  --sally-status-failure-border: var(--sally-red);

  // --- Elements
  --sally-text-header: var(--sally-black);
  --sally-text-header-secondary: var(--sally-green);
  --sally-popover-background: var(--sally-white);
  --sally-popover-border: var(--sally-white-shaded);
  --sally-popover-text: var(--sally-black);
  --sally-card-border: var(--sally-grey);
  --sally-subcard-background: var(--sally-white);
  --sally-subcard-border: var(--sally-white-shaded);
  --sally-selection-text: var(--sally-white);
  --sally-selection-background: var(--sally-blue);
  // Datepicker
  --sally-datepicker-background: var(--sally-white);
  --sally-datepicker-border: var(--sally-grey);
  --sally-datepicker-clear-hover: var(--sally-red);
  --sally-datepicker-text: var(--sally-black);
  --sally-datepicker-shadow: var(--sally-grey);
  --sally-datepicker-day-name: var(--sally-grey-faded);
  --sally-datepicker-outside-month: var(--sally-grey-faded);
  --sally-datepicker-week-number: var(--sally-grey-faded);
  --sally-datepicker-day-hover-text: var(--sally-white);
  --sally-datepicker-day-hover-background: var(--sally-green);
  --sally-datepicker-day-hover-outside-month-text: var(--sally-black);
  --sally-datepicker-day-hover-outside-month-background: var(--sally-green-faded);
  --sally-datepicker-day-range-background: var(--sally-green-faded);
  --sally-datepicker-day-selected-text: var(--sally-black);
  --sally-datepicker-day-selected-background: var(--sally-green-faded);
  --sally-datepicker-day-disabled: var(--sally-grey-shaded);

  // --- Form
  --sally-form-label: var(--sally-grey-shaded);
  --sally-form-border: var(--sally-grey-shaded);
  --sally-form-text: var(--sally-black);

  // --- Advertisement
  --sally-ad-highlight: var(--sally-green);
  --sally-ad-highlight-price: var(--sally-green);
  --sally-ad-modal-upgrade-logo: var(--sally-green);
  --sally-ad-modal-title: var(--sally-black);
  --sally-ad-modal-subtitle: var(--sally-grey-shaded);

  // --- Target element
  --sally-target-element-text: var(--sally-black);
  --sally-target-element-background: var(--sally-grey-faded);

  // --- Tooltip
  --sally-tooltip-text: var(--sally-white);
  --sally-tooltip-background: var(--sally-black);

  // --- Loading/login/registration
  --sally-loading-logo: var(--sally-green);
  --sally-loading-background: var(--sally-white);
  --sally-login-shadow: var(--sally-grey);
  --sally-registation-background: var(--sally-green);
  --sally-registation-text: var(--sally-white);

  // ------ Other standards, not colours
  // Font face
  --sally-font-family: Roboto;
  // Useful variables
  --sally-form-adjust-height: 24px;
  // Radii
  --sally-radius-16: 4px;
  --sally-radius-12: 4px;
  --sally-radius-10: 4px;
  --sally-radius-8: 4px;
  --sally-radius-6: 4px;
  --sally-radius-4: 4px;
  // Input field heights
  --sally-input-m-height: 32px;
  --sally-input-l-height: 36px;
  --sally-input-xl-height: 36px;
  --sally-input-xxl-height: 36px;
}