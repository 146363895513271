.contract-template-add-overview .contract-template-none {
  font-style: italic;
}
.contract-template-add-overview .contract-template-add-review .ant-btn-secondary {
  margin-top: 15px;
}

.contract-template-add-review .subcard, .contract-template-add-review .ant-row + .ant-row, .contract-template-add-review .subcard h2.h2 + * {
  margin-top: 15px;
}

.contract-template-add-employees .user-image {
  float: left;
  margin-right: 10px;
}
.contract-template-add-employees .ant-row + .ant-row {
  margin-top: 20px;
}
.contract-template-add-employees .ant-row .employees-type {
  margin-top: 8px;
}

