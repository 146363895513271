.support-user-field {
  cursor: help;
  overflow: hidden;
  border: 2px dashed var(--sally-red);
  border-radius: 5px;

  .support-user-field-hide {
    cursor: pointer;
  }

  @at-root span#{&} .support-user-field-hide i {
    position: relative;
    top: 6px;
  }
  @at-root div#{&} .support-user-field-hide i {
    float: right;
  }
}