div .ant-modal-wrap {
  display: flex;
  align-items: flex-start;
  top: 10%;
  position: fixed;
}
div .ant-modal-wrap .ant-modal-content {
  border-radius: var(--sally-radius-10);
  background-color: transparent;
  text-decoration: none;
}
div .ant-modal-wrap .ant-modal-content .global-search input {
  text-decoration: none;
  height: var(--sally-input-xl-height);
  border-radius: var(--sally-radius-10);
  font-family: var(--sally-font-family);
  font-size: 16px;
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item a {
  text-decoration: none;
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item .ant-row {
  border-radius: var(--sally-radius-10);
  font-family: var(--sally-font-family);
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item .global-search-item-title h2 {
  font-size: 15px;
  font-weight: bold;
  color: var(--sally-text);
  text-decoration: none;
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item .global-search-item-text p {
  color: var(--sally-paragraph-text);
  text-decoration: none;
  font-weight: 400;
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item.global-search-selected {
  background-color: var(--sally-toggle-checked-background);
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item.global-search-selected p {
  color: var(--sally-text);
  text-decoration: none;
}
div .ant-modal-wrap .ant-modal-content .global-search .global-search-item.global-search-selected a .global-search-item-title.h2 {
  color: var(--sally-text);
  text-decoration: none;
}

