body .ant-table-wrapper {
  zoom: 1;

  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }

  .ant-table {
    color: var(--sally-table-text);
    background-color: var(--sally-table-background);
    border: 1px solid var(--sally-table-border);
    overflow: hidden;
    position: relative;
    border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;

    .ant-table-body {
      transition: opacity 0.3s ease;

      .ant-table-body-inner {
        height: 100%;
      }
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      text-align: left;
      border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
      overflow: hidden;

      .ant-table-thead > tr > th {
        background: var(--sally-table-header-background);
        font-weight: 500;
        transition: background .3s ease;
        text-align: left;
        color: var(--sally-table-header-text);

        &[colspan] {
          text-align: center;
        }

        &.ant-table-selection-column-custom {
          padding-left: 16px;
          padding-right: 0;
        }
      }

      .ant-table-thead > tr,
      .ant-table-tbody > tr {
        transition: all .3s;

        &.ant-table-row-hover > td, &:hover > td {
          background-color: var(--sally-table-background);
        }
        > th, > td {
          padding: 18px 15px;
          border-bottom: 1px solid var(--sally-table-divider);

          &.no-break-column {
            word-break: keep-all;
            white-space: nowrap;
          }

          &.ant-table-selection-column {
            text-align: center;
            min-width: 62px;
            width: 62px;

            .ant-radio-wrapper {
              margin-right: 0;
            }
          }
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }

        > th {
          font-size: 14px;
          font-weight: 700;
        }

        > td {
          font-weight: 400;
          color: var(--sally-table-text);

          .table-hover {
            visibility: hidden;
          }
          small {
            font-size: 13px;
            font-weight: 400;
            color: var(--sally-table-secondary-text);
          }
          &.ant-table-col-context {
            width: 1px;
            white-space: nowrap;

            > div {
              display: inline-block;
              width: 22px;
              height: 20px;

              > div {
                display: block;
                width: 42px;
                height: 40px;
                margin: -10px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px 20px;
                cursor: pointer;

                &.ant-popover-open {
                  background-size: 11px 11px;
                }
              }
            }
          }
        }
        &:hover {
          background-color: inherit;

          > td {
            background-color: inherit;

            .table-hover {
              visibility: visible;
            }
          }
        }
        &:last-child > td {
          border-bottom: 0;
        }
        > tr:not(.spanned-row-first) > td, tr.spanned-row-first > td[rowspan] {
          border-bottom: 1px solid var(--sally-table-divider);
        }
      }

      .ant-table-thead > tr:hover {
        background: none;
      }

      .ant-table-tbody > tr.ant-table-row-selected {
        background: var(--sally-table-selected-row);
      }

      tr.ant-table-expanded-row,
      tr.ant-table-expanded-row:hover {
        background: var(--sally-table-background);
      }
      .ant-table-row-indent + .ant-table-row-expand-icon {
        margin-right: 8px;
      }
    }

    .ant-table-footer {
      padding: 16px 8px;
      background: var(--sally-table-background);
      border-radius: 0 0 var(--sally-radius-4) var(--sally-radius-4);
      position: relative;

      &:before {
        content: '';
        height: 1px;
        background: var(--sally-table-background);
        position: absolute;
        top: -1px;
        width: 100%;
        left: 0;
      }
    }

    .ant-table-title {
      padding: 16px 0;
      position: relative;
      top: 1px;
      border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;

      & + .ant-table-content {
        position: relative;
        border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
        overflow: hidden;
      }
    }
    &.ant-table-without-column-header {
      .ant-table-title + .ant-table-content,
      table {
        border-radius: 0;
      }
    }

    .ant-table-header {
      background: var(--sally-table-background);
      overflow: hidden;

      table {
        border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
      }
    }
    &.ant-table-loading {
      position: relative;

      .ant-table-body {
        background: var(--sally-table-loading-background);
        opacity: 0.5;
      }

      .ant-table-spin-holder {
        height: 20px;
        line-height: 20px;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        position: absolute;
      }

      .ant-table-with-pagination {
        margin-top: -20px;
      }
      .ant-table-without-pagination {
        margin-top: 10px;
      }
    }
    &.ant-table-small {
      border-radius: var(--sally-radius-4);

      .ant-table-header > table,
      .ant-table-body > table {
        border: 0;
        padding: 0 8px;
      }
      .ant-table-thead > tr > th {
        background: var(--sally-table-header-background);
        border-bottom: 1px solid var(--sally-table-divider);
      }
      .ant-table-tbody > tr > td {
        padding: 6px 8px;
      }
      .ant-table-title,
      .ant-table-footer,
      .ant-table-thead > tr > th {
        padding: 10px 8px;
      }
      .ant-table-title {
        border-bottom: 1px solid var(--sally-table-divider);
        top: 0;
      }
      .ant-table-header {
        background: var(--sally-table-background);
      }
      .ant-table-placeholder,
      .ant-table-row:last-child td {
        border-bottom: 0;
      }
    }

    .ant-table-column-sorter-toggle {
      cursor: pointer;
    }

    .ant-table-column-sorter {
      position: relative;
      top: 2px;
      margin-left: 4px;
      display: inline-block;
      width: 14px;
      vertical-align: middle;
      text-align: center;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
      float: right;
    }
    .ant-table-row-expand-icon {
      cursor: pointer;
      display: inline-block;
      width: 17px;
      height: 17px;
      text-align: center;
      line-height: 14px;
      border: 1px solid var(--sally-table-divider);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: var(--sally-table-background);
    }
    .ant-table-row-expanded:after {
      content: '-';
    }
    .ant-table-row-collapsed:after {
      content: '+';
    }
    .ant-table-row-spaced {
      visibility: hidden;
      &:after {
        content: '.';
      }
    }
    &.ant-table-clickable-rows {
      table .ant-table-tbody > tr {
        cursor: pointer;
      }
    }

    .ant-table-placeholder {
      text-align: center;
      padding-top: 8px;
      color: var(--sally-table-empty-text);
    }
  }
  .ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;

    table {
      width: auto;
      min-width: 100%;
    }
  }

  a, .dumb-link {
    font-weight: 700;
  }

  .ant-table-fixed-header {
    > .ant-table-content > .ant-table-scroll > .ant-table-body {
      position: relative;
      background: var(--sally-table-background);
    }
    .ant-table-body-inner {
      overflow: scroll;
    }
    .ant-table-scroll .ant-table-header {
      overflow: scroll;
      padding-bottom: 20px;
      margin-bottom: -20px;
    }
    .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
    .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
      border-radius: 0;
    }
    .ant-table-fixed-left .ant-table-body-inner {
      padding-right: 0;
    }
  }
  .ant-table-fixed-left,
  .ant-table-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    border-radius: 0;

    table {
      width: auto;
      background: var(--sally-table-background);
    }
  }
  .ant-table-fixed-left {
    left: 0;

    .ant-table-header {
      overflow-y: hidden;
    }
    .ant-table-body-inner {
      margin-right: -20px;
      padding-right: 20px;
    }
    table {
      border-radius: var(--sally-radius-4) 0 0 0;
    }
  }
  .ant-table-fixed-right {
    right: 0;

    table {
      border-radius: 0 var(--sally-radius-4) 0 0;
    }
    .ant-table-expanded-row {
      color: transparent;
      pointer-events: none;
    }
  }
}
body .ant-card.ant-card-bordered .ant-card-body .ant-table {
  border: none;
}