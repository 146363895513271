.ant-pagination {
  font-size: 12px;
  padding-top: 16px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-end;
}
.ant-pagination:after {
  content: " ";
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.ant-pagination a {
  text-decoration: none;
}
.ant-pagination .ant-pagination-total-text {
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
  margin-right: 8px;
}
.ant-pagination .ant-pagination-item {
  cursor: pointer;
  border-radius: var(--sally-pagination-item-border-radius);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid var(--sally-pagination-item-border);
  background-color: var(--sally-pagination-item-background);
  margin-right: 8px;
  outline: 0;
  margin-top: -3px;
}
.ant-pagination .ant-pagination-item a {
  color: var(--sally-pagination-item-text);
  transition: none;
  margin: 0 6px;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  font-weight: 700;
  background-color: var(--sally-pagination-item-active-background);
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: var(--sally-pagination-item-active-text);
}
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  cursor: pointer;
  color: var(--sally-pagination-item-text);
  border-radius: 4px;
  list-style: none;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  transition: all 0.3s;
  display: inline-block;
  vertical-align: middle;
}
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  outline: 0;
}
.ant-pagination .ant-pagination-prev a,
.ant-pagination .ant-pagination-next a {
  color: var(--sally-pagination-item-text);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border: 1px solid var(--sally-pagination-item-border);
  background-color: var(--sally-pagination-item-background);
  border-radius: 4px;
  outline: none;
  display: block;
  transition: all 0.3s;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled:hover, .ant-pagination.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination .ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination .ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
.ant-pagination .ant-pagination-options-size-changer {
  display: inline-block;
  margin-right: 8px;
}
.ant-pagination .ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
}
.ant-pagination .ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  font-size: 12px;
  line-height: 1.5;
  color: var(--sally-input-text);
  background-color: var(--sally-input-background);
  background-image: none;
  border: 1px solid var(--sally-input-border);
  border-radius: 4px;
  transition: all 0.3s;
  margin: 0 8px;
  width: 50px;
}
.ant-pagination .ant-pagination-options-quick-jumper input::-moz-placeholder, .ant-pagination .ant-pagination-options-quick-jumper input:-ms-input-placeholder, .ant-pagination .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: var(--sally-input-placeholder-text);
  opacity: 1;
}
@media only screen and (max-width: 1024px) {
  .ant-pagination-item-after-jump-prev,
.ant-pagination-item-before-jump-next {
    display: none;
  }
}

