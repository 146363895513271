.phone-page-wrapper {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.phone-page-wrapper .ant-card .h1 {
  padding-top: 50px;
}
.phone-page-wrapper .ant-card p {
  margin: 20px 0;
  line-height: 18pt;
  font-size: 12pt;
}
.phone-page-wrapper .ant-card .signing-fields {
  padding-top: 20px;
}
.phone-page-wrapper .ant-card.login-form {
  padding-top: 50px;
}
.phone-page-wrapper .ant-card.login-form.logout-form {
  padding-top: 75px;
}
.phone-page-wrapper .ant-card.login-form label, .phone-page-wrapper .ant-card .ant-form label {
  font-size: 16pt;
}
.phone-page-wrapper .ant-card.modal-form textarea {
  height: 100px;
}
.phone-page-wrapper .ant-btn.btn-download, .phone-page-wrapper .ant-btn.ant-btn-secondary {
  font-size: 1.5em;
  width: 100% !important;
  text-align: center;
}
.phone-page-wrapper .ant-btn.btn-reject {
  background: #d73435;
  color: #fff;
}
.phone-page-wrapper .ant-btn.btn-approve {
  background: var(--sally-green-shaded);
  color: #fff;
}
.phone-page-wrapper .ant-table-small .ant-table-tbody > tr > td {
  padding: 2px 4px;
  word-break: normal;
}

