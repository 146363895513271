.reset-password.ant-card.ant-card-bordered, .reset-password.ant-card.ant-card-bordered:hover {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 788px;
  height: 241px;
  margin: -120.5px 0 0 -394px;
  border: 0;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
  height: 100%;
  padding: 0;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image {
  position: relative;
  width: 412px;
  padding: 40px 0 0 34px;
  background-image: url("/src/assets/images/reset-password-bg-412x241.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image > img, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image > img {
  width: 101px;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image .reset-password-title, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image .reset-password-title {
  position: absolute;
  bottom: 28px;
  left: 34px;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image .reset-password-title .h1, .reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image .reset-password-title .h3, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image .reset-password-title .h1, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image .reset-password-title .h3 {
  margin: 0;
  color: #fff;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image .reset-password-title .h3, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image .reset-password-title .h3 {
  opacity: 0.7;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-image svg .logo-fill, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-image svg .logo-fill {
  fill: var(--sally-login-logo);
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-form, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-form {
  width: 376px;
  padding: 26px 34px 0 34px;
  border: 1px solid #dfe2e5;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-form .h1, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-form .h1 {
  margin-bottom: 23px;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-form .form-error, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-form .form-error {
  margin-top: -20px;
  margin-bottom: 0px;
}
.reset-password.ant-card.ant-card-bordered .ant-card-body .reset-password-form .ant-btn, .reset-password.ant-card.ant-card-bordered:hover .ant-card-body .reset-password-form .ant-btn {
  width: 100%;
  margin-top: 10px;
}
.reset-password.ant-card.ant-card-bordered:hover, .reset-password.ant-card.ant-card-bordered:hover:hover {
  border: 0;
}

