body .employee-search-search-results .ant-row {
  margin-left: -25px;
  margin-right: -25px;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee {
  position: relative;
  width: 100%;
  padding: 8px 8px 8px 16px;
  border: 1px solid #e1e6eb;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.02);
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-black);
  margin-top: 8px;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee:first-child {
  margin-top: 0;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee button {
  padding-right: 12px;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .message {
  font-weight: bold;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-select, body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-deselect {
  padding-left: 2px;
  padding-right: 2px;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-select .icon, body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-deselect .icon {
  margin-right: 0;
}
body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-select.employee-search-select, body .employee-search-search-results .employee-search-search-result-container .employee-search-employee .employee-search-deselect.employee-search-select {
  float: right;
}
body .employee-search-search .ant-switch-wrapper .ant-switch-text {
  font-weight: normal;
}

