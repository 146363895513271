body .work-hours .title-menu a + *, body .work-hours .title-menu span + *, body .work-hours .title-menu div + *, body .work-hours .title-menu .ant-btn + * {
  margin-left: 20px;
}
body .work-hours .title-menu .ant-btn {
  padding-right: 14px;
}
body .work-hours .title-menu label {
  display: inline;
}
body .work-hours .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
body .work-hours .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
body .work-hours .h2 {
  text-align: center;
}
body .work-hours .h3 {
  line-height: inherit;
}
body .work-hours .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
body .work-hours .violations-icon {
  fill: var(--sally-status-warning-background);
}

.ant-tooltip-inner p {
  color: var(--sally-background);
}
.ant-tooltip-inner p:last-child {
  margin-bottom: 0;
}

