body {
    // Ageras colours
    --ageras-electric-green: #01ff94;
    --ageras-deep-sea-green: #002e33;
    --ageras-lavender: #dbd4ff;
    --ageras-day-grey: #edf1f7;

    --ageras-black: #000;
    --ageras-white: #fff;

    --ageras-green: #00e383;
    --ageras-green-140: #075e42;
    --ageras-green-120: #078e64;
    --ageras-green-110: #298574;
    --ageras-green-90: #32d583;
    --ageras-green-60: #6ce9a6;
    --ageras-green-20: #a6f4c5;
    --ageras-green-10: #d1fadf;
    --ageras-green-5: #e8fff0;

    --ageras-yellow: #fbdb3f;
    --ageras-yellow-220: #735714;
    --ageras-yellow-200: #8f7638;
    --ageras-yellow-120: #dcb11a;
    --ageras-yellow-30: #fff888;
    --ageras-yellow-10: #fff9cc;

    --ageras-red: #d92d20;
    --ageras-red-120: #951307;
    --ageras-red-110: #c94337;
    --ageras-red-100: #f4594e;
    --ageras-red-90: #f07870;
    --ageras-red-10: #f7ddd9;
    --ageras-red-5: #fff8f6;

    --ageras-purple: #5e45d2;
    --ageras-purple-110: #5d4dae;
    --ageras-purple-20: #f8f6ff;

    --ageras-blue-140: #131c3e;
    --ageras-blue-120: #1e267d;
    --ageras-blue-110: #2258ce;
    --ageras-blue-90: #5e8bef;
    --ageras-blue-60: #7ca2f8;
    --ageras-blue-10: #d0e6fe;
    --ageras-blue-5: #e4f1ff;

    --ageras-grey-200: #26282f;
    --ageras-grey-90: #697588;
    --ageras-grey-60: #a7a9b4;
    --ageras-grey-50: #d3d6df;
    --ageras-grey-40: #d9dce5;
    --ageras-grey-30: #e8e9f0;
    --ageras-grey-20: #f5f6f9;
    --ageras-grey-10: #f6f9ff;

    // Classic Salary colours
    --sally-green: #24973c;
    --sally-green-shaded: #1f8234;
    --sally-green-faded: #6acb7e;
    --sally-orange: #f69306;
    --sally-orange-shaded: #f8750c;
    --sally-orange-faded: #ffa72c;
    --sally-yellow: #f4b711;
    --sally-yellow-shaded: #f2ae0a;
    --sally-yellow-faded: #ffce3d;
    --sally-red: #fa581f;
    --sally-red-shaded: #fc6234;
    --sally-red-faded: #f46e65;
    --sally-grey: #adbbc8;
    --sally-grey-shaded: #748da6;
    --sally-grey-faded: #cdcdcd;
    --sally-blue: #108ee9;
    --sally-blue-shaded: #0e77ca;
    --sally-blue-faded: #67b3ff;
    --sally-black: #20445b;
    --sally-black-faded: #3e5461;
    --sally-white: #fff;
    --sally-white-shaded: #e5e5e5;
    --sally-white-faded: #f7f7f7;

    .site-wrapper.with-side-menu {
        display: grid;
        grid-template-columns: minmax(300px, 15%) auto;
    }

    .main-wrapper {
        position: relative;
        width: 100%;
        max-width: 1240px;
        min-height: 500px;
        margin: 0 auto;
        padding: 30px 20px 40px 20px;

        .ant-row, .ant-row-flex {
            margin: 0 -15px;

            > div {
                padding: 0 15px;
            }
            & + .ant-row, & + .ant-row-flex {
                margin-top: 30px;
            }
        }
    }
}
body {
    background-color: var(--sally-background);
    color: var(--sally-text);
    font-family: var(--sally-font-family), sans-serif;
}
.global-message {
    font-size: 14pt;
    text-align: center;
    min-height: 50px;
    z-index: 1045;
    padding: 10px 20px;
    border-color: var(--sally-global-message-background);
    background-color: var(--sally-global-message-background);
    color: var(--sally-global-message-text);
}
.phone-page-wrapper {
    .ant-card {
        background-color: var(--sally-background);
        height: 100vh;
    }

    .ant-row {
        margin-bottom: 25px;

        &.footer {
            margin-bottom: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    p {
        font-size: 1.5em;
    }
    .ant-btn {
        width: 100%;
        height: 100px;
        font-size: 2em;
    }

    &.ant-modal {
        .ant-card {
            height: auto;

            .ant-input {
                height: 25vh;
                margin-bottom: 10px;
            }

            .ant-btn {
                font-size: 2em;
            }
        }
    }
}
html {
    height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

p {
    line-height: 18px;
    margin-bottom: 10px;
    font-size: 12px;
    color: var(--sally-paragraph-text);
}

small {
    display: block;
    font-size: 10px;
    color: var(--sally-paragraph-text);

    &.inline {
        display: inline;
    }
}

label {
    color: var(--sally-form-label);
}

a {
    color: var(--sally-link);
    text-decoration: underline;

    &:hover {
        color: var(--sally-link-hover);
        text-decoration: underline;
    }

    &.black {
        color: var(--sally-black);

        &:hover {
            color: var(--sally-black);
        }
    }
}
