.package-upgrade.ant-card.ant-card-bordered .ant-card-body, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body {
  padding-top: 50px;
  text-align: center;
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .logo-fill, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .logo-fill {
  fill: var(--sally-link);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body h1, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body h1 {
  display: block;
  margin: 0;
  font-size: 30px;
  color: var(--sally-ad-modal-title);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .package-upgrade-subtitle, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .package-upgrade-subtitle {
  margin-top: 12px;
  font-size: 20px;
  color: var(--sally-ad-modal-subtitle);
  padding-top: 1em;
  width: 80%;
  text-align: center;
  margin: auto;
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .package-upgrade-price, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .package-upgrade-price {
  font-size: 100px;
  font-weight: 200;
  color: var(--sally-ad-highlight-price);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .package-upgrade-price-details, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .package-upgrade-price-details {
  font-size: 16px;
  color: var(--sally-ad-modal-subtitle);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .ant-row, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .ant-row {
  margin-top: 100px;
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .ant-row > *, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .ant-row > * {
  font-size: 15px;
  color: var(--sally-ad-modal-subtitle);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .ant-row > * strong, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .ant-row > * strong {
  display: block;
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: normal;
  color: var(--sally-ad-modal-title);
}
.package-upgrade.ant-card.ant-card-bordered .ant-card-body .ant-row .ant-col, .package-upgrade.ant-card.ant-card-bordered:hover .ant-card-body .ant-row .ant-col {
  padding-left: 5px;
  padding-right: 5px;
}
.package-upgrade.ant-card.ant-card-bordered.premium-upgrade .ant-card-body .package-upgrade-subtitle, .package-upgrade.ant-card.ant-card-bordered.premium-upgrade:hover .ant-card-body .package-upgrade-subtitle {
  margin-top: 10px;
}
.package-upgrade.ant-card.ant-card-bordered.premium-upgrade .ant-card-body .package-upgrade-price, .package-upgrade.ant-card.ant-card-bordered.premium-upgrade:hover .ant-card-body .package-upgrade-price {
  margin-top: 20px;
}
.package-upgrade.ant-card.ant-card-bordered.premium-upgrade .ant-card-body .package-upgrade-price-details, .package-upgrade.ant-card.ant-card-bordered.premium-upgrade:hover .ant-card-body .package-upgrade-price-details {
  margin-bottom: 40px;
}
.package-upgrade.ant-card.ant-card-bordered.premium-upgrade .ant-card-body .ant-row, .package-upgrade.ant-card.ant-card-bordered.premium-upgrade:hover .ant-card-body .ant-row {
  margin-top: 50px;
}

