body.theme-light {
  // --------- Standard colour usage
  --sally-background: var(--ageras-grey-10);
  --sally-background-layer-1: var(--ageras-white);
  --sally-text: var(--ageras-deep-sea-green);
  --sally-paragraph-text: var(--ageras-grey-90);
  --sally-warning-text: var(--ageras-red);
  --sally-error-text: var(--ageras-red);
  --sally-link: var(--ageras-deep-sea-green);
  --sally-link-hover: var(--ageras-green-120);
  --sally-subdued-link: var(--ageras-grey-40);
  --sally-subdued-link-hover: var(--ageras-grey-90);
  --sally-alert-background: var(--ageras-red);
  --sally-alert-text: var(--ageras-white);
  --sally-code-border: var(--ageras-grey-40);

  // --- Global message
  --sally-global-message-background: var(--ageras-red);
  --sally-global-message-text: var(--ageras-white);

  // --- Dashboard
  --sally-dashboard-arrow-background: var(--ageras-black);
  --sally-dashboard-arrow: var(--ageras-white);

  // --- Header menu
  --sally-header-background: var(--ageras-deep-sea-green);
  --sally-header-border: var(--ageras-grey-30);
  --sally-header-submenu-background: var(--ageras-green-140);
  --sally-header-submenu-divider: var(--ageras-green-140);
  --sally-header-text: var(--ageras-white);
  --sally-header-line-height: 42px;
  --sally-header-weight: 700;
  --sally-logo-text: var(--ageras-electric-green);
  --sally-header-item-vertical-margin: 5px;
  --sally-header-item-horizontal-margin: 4px;
  --sally-header-item-horizontal-padding: 12px;
  --sally-header-item-radius: 8px;
  --sally-header-item-hover-background: var(--ageras-electric-green);
  --sally-header-item-hover-text: var(--ageras-black);
  --sally-header-item-selected-background: var(--ageras-green-140);
  --sally-header-user-image-background: var(--ageras-electric-green);
  --sally-header-user-image-text: var(--ageras-deep-sea-green);

  // --- Left menu
  --sally-menu-background: var(--ageras-white);
  --sally-menu-item: var(--ageras-white);
  --sally-menu-item-text: var(--ageras-black);
  --sally-menu-shadow: var(--ageras-black);
  --sally-menu-submenu-item-text: var(--ageras-grey-90);
  --sally-menu-item-hover: var(--ageras-grey-10);
  --sally-menu-item-hover-text: var(--ageras-black);
  --sally-menu-item-active: var(--ageras-deep-sea-green);
  --sally-menu-item-active-text: var(--ageras-white);
  --sally-menu-divider: var(--ageras-grey-30);

  // --- Submenu
  --sally-submenu-active-weight: 700;
  --sally-submenu-background: var(--ageras-white);
  --sally-submenu-border: var(--ageras-white);
  --sally-submenu-item-text: var(--ageras-grey-90);
  --sally-submenu-item-hover-text: var(--ageras-deep-sea-green);
  --sally-submenu-item-active-text: var(--ageras-deep-sea-green);
  --sally-submenu-item-active-border: var(--ageras-green);
  --sally-menu-nubbin-text: var(--ageras-grey-200);
  --sally-menu-nubbin-background: var(--ageras-grey-30);

  // --- User image
  --sally-user-image-background: var(--ageras-grey-30);
  --sally-user-image-text: var(--ageras-grey-200);

  // --- Table
  --sally-table-background: var(--ageras-white);
  --sally-table-text: var(--ageras-deep-sea-green);
  --sally-table-secondary-text: var(--ageras-grey-90);
  --sally-table-border: var(--ageras-white);
  --sally-table-header-background: var(--ageras-white);
  --sally-table-header-text: var(--ageras-deep-sea-green);
  --sally-table-selected-row: var(--ageras-white);
  --sally-table-loading-background: var(--ageras-white);
  --sally-table-divider: var(--ageras-grey-30);
  --sally-table-sorter: var(--ageras-grey-90);
  --sally-table-sorter-active: var(--ageras-deep-sea-green);
  --sally-table-empty-text: var(--ageras-grey-90);
  // Pagination
  --sally-pagination-background: inherit;
  --sally-pagination-item-text: var(--ageras-grey-90);
  --sally-pagination-item-background: inherit;
  --sally-pagination-item-border: none;
  --sally-pagination-item-active-text: var(--ageras-deep-sea-green);
  --sally-pagination-item-active-background: var(--ageras-grey-30);
  --sally-pagination-item-border-radius: 30px;

  // --- Button
  --sally-button-box-shadow: var(--ageres-grey-90);
  // Text
  --sally-button-text-text: var(--ageras-grey-90);
  --sally-button-text-background: var(--ageras-white);
  --sally-button-text-border: var(--ageras-white);
  --sally-button-text-danger-text: var(--ageras-red);
  --sally-button-text-danger-background: var(--ageras-white);
  --sally-button-text-hover-background: var(--ageras-grey-10);
  --sally-button-text-danger-hover-text: var(--ageras-white);
  --sally-button-text-danger-hover-background: var(--ageras-red);
  --sally-button-text-focus-border: var(--ageras-green);
  --sally-button-text-active-text: var(--ageras-deep-sea-green);
  --sally-button-text-active-background: var(--ageras-grey-30);
  --sally-button-text-danger-active-background: var(--ageras-red-10);
  --sally-button-text-loading-text: var(--ageras-grey-60);
  --sally-button-text-disabled-text: var(--ageras-grey-50);
  --sally-button-text-disabled-background: var(--ageras-white);
  // Tertiary
  --sally-button-tertiary-text: var(--ageras-deep-sea-green);
  --sally-button-tertiary-background: var(--ageras-white);
  --sally-button-tertiary-border: var(--ageras-grey-50);
  --sally-button-tertiary-danger-text: var(--ageras-red);
  --sally-button-tertiary-danger-background: var(--ageras-white);
  --sally-button-tertiary-danger-border: var(--ageras-red);
  --sally-button-tertiary-hover-background: var(--ageras-grey-30);
  --sally-button-tertiary-danger-hover-text: var(--ageras-white);
  --sally-button-tertiary-danger-hover-background: var(--ageras-red);
  --sally-button-tertiary-focus-border: var(--ageras-green);
  --sally-button-tertiary-active-background: var(--ageras-grey-40);
  --sally-button-tertiary-danger-active-background: var(--ageras-red-10);
  --sally-button-tertiary-loading-text: var(--ageras-grey-90);
  --sally-button-tertiary-loading-background: var(--ageras-day-grey);
  --sally-button-tertiary-danger-loading-background: var(--ageras-red-5);
  --sally-button-tertiary-disabled-text: var(--ageras-grey-50);
  --sally-button-tertiary-disabled-background: var(--ageras-grey-20);
  // Secondary
  --sally-button-secondary-text: var(--ageras-deep-sea-green);
  --sally-button-secondary-background: var(--ageras-white);
  --sally-button-secondary-border: var(--ageras-grey-50);
  --sally-button-secondary-danger-text: var(--ageras-red);
  --sally-button-secondary-danger-background: var(--ageras-white);
  --sally-button-secondary-danger-border: var(--ageras-red);
  --sally-button-secondary-hover-background: var(--ageras-grey-30);
  --sally-button-secondary-danger-hover-text: var(--ageras-white);
  --sally-button-secondary-danger-hover-background: var(--ageras-red);
  --sally-button-secondary-focus-border: var(--ageras-green);
  --sally-button-secondary-active-background: var(--ageras-grey-40);
  --sally-button-secondary-danger-active-background: var(--ageras-red-10);
  --sally-button-secondary-loading-text: var(--ageras-grey-90);
  --sally-button-secondary-loading-background: var(--ageras-day-grey);
  --sally-button-secondary-danger-loading-background: var(--ageras-red-5);
  --sally-button-secondary-disabled-text: var(--ageras-grey-50);
  --sally-button-secondary-disabled-background: var(--ageras-grey-20);
  // Primary
  --sally-button-primary-text: var(--ageras-electric-green);
  --sally-button-primary-background: var(--ageras-deep-sea-green);
  --sally-button-primary-border: var(--ageras-white);
  --sally-button-primary-danger-text: var(--ageras-white);
  --sally-button-primary-danger-background: var(--ageras-red);
  --sally-button-primary-hover-text: var(--ageras-white);
  --sally-button-primary-hover-background: var(--ageras-green-140);
  --sally-button-primary-danger-hover-background: var(--ageras-red-120);
  --sally-button-primary-focus-border: var(--ageras-electric-green);
  --sally-button-primary-active-text: var(--ageras-deep-sea-green);
  --sally-button-primary-active-background: var(--ageras-electric-green);
  --sally-button-primary-danger-active-background: var(--ageras-red-110);
  --sally-button-primary-loading-text: var(--ageras-grey-90);
  --sally-button-primary-loading-background: var(--ageras-day-grey);
  --sally-button-primary-danger-loading-text: var(--ageras-red);
  --sally-button-primary-danger-loading-background: var(--ageras-red-5);
  --sally-button-primary-disabled-text: var(--ageras-grey-50);
  --sally-button-primary-disabled-background: var(--ageras-grey-20);

  // --- Input
  --sally-input-text: var(--ageras-deep-sea-green);
  --sally-input-placeholder-text: var(--ageras-grey-60);
  --sally-input-background: var(--ageras-white);
  --sally-input-border: var(--ageras-grey-50);
  --sally-input-hover-border: var(--ageras-deep-sea-green);
  --sally-input-focus-border: var(--ageras-electric-green);
  --sally-input-focus-shadow: var(--ageras-grey-40);
  --sally-input-disabled-text: var(--ageras-grey-90);
  --sally-input-disabled-background: var(--ageras-grey-20);
  --sally-input-disabled-border: var(--ageras-grey-40);
  --sally-input-error-text: var(--ageras-deep-sea-green);
  --sally-input-error-background: var(--ageras-red-5);
  --sally-input-error-border: var(--ageras-red);

  // --- Select
  --sally-select-padding: 5px;
  --sally-select-item-radius: 6px;
  --sally-select-item-hover-text: var(--ageras-white);
  --sally-select-item-hover-background: var(--ageras-deep-sea-green);
  --sally-select-item-selected-background: var(--ageras-day-grey);
  --sally-select-item-divider: var(--ageras-white);

  // --- Toggle
  --sally-toggle-text: var(--ageras-deep-sea-green);
  --sally-toggle-hover-text: var(--ageras-lavender);
  --sally-toggle-hover-background: var(--ageras-deep-sea-green);
  --sally-toggle-focus-text: var(--ageras-lavender);
  --sally-toggle-focus-background: var(--ageras-green-110);
  --sally-toggle-focus-border: var(--ageras-green-110);
  --sally-toggle-checked-text: var(--ageras-deep-sea-green);
  --sally-toggle-checked-background: var(--ageras-electric-green);
  --sally-toggle-checked-border: var(--ageras-green);
  --sally-switch-background: var(--ageras-grey-50);
  --sally-switch-nubbin: var(--ageras-white);
  --sally-switch-nubbin-shadow: var(--ageras-grey-50);
  --sally-switch-checked-nubbin-shadow: var(--ageras-grey-50);
  --sally-switch-hover-background: var(--ageras-grey-60);
  --sally-toggle-checked-hover-background: var(--ageras-deep-sea-green);
  --sally-switch-disabled-background: var(--ageras-grey-20);

  // --- Navigator
  --sally-navigator-background: var(--ageras-grey-40);
  --sally-navigator-text: var(--ageras-grey-90);
  --sally-navigator-selected-background: var(--ageras-white);
  --sally-navigator-selected-text: var(--ageras-deep-sea-green);
  --sally-navigator-hover-background: var(--ageras-deep-sea-green);
  --sally-navigator-hover-text: var(--ageras-lavender);

  // --- Notification levels
  --sally-status-awaiting-user-text-on-white: var(--ageras-yellow-120);
  --sally-status-awaiting-user-text: var(--ageras-grey-200);
  --sally-status-awaiting-user-background: var(--ageras-yellow);
  --sally-status-awaiting-user-border: var(--ageras-yellow);
  --sally-status-awaiting-system-text-on-white: var(--ageras-purple-110);
  --sally-status-awaiting-system-text: var(--ageras-deep-sea-green);
  --sally-status-awaiting-system-background: var(--ageras-lavender);
  --sally-status-awaiting-system-border: var(--ageras-lavender);
  --sally-status-info-text-on-white: var(--ageras-purple-110);
  --sally-status-info-text: var(--ageras-deep-sea-green);
  --sally-status-info-background: var(--ageras-lavender);
  --sally-status-info-border: var(--ageras-lavender);
  --sally-status-success-text-on-white: var(--ageras-green-110);
  --sally-status-success-text: var(--ageras-deep-sea-green);
  --sally-status-success-background: var(--ageras-green);
  --sally-status-success-border: var(--ageras-green);
  --sally-status-warning-text-on-white: var(--ageras-yellow-120);
  --sally-status-warning-text: var(--ageras-grey-200);
  --sally-status-warning-background: var(--ageras-yellow);
  --sally-status-warning-border: var(--ageras-yellow);
  --sally-status-failure-text-on-white: var(--ageras-red);
  --sally-status-failure-text: var(--ageras-white);
  --sally-status-failure-background: var(--ageras-red);
  --sally-status-failure-border: var(--ageras-red);

  // --- Elements
  --sally-text-header: var(--ageras-deep-sea-green);
  --sally-text-header-secondary: var(--ageras-black);
  --sally-popover-background: var(--ageras-white);
  --sally-popover-border: var(--ageras-grey-10);
  --sally-popover-text: var(--ageras-deep-sea-green);
  --sally-card-border: var(--ageras-grey-10);
  --sally-subcard-background: var(--ageras-white);
  --sally-subcard-border: var(--ageras-grey-10);
  --sally-selection-text: var(--ageras-white);
  --sally-selection-background: var(--ageras-purple-110);
  // Datepicker
  --sally-datepicker-background: var(--ageras-white);
  --sally-datepicker-border: var(--ageras-grey-40);
  --sally-datepicker-clear-hover: var(--ageras-red);
  --sally-datepicker-text: var(--ageras-deep-sea-green);
  --sally-datepicker-shadow: var(--ageras-grey-40);
  --sally-datepicker-day-name: var(--ageras-grey-40);
  --sally-datepicker-outside-month: var(--ageras-grey-40);
  --sally-datepicker-week-number: var(--ageras-grey-60);
  --sally-datepicker-day-hover-text: var(--ageras-deep-sea-green);
  --sally-datepicker-day-hover-background: var(--ageras-green-60);
  --sally-datepicker-day-hover-outside-month-text: var(--ageras-white);
  --sally-datepicker-day-hover-outside-month-background: var(--ageras-grey-60);
  --sally-datepicker-day-range-background: var(--ageras-green-10);
  --sally-datepicker-day-selected-text: var(--ageras-deep-sea-green);
  --sally-datepicker-day-selected-background: var(--ageras-electric-green);
  --sally-datepicker-day-disabled: var(--ageras-grey-60);

  // --- Form
  --sally-form-label: var(--ageras-grey-90);
  --sally-form-border: var(--ageras-grey-90);
  --sally-form-text: var(--ageras-deep-sea-green);

  // --- Advertisement
  --sally-ad-highlight: var(--ageras-deep-sea-green);
  --sally-ad-highlight-price: var(--ageras-deep-sea-green);
  --sally-ad-modal-upgrade-logo: var(--ageras-deep-sea-green);
  --sally-ad-modal-title: var(--ageras-deep-sea-green);
  --sally-ad-modal-subtitle: var(--ageras-grey-90);

  // --- Target element
  --sally-target-element-text: var(--ageras-deep-sea-green);
  --sally-target-element-background: var(--ageras-lavender);

  // --- Tooltip
  --sally-tooltip-text: var(--ageras-white);
  --sally-tooltip-background: var(--ageras-deep-sea-green);

  // --- Loading/login/registration
  --sally-loading-logo: var(--ageras-electric-green);
  --sally-loading-background: var(--ageras-deep-sea-green);
  --sally-login-shadow: var(--ageras-grey-40);
  --sally-registation-background: var(--ageras-deep-sea-green);
  --sally-registation-text: var(--ageras-white);

  // ------ Other standards, not colours
  // Font face
  --sally-font-family: Aeonik;
  // Useful variables
  --sally-form-adjust-height: 16px;
  // Radii
  --sally-radius-16: 16px;
  --sally-radius-12: 12px;
  --sally-radius-10: 10px;
  --sally-radius-8: 8px;
  --sally-radius-6: 6px;
  --sally-radius-4: 4px;
  // Input field heights
  --sally-input-m-height: 32px;
  --sally-input-l-height: 40px;
  --sally-input-xl-height: 48px;
  --sally-input-xxl-height: 56px;
}