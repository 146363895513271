body .accounting-vouchers .title-menu {
  display: flex;
  flex-direction: row;
}
body .accounting-vouchers .title-menu > a + *, body .accounting-vouchers .title-menu > span + *, body .accounting-vouchers .title-menu > .ant-btn + *, body .accounting-vouchers .title-menu > div + * {
  margin-left: 20px;
}
body .accounting-vouchers .title-menu label {
  margin-top: 8px;
  display: inline-block;
}
body .accounting-vouchers .voucher-row {
  display: flex;
}
body .accounting-vouchers .voucher-row .ant-btn {
  min-height: 32px;
  padding-top: 5px;
  padding-right: 25px;
}
body .accounting-vouchers .voucher-row .ant-btn .icon {
  margin-left: -10px;
}
body .accounting-vouchers .voucher-row .ant-btn .icon + span {
  margin-left: -5px;
}
body .accounting-vouchers .ant-tooltip-close, body .accounting-vouchers .ant-tooltip-open {
  cursor: help;
}

