.basic-integrations {
  .ant-card {
    p {
      font-size: 12pt;
      line-height: 13pt;
    }

    &.card-grandhood, &.card-advisor {
      &.card-grandhood {
        background-image: url("/src/assets/images/grandhood-corner-logo.png");
        background-repeat: no-repeat;
        background-position: bottom 25px right 30px;
        background-size: auto 45px;

        & > .ant-card-body > div {
          background-size: auto 475px;
          &.integration-in-use {
            background-image: url("/src/assets/images/grandhood-intergration-in-use.png");
          }
          &.integration-need-signature {
            background-image: url("/src/assets/images/grandhood-need-signature.png");
          }
          &.integration-not-started {
            background-image: url("/src/assets/images/grandhood-not-started.png");
          }

          blockquote {
            background-color: #FFE5F2;
            top: 200px;
            right: 75px;
          }
        }
      }
      &.card-advisor {
        & > .ant-card-body > div {
          background-size: auto 700px;
          background-image: url("/src/assets/images/ageras-integration.png");
        }

        blockquote {
          background-color: #042D52;
          top: 100px;
          right: 125px;

          p, cite {
            color: #fff;
          }
        }

        h3 ~ p {
          margin-top: 15px;
        }

        h2 {
          margin-top: 15px;

          & ~ ol {
            margin-top: 10px;
          }
        }

        ol {
          h3 {
            margin-bottom: 0;
          }

          li ~ li {
            margin-top: 5px;
          }
        }
      }

      h1 {
        font-size: 35pt;
        line-height: 35pt;
        margin-bottom: 25px;
      }

      & > .ant-card-body > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: top right;
        min-height: 520px;

        p {
          max-width: 450px;
          line-height: 18pt;
        }

        a {
          color: #000000;
          text-decoration: underline;
        }

        ul {
          margin-top: 25px;

          li::before {
            content: '';
            display: inline-block;
            height: 11px;
            width: 15px;
            background-image: url("/src/assets/images/grandhood-tick.png");
            background-size: 100% 100%;
            margin-right: 5px;
          }
          li:not(:first-of-type) {
            margin-top: 5px;
          }
        }

        ol {
          width: 600px;
        }

        blockquote {
          padding: 20px;
          position: relative;

          p {
            max-width: revert;
            font-size: 14pt;
            line-height: 20pt;
            font-style: italic;
          }
          cite {
            font-style: normal;
            line-height: 10pt;

            strong {
              color: inherit;
              font-weight: 800;
            }
          }
        }
      }
    }
    &.card-rmindr {
      ol {
        list-style: circle;
        color: var(--sally-grey-shaded);
        font-size: 12pt;
        margin-left: 15px;
        margin-bottom: 15px;
      }
      code {
        font-weight: 800;
        color: var(--sally-green-shaded);
      }
    }
  }
}