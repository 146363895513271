/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: inherit;
  color: var(--sally-form-label);
  border: 0;
  border-bottom: 1px solid var(--sally-form-border);
}
label {
  font-size: 12px;
}
input[type="search"] {
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 15px;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
}
label {
  position: relative;
}
label > .anticon {
  vertical-align: top;
  font-size: 12px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled {
  cursor: not-allowed;
}
.ant-radio-inline.disabled,
.ant-radio-vertical.disabled,
.ant-checkbox-inline.disabled,
.ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
.ant-radio.disabled label,
.ant-checkbox.disabled label {
  cursor: not-allowed;
}
.ant-form-item {
  font-size: 12px;
  margin-bottom: 24px;
  color: var(--sally-form-text);
  vertical-align: top;
}
.ant-form-item-control > .ant-form-item:last-child,
.ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
  margin-bottom: -24px;
}
.ant-form-item-control {
  line-height: 31.9999px;
  position: relative;
  zoom: 1;
}
.ant-form-item-control:before,
.ant-form-item-control:after {
  content: " ";
  display: table;
}
.ant-form-item-control:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-form-item-with-help {
  margin-bottom: 6px;
}
.ant-form-item-label {
  text-align: right;
  vertical-align: middle;
  line-height: 32px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-form-item-label label {
  color: var(--sally-form-label);
}
.ant-form-item-label label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}
.ant-form-item-no-colon .ant-form-item-label label:after {
  content: " ";
}
.ant-form-explain,
.ant-form-extra,
.ant-form-char-limit {
  color: var(--sally-form-label);
  line-height: 1.5;
}
.ant-form-extra,
.ant-form-char-limit {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form textarea.ant-input {
  height: auto;
}
form .ant-upload {
  background: transparent;
}
form input[type="radio"],
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
form {
  input[type="radio"],
  input[type="checkbox"] {
    width: 14px;
    height: 14px;
  }

  .ant-radio-inline,
  .ant-checkbox-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }

  .ant-checkbox-vertical,
  .ant-radio-vertical {
    display: block;

    & + .ant-checkbox-vertical,
    & + .ant-radio-vertical {
      margin-left: 0;
    }
  }
  .ant-input-number {
    margin-right: 8px;
  }
  .ant-select,
  .ant-cascader-picker {
    width: 100%;

    &:only-child {
      display: block;
    }
  }
  .ant-input-group {
    .ant-select, .ant-cascader-picker {
      width: auto;
      min-width: auto;
    }
  }
  .ant-input-group-addon .ant-select:only-child,
  .ant-input-group-addon .ant-cascader-picker:only-child {
    display: inline-block;
  }
  .ant-input-group-wrapper {
    position: relative;
    top: 2px;
  }
  .ant-input-group-wrap {
    .ant-select {
      min-width: auto;
    }
    .ant-select-selection {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:hover {
      border-color: var(--sally-input-hover-border);
    }
    .ant-select-selection--single {
      margin-left: -1px;
      height: 32px;
    }
  }
}
.ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-left: 8px;
  padding-right: 25px;
  line-height: 30px;
}
.ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: left;
  line-height: 1.5;
}
.ant-form-vertical .ant-form-item-label label:after,
.ant-col-24.ant-form-item-label label:after,
.ant-col-xl-24.ant-form-item-label label:after {
  display: none;
}
@media (max-width: 767px) {
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-xs-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-sm-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-md-24.ant-form-item-label label:after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
  }
  .ant-col-lg-24.ant-form-item-label label:after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > div {
  display: inline-block;
  vertical-align: middle;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-explain {
  position: absolute;
}
.has-warning {
  .ant-form-explain,
  .ant-form-split {
    color: var(--sally-status-warning-text-on-white);
  }
  .ant-input {
    &:hover {
      border-color: var(--sally-status-warning-background);
    }
    &:focus {
      border-color: var(--sally-status-warning-background);
      outline: 0;
      box-shadow: 0 0 0 2px var(--sally-status-warning-background);
    }
    &:not([disabled]):hover {
      border-color: var(--sally-status-warning-background);
    }
  }
  .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: var(--sally-status-warning-background);
    outline: 0;
    box-shadow: 0 0 0 2px var(--sally-status-warning-background);
  }
  .ant-input-prefix {
    color: var(--sally-status-warning-text-on-white);
  }
  .has-warning .ant-input-group-addon {
    color: var(--sally-status-warning-text-on-white);
    border-color: var(--sally-status-warning-background);
    background-color: var(--sally-background);
  }
  .ant-select-selection {
    border-color: var(--sally-status-warning-background);
  }
  .ant-select-open .ant-select-selection,
  .ant-select-focused .ant-select-selection {
    border-color: var(--sally-status-warning-background);
    outline: 0;
    box-shadow: 0 0 0 2px var(--sally-status-warning-background);
  }
}
.has-error {
  .ant-form-explain,
  .ant-form-split {
    color: var(--sally-status-failure-text-on-white);
  }
  .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: var(--sally-status-failure-background);
    outline: 0;
    box-shadow: 0 0 0 2px var(--sally-status-failure-background);;
  }
  .ant-input-prefix {
    color: var(--sally-status-failure-text-on-white);
  }
  .ant-input-group-addon {
    color: var(--sally-status-failure-text-on-white);
    border-color: var(--sally-status-failure-background);
    background-color: var(--sally-background);
  }
  .ant-select-selection {
    border-color: var(--sally-status-failure-background);
  }
  .ant-select-open .ant-select-selection,
  .ant-select-focused .ant-select-selection {
    color: var(--sally-status-failure-text-on-white);
    border-color: var(--sally-status-failure-background);
    background-color: var(--sally-background);
  }
  .ant-input-group-addon .ant-select-selection {
    border-color: transparent;
    box-shadow: none;
  }
  .ant-input-number,
  .ant-time-picker-input {
    border-color: var(--sally-status-failure-background);
  }
  .ant-cascader-picker:focus .ant-cascader-input {
    color: var(--sally-status-failure-text-on-white);
    border-color: var(--sally-status-failure-background);
    background-color: var(--sally-background);
  }
  label {
    color: var(--sally-input-error-border);
  }
  .ant-input {
    color: var(--sally-input-error-text);
    background-color: var(--sally-input-error-background);
    border-color: var(--sally-input-error-border);
  }
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 16px;
}
.ant-advanced-search-form .ant-input,
.ant-advanced-search-form .ant-input-group .ant-input,
.ant-advanced-search-form .ant-input-group .ant-input-group-addon {
  height: 28px;
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

body {
  .ant-form {
    .form-error {
      margin-bottom: 5px;
      padding-left: 20px;
      background-position: -4px center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      line-height: 20px;
      font-size: 14px;
      color: var(--sally-status-failure-text-on-white);

      &.ant-col {
        background: none;
      }
    }
    .ant-row, .ant-subrow {
      margin-right: -10px;
      margin-left: -10px;

      > div {
        padding: 0 10px;

        > .ant-row {
          margin-right: 0;
          margin-left: 0;

          > div {
            padding: 0;
          }
        }
      }
      & + .ant-row {
        margin-top: 15px;
      }
    }
    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-control {
        line-height: inherit;

        .ant-form-extra {
          display: block;
          margin-top: 10px;
        }
        .ant-input-number {
          margin-top: 0;
        }
        .ant-switch, .ant-radio-group {
          margin-top: 9px;
          margin-bottom: 9px;
        }
        .ant-input-prefix .ant-input-prefix-text,
        .ant-input-suffix .ant-input-suffix-text {
          position: relative;
          z-index: 1;
          height: 36px;
          line-height: 36px;
        }
        .ant-input-prefix .ant-input-prefix-text {
          left: 10px;
          right: auto;
        }
        .ant-input-suffix {
          .ant-input-suffix-text {
            right: 10px;
            left: auto;
          }
          .calculator-icon {
            cursor: pointer;
            position: relative;
            top: 5px;
          }
        }
      }
    }
    .ant-form-extra,
    .ant-form-char-limit {
      color: var(--sally-paragraph-text);
    }
    .ant-form-char-limit {
      .ant-form-char-limit-count {
        float: right;
      }
      .ant-form-char-limit-warning {
        color: var(--sally-status-warning-text-on-white);
      }
    }
    .ant-btn[type="submit"] {
      &.ant-btn-primary {
        position: relative;
        display: inline-block;
        width: auto;
        margin-top: 20px;
        padding-right: 24px;
        padding-left: 24px;
      }
    }
    .ant-btn + .ant-btn {
      display: inline-block;
      margin-left: 16px;
    }
    .ant-form-warning {
      display: inline-block;
      padding: 2px 0 2px 35px;
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-warning p {
      color: var(--sally-status-warning-text-on-white);
    }
    .ant-form-warning-top {
      display: inline-block;
      padding: 35px 0 2px 2px;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-warning-text-on-white);

      &.no-icon {
        padding: 2px 0 2px 2px;
        background: none;
      }

      p {
        color: var(--sally-status-warning-text-on-white);
      }
    }
    .ant-form-error {
      display: inline-block;
      padding: 2px 0 2px 35px;
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 22px 22px;
      font-weight: 500;
      color: var(--sally-status-failure-text-on-white);

      &.no-icon {
        padding: 2px 0;
        background: none;
      }

      p {
        color: var(--sally-status-failure-text-on-white);
      }
    }
    &.show-detailed-errors {
      .has-error {
        .ant-form-explain {
          display: block;
        }
      }
    }
  }
}
