.employee-filter {
  display: flex;
  flex-direction: row;
  max-width: 800px;
  padding: 0 !important;
  align-items: center;

  .employee-filter-small{
    height: 40px;
   
  }

  .ant-input-search, .ant-select {
    max-width: 400px;
    min-width: 200px;

    & + * {
      margin-left: 10px;
    }
  }
}
.ant-row {
  .employee-filter {
    float: right;
    margin-bottom: 10px;
  }
}
.title-menu {
  .employee-filter {
    & + * {
      margin-left: 10px;
    }
  }
}