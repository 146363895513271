body .ant-btn.ant-btn-secondary.mitid-button {
  background-color: #0060e6;
  /* these colours are specified for MitID, do not change */
  background-image: url("/src/assets/images/mitid-logo.png");
  background-position: 20px center;
  background-repeat: no-repeat;
  background-size: 92px 25px;
  color: #ffffff;
  /* these colours are specified for MitID, do not change */
  font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
  border-radius: 4px;
  height: 58px;
  padding: 0.5rem 1rem 0.25rem calc(1rem + 1rem + 92px);
  text-align: right;
  font-weight: bold;
}

