.menu-aside-container {
  position: sticky;
  z-index: 1040;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background: var(--sally-menu-background);
  color: var(--sally-menu-item-text);
  box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);
  top: 0;
  height: 100vh;
  margin: 0;
  padding: 20px 0;
  display: grid;
  grid-template-rows: 56px auto 56px;
}
.menu-aside-container .main-menu {
  overflow-x: hidden;
}
.menu-aside-container .main-menu .menu-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
}
.menu-aside-container .main-menu .menu-logo > img {
  width: 100px;
  margin: 15px 0 0 0;
}
.menu-aside-container .main-menu .menu-logo.multiple {
  text-decoration: 1px underline;
  border-bottom: 0;
  font-weight: bold;
  line-height: 52px;
  font-size: 16px;
  padding: 1px 10px 1px;
}
.menu-aside-container .main-menu .menu-logo.multiple span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
}
.menu-aside-container .main-menu .menu-logo.multiple:hover {
  background-color: var(--sally-menu-item-hover);
}
.menu-aside-container .main-menu .ant-menu-item.with-menu-toggle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-aside-container .main-menu .ant-menu-item.with-menu-toggle .ant-menu {
  flex-basis: 100%;
}
.menu-aside-container .main-menu .menu-toggle {
  float: right;
  z-index: 99;
  display: block;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  padding: 3px 5px;
}
.menu-aside-container .main-menu .menu-toggle:hover {
  background-color: var(--sally-menu-item-hover);
}
.menu-aside-container .main-menu .menu-toggle + a {
  width: 50%;
}
.menu-aside-container .main-menu .menu-toggle-value {
  display: none;
}
.menu-aside-container .main-menu .menu-toggle-value:not(:checked) + .ant-menu {
  display: none;
}
.menu-aside-container .main-menu .menu-toggle-value:checked + .ant-menu {
  display: block;
}
.menu-aside-container .main-menu .ant-menu.ant-menu-vertical, .menu-aside-container .bottom-menu.ant-menu.ant-menu-vertical {
  background-color: var(--sally-menu-item);
  border-right: 0;
  line-height: 52px;
  font-weight: 500;
  /*
  & > .ant-menu-item-active,
  & > .ant-menu-item-selected {
    background-color: var(--sally-menu-item-active);
    color: var(--sally-menu-item-active-text);
    border-right: 0;
  }*/
}
.menu-aside-container .main-menu .ant-menu.ant-menu-vertical > .ant-menu-item, .menu-aside-container .bottom-menu.ant-menu.ant-menu-vertical > .ant-menu-item {
  font-size: 16px;
  top: 0;
  padding: 2px 4px 1px 12px;
  border-right: 0;
}
.menu-aside-container .main-menu .ant-menu.ant-menu-vertical > .ant-menu-item > a:hover, .menu-aside-container .bottom-menu.ant-menu.ant-menu-vertical > .ant-menu-item > a:hover {
  text-decoration: none;
}
.menu-aside-container .main-menu .ant-menu.ant-menu-vertical > .ant-menu-item > a .icon, .menu-aside-container .bottom-menu.ant-menu.ant-menu-vertical > .ant-menu-item > a .icon {
  margin: 0 8px -4px 0;
}
.menu-aside-container .top-menu {
  display: flex;
  justify-content: space-around;
}
.menu-aside-container .top-menu .drop-down-menu, .menu-aside-container .top-menu .user-menu-button {
  cursor: pointer;
}
.menu-aside-container .top-menu .drop-down-menu {
  margin-right: 15px;
  padding: 18px 5px 0;
}
.menu-aside-container .top-menu .drop-down-menu.ant-popover-open {
  background-color: var(--sally-menu-background);
}
.menu-aside-container .bottom-menu {
  display: flex;
  justify-content: space-around;
}
.menu-aside-container .ant-menu-vertical .ant-menu-item,
.menu-aside-container .ant-menu-vertical .ant-menu-submenu-title {
  height: auto;
}

.notification-button {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  margin-right: 20px;
  padding: 6px 10px;
  vertical-align: top;
  cursor: pointer;
}
.notification-button .notification-badge {
  position: absolute;
  top: 3px;
  right: 7px;
  padding: 0 3px;
  background-color: var(--sally-alert-background);
  border-radius: 50%;
  font-size: 8px;
  font-weight: 700;
  color: var(--sally-alert-text);
}

.user-menu-button .user-image {
  margin-top: 8px;
}

.user-menu-popover {
  z-index: 1044;
  left: 0;
  right: auto !important;
  margin-top: 0;
  padding-top: 6px;
}
.user-menu-popover .ant-popover-content {
  margin-top: -10px;
}

.user-menu-popover .ant-popover-arrow {
  display: none;
}
.user-menu-popover .ant-popover-inner {
  background-color: transparent;
  background-clip: border-box;
  border-radius: 0;
  box-shadow: none;
}
.user-menu-popover .ant-popover-inner-content {
  padding: 0;
}

.user-menu.ant-menu {
  overflow-x: hidden;
  overflow-y: scroll;
}
.user-menu.ant-menu .ant-menu-item {
  height: 44px;
  line-height: 44px;
  padding: 0 24px 0 20px;
}
.user-menu.ant-menu .ant-menu-item > a {
  font-weight: 700;
}
.user-menu.ant-menu .ant-menu-item > a:hover {
  text-decoration: none;
}
.user-menu.ant-menu .ant-menu-item > a .icon, .user-menu.ant-menu .ant-menu-item > a .user-image {
  float: left;
  display: block;
}
.user-menu.ant-menu .ant-menu-item > a .icon {
  margin: 12px 9px 0 2px;
}
.user-menu.ant-menu .ant-menu-item > a .user-image {
  margin: 10px 7px 0 0;
}

.user-menu.ant-menu {
  min-width: 350px;
  max-height: 500px;
  border-right: 0;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);
}
.user-menu.ant-menu .ant-menu-item .search-field .icon {
  margin: 0 10px -5px 0;
}
.user-menu.ant-menu .ant-menu-item .search-field .ant-input {
  width: 200px;
  height: 28px;
  padding: 8px 8px;
}
.user-menu.ant-menu .ant-menu-item .cvr {
  float: right;
  padding-left: 10px;
}
.user-menu.ant-menu .ant-menu-item:last-child {
  border-bottom-left-radius: 4px;
}
.user-menu.ant-menu .ant-menu-item-divider {
  height: 14px;
  margin: 0;
  border-top: 1px solid var(--sally-menu-divider);
  background-color: var(--sally-menu-divider);
}

