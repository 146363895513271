/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-steps {
  font-size: 0;
  line-height: 1.5;
}
.ant-steps .ant-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-head-inner {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-head-inner > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-head-inner > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-title {
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-description {
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps .ant-steps-item.ant-steps-status-wait .ant-steps-tail > i {
  background-color: #e9e9e9;
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-head-inner {
  border-color: var(--sally-blue);
  background-color: var(--sally-blue);
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-head-inner > .ant-steps-icon {
  color: #fff;
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-head-inner > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--sally-blue);
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps .ant-steps-item.ant-steps-status-process .ant-steps-tail > i {
  background-color: #e9e9e9;
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-head-inner {
  border-color: var(--sally-blue);
  background-color: #fff;
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-head-inner > .ant-steps-icon {
  color: var(--sally-blue);
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-head-inner > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--sally-blue);
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-tail > i:after {
  width: 100%;
  background: var(--sally-blue);
  transition: all .6s;
  opacity: 1;
  box-shadow: 0 0 0 0 var(--sally-blue);
  animation: tailEffect .4s;
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-title {
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps .ant-steps-item.ant-steps-status-finish .ant-steps-description {
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-head-inner {
  border-color: var(--sally-red);
  background-color: #fff;
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-head-inner > .ant-steps-icon {
  color: var(--sally-red);
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-head-inner > .ant-steps-icon .ant-steps-icon-dot {
  background: var(--sally-red);
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-title {
  color: var(--sally-red);
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-description {
  color: var(--sally-red);
}
.ant-steps .ant-steps-item.ant-steps-status-error .ant-steps-tail > i {
  background-color: #e9e9e9;
}
.ant-steps .ant-steps-item.ant-steps-next-error .ant-steps-tail > i,
.ant-steps .ant-steps-item.ant-steps-next-error .ant-steps-tail > i:after {
  background-color: var(--sally-red);
}
.ant-steps .ant-steps-item.ant-steps-custom .ant-steps-head-inner {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}
.ant-steps .ant-steps-item.ant-steps-custom .ant-steps-head-inner > .ant-steps-icon {
  font-size: 26px;
  width: 26px;
  height: 26px;
}
.ant-steps .ant-steps-item.ant-steps-custom.ant-steps-status-process .ant-steps-head-inner > .ant-steps-icon {
  color: var(--sally-blue);
}
.ant-steps .ant-steps-head,
.ant-steps .ant-steps-main {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.ant-steps .ant-steps-head {
  background: #fff;
}
.ant-steps .ant-steps-head-inner {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 26px;
  height: 26px;
  line-height: 23px;
  text-align: center;
  border-radius: 26px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
.ant-steps .ant-steps-head-inner > .ant-steps-icon {
  line-height: 1;
  color: var(--sally-blue);
  position: relative;
}
.ant-steps .ant-steps-head-inner > .ant-steps-icon.anticon {
  font-size: 12px;
}
.ant-steps .ant-steps-head-inner > .ant-steps-icon.anticon-cross,
.ant-steps .ant-steps-head-inner > .ant-steps-icon.anticon-check {
  font-weight: bold;
}
.ant-steps .ant-steps-title {
  font-size: 14px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #fff;
  display: inline-block;
  padding-right: 10px;
}
.ant-steps .ant-steps-title > a:first-child:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps .ant-steps-item:last-child .ant-steps-title {
  padding-right: 0;
  width: 100%;
}
.ant-steps .ant-steps-item:last-child .ant-steps-tail {
  display: none;
}
.ant-steps .ant-steps-description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps .ant-steps-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 13px;
  padding: 0 10px;
}
.ant-steps .ant-steps-tail > i {
  display: inline-block;
  vertical-align: top;
  background: #e9e9e9;
  height: 1px;
  border-radius: 1px;
  width: 100%;
  position: relative;
}
.ant-steps .ant-steps-tail > i:after {
  position: absolute;
  content: '';
  top: 0;
  width: 0;
  background: #e9e9e9;
  height: 100%;
  opacity: 0;
}
.ant-steps.ant-steps-small .ant-steps-head-inner {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 18px;
  height: 18px;
  line-height: 15px;
  text-align: center;
  border-radius: 18px;
  font-size: 12px;
  margin-right: 10px;
}
.ant-steps.ant-steps-small .ant-steps-head-inner > .ant-steps-icon.anticon {
  display: inline-block;
  font-size: 12px;
  font-size: 9px \9;
  -ms-transform: scale(0.75) rotate(0deg);
      transform: scale(0.75) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  top: 0;
}
:root .ant-steps.ant-steps-small .ant-steps-head-inner > .ant-steps-icon.anticon {
  -webkit-filter: none;
          filter: none;
}
:root .ant-steps.ant-steps-small .ant-steps-head-inner > .ant-steps-icon.anticon {
  font-size: 12px;
}
.ant-steps.ant-steps-small .ant-steps-main {
  margin-top: 0;
}
.ant-steps.ant-steps-small .ant-steps-title {
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}
.ant-steps.ant-steps-small .ant-steps-description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.43);
}
.ant-steps.ant-steps-small .ant-steps-tail {
  top: 8px;
  padding: 0 8px;
}
.ant-steps.ant-steps-small .ant-steps-tail > i {
  height: 1px;
  border-radius: 1px;
  width: 100%;
}
.ant-steps.ant-steps-small .ant-steps-custom .ant-steps-head-inner > .ant-steps-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-tail {
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 30px 0 4px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-tail > i {
  height: 100%;
  width: 1px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-tail > i:after {
  height: 0;
  width: 100%;
}
.ant-steps-vertical .ant-steps-status-finish .ant-steps-tail > i:after {
  height: 100%;
}
.ant-steps-vertical .ant-steps-head {
  float: left;
}
.ant-steps-vertical .ant-steps-head-inner {
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-main {
  min-height: 47px;
  overflow: hidden;
  display: block;
}
.ant-steps-vertical .ant-steps-main .ant-steps-title {
  line-height: 26px;
}
.ant-steps-vertical .ant-steps-main .ant-steps-description {
  padding-bottom: 12px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-tail {
  position: absolute;
  left: 9px;
  top: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-tail > i {
  height: 100%;
}
.ant-steps-vertical.ant-steps-small .ant-steps-title {
  line-height: 18px;
}
.ant-steps-horizontal.ant-steps-hidden {
  visibility: hidden;
}
.ant-steps-horizontal .ant-steps-description {
  max-width: 120px;
}
.ant-steps-horizontal .ant-steps-item:not(:first-child) .ant-steps-head {
  padding-left: 10px;
  margin-left: -10px;
}
.ant-steps-dot .ant-steps-item .ant-steps-step {
  display: inline-block;
  text-align: center;
  width: 120px;
}
.ant-steps-dot .ant-steps-item:not(:first-child) .ant-steps-head {
  margin-left: 0;
  padding-left: 0;
}
.ant-steps-dot .ant-steps-tail {
  margin: 0 0 0 60px;
  padding: 0;
  width: 100%;
  top: 1px;
}
.ant-steps-dot .ant-steps-tail > i {
  height: 3px;
}
.ant-steps-dot .ant-steps-head {
  display: inline-block;
  padding-right: 0;
}
.ant-steps-dot .ant-steps-head-inner {
  margin: 0 auto;
  width: 5px;
  height: 5px;
  line-height: 5px;
  border: 0;
}
.ant-steps-dot .ant-steps-head-inner .ant-steps-icon-dot {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  position: relative;
}
.ant-steps-dot .ant-steps-head-inner .ant-steps-icon-dot:after {
  content: '';
  background: rgba(255, 255, 255, 0.001);
  width: 40px;
  height: 24px;
  position: absolute;
  top: -8px;
  left: -16px;
}
.ant-steps-dot .ant-steps-head-inner .ant-steps-icon-dot:hover {
  -ms-transform: scale(1.3);
      transform: scale(1.3);
}
.ant-steps-dot .ant-steps-main {
  display: block;
  margin-top: 10px;
}
.ant-steps-dot .ant-steps-main .ant-steps-title {
  padding-right: 0;
  background-color: transparent;
}
.ant-steps-dot .ant-steps-status-process .ant-steps-head {
  top: -1px;
}
.ant-steps-dot .ant-steps-status-process .ant-steps-head-inner {
  width: 7px;
  height: 7px;
  line-height: 7px;
}
.ant-steps-dot .ant-steps-status-process .ant-steps-head-inner .ant-steps-icon-dot {
  border-radius: 3.5px;
}
.ant-steps-dot.ant-steps-vertical .ant-steps-tail {
  left: 2px;
  height: 100%;
  padding: 0;
  top: 15px;
}
.ant-steps-dot.ant-steps-vertical .ant-steps-tail > i {
  height: 100%;
  width: 3px;
}
.ant-steps-dot.ant-steps-vertical .ant-steps-head {
  top: 12px;
  left: 1px;
}
.ant-steps-dot.ant-steps-vertical .ant-steps-status-process .ant-steps-head {
  left: 0;
}
@keyframes tailEffect {
  to {
    box-shadow: 0 0 3px 3px transparent;
  }
}

body {
  .ant-steps {
    .ant-steps-item {
      .ant-steps-title {
        font-size: 15px;
        font-weight: 500;
      }
      .ant-steps-head-inner {
        line-height: 24px;
        font-weight: 500;
      }
      .ant-steps-tail {
        height: 2px;

        & > i {
          height: 2px;
        }
      }
      &.ant-steps-status-finish {
        .ant-steps-main {
          background-color: #fff;

          .ant-steps-title {
            opacity: 0.5;
            color: var(--sally-grey-shaded);
          }
        }
        .ant-steps-head-inner {
          border-color: var(--sally-green-faded);
          background-color: var(--sally-green-faded);

          & > .ant-steps-icon {
            width: 10px;
            height: 10px;
            margin-left: 1px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 10px 10px;

            &:before {
              content: '';
              display: none;
            }
          }
        }
        .ant-steps-tail {
          & > i:after {
            background-color: #eaf7ed;
          }
        }
      }
      &.ant-steps-status-process {
        .ant-steps-title {
          color: var(--sally-black);
        }
        .ant-steps-head-inner {
          border-color: var(--sally-green);
          background-color: var(--sally-green);
        }
        .ant-steps-tail {
          & > i {
            &, &:after {
              background-color: #f9fafb;
            }
          }
        }
      }
      &.ant-steps-status-wait {
        .ant-steps-title {
          color: var(--sally-grey-shaded);
        }
        .ant-steps-head-inner {
          border-color: #e6eaee;
          background-color: #fafbfc;
          color: var(--sally-grey-shaded);
        }
      }
    }
    &.ant-steps-vertical {
      margin-top: 20px;

      .ant-steps-main {
        min-height: 40px;
      }
      .ant-steps-tail {
        top: 4px;
        left: 12px;
        width: 2px;
        height: 100%;
        padding-top: 22px;

        & > i {
          width: 2px;
          height: 100%;
        }
      }
    }
  }
}
