.freelancers {
    .title-menu {
        a, span, .ant-btn {
            & + * {
                margin-left: 20px;
            }
        }
        .ant-input-search.ant-input-affix-wrapper {
            width: inherit;

            .ant-input {
                width: 200px;
            }
        }
    }
    .h3 {
        line-height: inherit;

        > .user-image {
            float: left;
            margin: 3px 18px 0 0;
        }
    }
    .ant-table .ant-table-row {
        cursor: pointer;

        td > a {
            display: inline-block;
            padding: 5px 0;
            color: var(--sally-orange);
        }
    }
    .employees-toggle {
        display: inline-block;
        margin-top: 16px;
        padding: 0 20px 0 0;
        background-repeat: no-repeat;
        background-position: right 4px;
        background-size: 12px 12px;
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-grey-shaded);
        cursor: pointer;

        &.employees-toggle-visible {
        }
    }
    .employees-table {
        overflow-y: hidden;
        max-height: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &.employees-table-visible {
            max-height: 5600px;
        }
    }
}
