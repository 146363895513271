.approve-tab {
  .title-menu {
    a, span, .ant-btn {
      & + * {
        margin-left: 20px;
      }
    }
    label {
      display: inline;
    }
  }
  .approve-box {
    .h1 {
      font-size: 14pt;
      margin-bottom: 20px;
    }
    span.approve-link {
      color: var(--sally-link);
      cursor: pointer;
      float: right;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  tr > th, tr > td {
    word-break: break-word;
  }

  td.ant-table-col-thumbnail {
    width: 1px;

    .voucher-image {
      max-width: 50px;
      height: 50px;
      cursor: pointer;

      img {
        max-width: 50px;
        height: 50px;
      }
    }
  }
}