/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-select-auto-complete.ant-select {
  height: var(--sally-input-l-height);
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  box-shadow: none;
  height: var(--sally-input-l-height);
}
.ant-select-auto-complete.ant-select .ant-select-selection .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  background: transparent;
  border-width: 1px;
}

