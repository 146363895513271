dl {
  display: grid;
  grid-template-columns: 150px 350px;
  grid-template-rows: 25px;
  color: var(--sally-grey-shaded);
}
dl dt {
  font-weight: bold;
}

