body .companies-add {
    margin-left: 16.66666667%;
    width: 66.66666667%;

    .ant-card.ant-card-bordered {
        .company-search {
            margin: 30px 0 0 0;
            padding-left: 120px;

            @media screen and (max-width: 780px) {
                margin-left: 0;
                padding-left: 30px;
            }

            label {
                display: block;
                margin-bottom: 5px;
            }

            .autocomplete-wrapper {
                position: relative;

                .autocomplete-field {
                    width: 100%;

                    .ant-select-selection {
                        border: 1px solid var(--sally-input-border);
                    }
                    .ant-select-search {
                        width: 100%;
                    }
                }

                .ant-spin {
                    position: absolute;
                    top: 9px;
                    right: 10px;
                }
            }

            + * {
                margin-top: 30px;
            }
        }

        .ant-form {
            .ant-btn[type="submit"].ant-btn-primary {
                margin-top: 0;

                @media screen and (max-width: 780px) {
                    width: 100%;
                }
            }
        }
    }
}
