.salary-registration .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.salary-registration .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.salary-registration .h1 {
  margin-bottom: 10px;
}
.salary-registration .h1 .ant-select {
  width: 200px;
  margin-left: 20px;
}
.salary-registration .h1 + p {
  font-size: 14px;
}
.salary-registration .h3 {
  line-height: inherit;
}
.salary-registration .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.salary-registration .ant-table .ant-table-row {
  cursor: pointer;
}
.salary-registration .ant-table .ant-table-row td > a {
  display: inline-block;
  padding: 5px 0;
  color: var(--sally-orange);
}
.salary-registration .ant-table .ant-table-row .salary-registration-hour-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}
.salary-registration .ant-table .ant-table-row .salary-registration-cell-loading {
  font-style: italic;
}

