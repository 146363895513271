.documents {
  .title-menu {
    a, span, .ant-btn {
      & + * {
        margin-left: 20px;
      }
    }
    .ant-input-search.ant-input-affix-wrapper {
      width: inherit;

      .ant-input {
        width: 200px;
      }
    }
    a + a .ant-btn {
      padding-left: 20px;
      font-size: 14px;
    }
  }
  .ant-table {
    .ant-table-row {
      td {
        overflow-x: hidden;
        text-overflow: ellipsis;

        a {
          color: var(--sally-link);
        }
      }

      .document-table-name {
        max-width: 300px;

        .document-table-filename {
          font-weight: normal;
          font-style: italic;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      .document-table-signer, .document-table-signed {
        max-width: 200px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .employee-table-actions {
        max-width: 150px;
      }
    }
  }
}
.document-add-signer {
  float: right;
  display: inline-block;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-link);
  cursor: pointer;

  .icon {
    margin: 0 2px -5px 0;
  }
}
.document-remove-signer, .document-resend-signer {
  display: inline-block;
  margin-top: 25px;
  cursor: pointer;
}
body .ant-form .ant-row {
  &.document-signer-title {
    & + .ant-row {
      margin-top: 0;
    }

    .h2 {
      font-size: 12pt;
    }
  }
  &.document-signer-order-note {
    margin-top: 0;
    & + .ant-row {
      margin-top: 0;
    }
  }
}
small.small-note {
  color: var(--sally-black-faded);
  font-weight: normal;
  font-style: italic;
}