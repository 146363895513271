.dashboard {
    $onboarding-height: 300px;
    .onboarding {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        overflow-x: hidden;
        width: 100%;
        height: $onboarding-height;
        padding: 35px 0 0 0;
        background-color: var(--sally-background-layer-1);
        box-shadow: 0 1px 3px 0 rgba(20, 49, 26, 0.18);

        .h1 {
            margin-bottom: 14px;
            text-align: center;
            font-size: 20px;
        }
        .onboarding-cards {
            position: absolute;
            bottom: 40px;
            left: 50%;
            overflow-x: hidden;
            max-width: calc(100% - (50% - 500px));
            margin-left: -500px;
            white-space: nowrap;

            .onboarding-card {
                position: relative;
                display: inline-block;
                width: 310px;
                height: 215px;
                margin-right: 35px;
                padding: 30px 40px 0 40px;
                background-color: var(--sally-background);
                background-position: center 180px;
                background-repeat: no-repeat;
                background-size: 42px 13px;
                border-radius: 4px;
                vertical-align: top;
                text-align: center;
                white-space: normal;
                cursor: pointer;

                .onboarding-close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    opacity: 0.7;
                    cursor: pointer;
                }
                .onboarding-icon {
                    width: 31px;
                    height: 31px;
                }
                .h3 {
                    margin-top: 5px;
                    margin-bottom: 10px;
                    font-size: 17px;
                }
                p {
                    line-height: 20px;
                    font-size: 14px;
                }

                &.with-button {
                    padding: 30px 0 0;

                    background-image: none;
                }

                &.reduced-padding {
                    padding: 30px 0 0;

                    p {
                        margin: 0 40px;
                    }
                }

                &.reduced-spacing {
                    h3, p {
                        margin: 0;
                    }
                }
            }
        }
        .onboarding-arrow {
            position: absolute;
            bottom: 120px;
            width: 40px;
            height: 50px;
            background-color: var(--sally-dashboard-arrow-background);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                display: block;
                width: 10px;
                height: 10px;
                margin-top: -10px;
                border: 10px solid transparent;
            }
            &.onboarding-arrow-left {
                left: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;

                &:before {
                    right: 20px;
                    border-right-color: var(--sally-dashboard-arrow);
                }
            }
            &.onboarding-arrow-right {
                right: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                &:before {
                    left: 20px;
                    border-left-color: var(--sally-dashboard-arrow);
                }
            }
        }
        & + .dashboard-main {
            margin-top: $onboarding-height;
        }
    }
    .dashboard-main {
        max-width: 1240px;
        margin-right: auto;
        margin-left: auto;

        .h3 {
            clear: none;
            margin: -7px 0 22px 0;
            font-size: 17px;
            font-weight: 700;
        }

        a {
            text-decoration: none;
        }

        .ant-card.ant-card-bordered.dashboard-stats-card {
            height: 100%;

            .ant-card-body {
                height: 100%;
                min-height: 135px;
                padding: 20px 40px;
                line-height: 1;
                cursor: pointer;

                .stat-large {
                    display: inline-block;
                    padding-top: 10px;
                    padding-right: 20px;
                    line-height: 30px;
                    font-size: 30px;
                    font-weight: 500;
                }
                .stat-small {
                    margin-top: 5px;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: 400;

                    em {
                        font-style: normal;
                    }
                }
            }

            &.stat-negative {
                .ant-card-body {
                    .stat-small em {
                        color: var(--sally-warning-text);
                    }
                }
            }
        }
        .ant-card.ant-card-bordered.dashboard-graph-card {
            .ant-card-body {
                padding-right: 24px;
                padding-left: 24px;

                .dashboard-graph-inner {
                    padding-right: 6px;
                    padding-left: 6px;

                    p {
                        margin: 0 0 20px 0;
                        font-size: 12px;
                    }
                    .stat-large {
                        display: inline-block;
                        line-height: 40px;
                        font-size: 40px;
                        font-weight: 500;
                    }
                    .stat-small {
                        margin-top: 5px;
                        line-height: 19px;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
                .dashboard-graph-chart {
                    margin-top: 50px;
                }
            }
        }
        .ant-card.ant-card-bordered.dashboard-calendar-card, .ant-card.ant-card-bordered.dashboard-anniversaries-card {
            .ant-card-body {
                padding-bottom: 20px;
            }
        }
        .ant-card.ant-card-bordered.dashboard-transfers-card {
            .ant-card-body {
                padding-bottom: 20px;

                td, th {
                    font-size: 14px;
                    padding-top: 15px;
                    padding-bottom: 15px;

                    &:last-child {
                        text-align: right;
                    }
                }
                td {
                    font-weight: 500;
                }
            }
        }
        .ant-card.ant-card-bordered.dashboard-registrations-card {
            .ant-card-body {
                padding-bottom: 20px;

                .dashboard-registrations-header {
                    margin: -10px -30px 0 -30px;

                    & > * {
                        float: left;
                        padding: 0 10px 5px 10px;
                        font-weight: 700;

                        &:first-child {
                            padding-left: 30px;
                        }
                        &:last-child {
                            padding-right: 30px;
                        }
                        &.dashboard-registrations-header1 {
                            width: 40%;
                        }
                        &.dashboard-registrations-header2 {
                            width: 30%;
                            text-align: right;
                        }
                        &.dashboard-registrations-header3 {
                            width: 30%;
                            text-align: right;
                        }
                    }
                    &:after {
                        content: '';
                        display: block;
                        clear: left;
                    }
                }
                .dashboard-registrations-inner {
                    margin: 0 -30px -20px -30px;
                    border-top: 1px solid var(--sally-table-divider);

                    .dashboard-registrations-row {
                        display: block;
                        height: 50px;
                        border-bottom: 1px solid var(--sally-table-divider);

                        & > * {
                            float: left;
                            padding: 15px 10px;
                            line-height: 20px;
                            font-size: 14px;

                            &:first-child {
                                padding-left: 30px;
                            }
                            &:last-child {
                                padding-right: 30px;
                            }
                            &.dashboard-registrations-col1 {
                                width: 40%;
                            }
                            &.dashboard-registrations-col2 {
                                width: 30%;
                                text-align: right;
                            }
                            &.dashboard-registrations-col3 {
                                width: 30%;
                                text-align: right;

                                a {
                                    text-decoration: underline;
                                    font-weight: 700;

                                    &.pending-approval {
                                        color: var(--sally-warning-text)
                                    }
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                    em {
                        display: block;
                        padding: 20px 30px 20px 30px;
                        font-size: 14px;
                    }
                }
            }
        }
        em.empty-table-note {
            width: calc(100% + 60px);
            display: block;
            padding: 20px 30px 0 30px;
            margin-left: -30px;
            font-size: 14px;
            font-weight: normal;
            border-top: 1px solid var(--sally-table-divider);
        }
    }
}
.dashboard, .company-dashboards {
    em {
        color: var(--sally-paragraph-text);
    }
    .dashboard-calendar-inner, .dashboard-anniversaries-inner {
        margin: 0 -30px -20px -30px;
        border-top: 1px solid var(--sally-table-divider);

        .dashboard-calendar-row, .dashboard-anniversaries-row {
            display: block;
            min-height: 50px;
            border-bottom: 1px solid var(--sally-table-divider);
            clear: left;

            & > * {
                float: left;
                padding: 15px 10px;
                line-height: 20px;
                font-size: 14px;
            }
            &:last-child {
                border-bottom: 0;
            }
        }

        .dashboard-calendar-row {
            & > * {
                &.dashboard-calendar-col1 {
                    width: 35%;
                    padding-left: 30px;
                    font-weight: 500;
                }
                &.dashboard-calendar-col2 {
                    width: 40%;
                    text-align: right;
                    font-weight: 500;
                    line-height: 1;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.dashboard-calendar-col3 {
                    width: 25%;
                    padding-right: 30px;
                    text-align: right;
                    font-weight: 500;
                }
            }
        }
        .dashboard-anniversaries-row {
            & > * {
                &.dashboard-calendar-col1 {
                    width: 40%;
                    padding-left: 30px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.dashboard-calendar-col2 {
                    width: 30%;
                }
                &.dashboard-calendar-col3 {
                    width: 30%;
                    text-align: right;
                    padding-right: 30px;
                }
                &.dashboard-calendar-fullcol {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
        em {
            display: block;
            padding: 20px 30px 20px 30px;
            font-size: 14px;
        }
    }
}
.dashboard-notifications-card {
    .h3 {
        clear: none;
        margin: -7px 0 22px 0;
        font-size: 17px;
    }
    .dashboard-notifications-inner {
        margin: 0 -30px -20px -30px;
        border-top: 1px solid var(--sally-table-divider);

        .dashboard-notifications-row {
            background-repeat: no-repeat;
            background-position: 30px 50%;
            background-size: 25px 25px;
            display: block;
            padding-left: 75px;
            height: 50px;
            padding-top: 8px;

            &.dashboard-notifications-row-warning {
                color: var(--sally-warning-text);
            }

            &.dashboard-notifications-row-error {
                color: var(--sally-error-text);
            }
        }
    }
}
.reimbursement-feature-popup {
    .h3 {
        margin-top: 20px;
    }
    p {
        line-height: 22px;
        font-size: 14px;
    }
}
