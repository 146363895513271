.loading-overlay {
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.loading-overlay:not(.inline) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loading-overlay.inline ~ * {
  display: none !important;
}
.loading-overlay .loading-text {
  text-align: center;
  margin: 0 10%;
}

