.pay-rolls {

    .pay-rolls-table {
        .period-view {

            strong {
                display: inline-block;
                min-width: 100px;

                em {
                    font-style: normal;
                    color: var(--sally-table-secondary-text);
                }

                &:first-child {
                    padding-right: 15px;
                }

                &:last-child {
                    padding-left: 15px;
                }
            }

            .icon {
                vertical-align: top;
            }
        }

        .ant-table-row {
            td:first-child {
                .icon {
                    float: left;

                    &+span {
                        display: inline-block;
                        margin-left: 20px;
                    }
                }
            }

            &.pay-rolls-table-row-success {
                td:first-child {
                    color: var(--sally-status-success-text-on-white);

                    .icon-fill {
                        fill: var(--sally-status-success-text-on-white);
                    }
                }
            }

            &.pay-rolls-table-row-waiting {
                td:first-child {
                    color: var(--sally-status-awaiting-system-text-on-white);

                    .icon-fill {
                        fill: var(--sally-status-awaiting-system-text-on-white);
                    }
                }
            }

            &.pay-rolls-table-row-warning {
                td:first-child {
                    color: var(--sally-status-awaiting-user-text-on-white);

                    .icon-fill {
                        fill: var(--sally-status-awaiting-user-text-on-white);
                    }
                }
            }

            &.pay-rolls-table-row-error {
                td:first-child {
                    color: var(--sally-status-failure-text-on-white);

                    .icon-fill {
                        fill: var(--sally-status-failure-text-on-white);
                    }
                }
            }

            &.pay-rolls-table-row-clickable {
                cursor: pointer;
            }
        }
    }

    .h2+.pay-rolls-table {
        margin-top: 15px;
    }

    .pay-rolls-help-text {
        margin: -20px 0 30px 0;
    }
}

.ant-card.ant-card-bordered.no-pay-roll-card {
    .ant-card-body {
        background-position: 30px center;
        background-repeat: no-repeat;
        background-size: 40px 40px;

        p {
            margin: 0;
            font-size: 14px;
        }

        a {
            position: absolute;
            top: 50%;
            right: 40px;
            display: inline-block;
            line-height: 20px;
            margin-top: -10px;
            padding-right: 15px;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 12px 12px;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .ant-card-body,
    &:hover .ant-card-body {
        padding: 25px 110px 25px 90px;
    }
}

.pay-roll-create {
    width: 65%;
    margin: auto;

.payrolls-select-container {
    display: flex;
    flex-direction: column;

    .payrolls-select {
        width: 10rem;
    }
}

.previous-payrolls-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.payroll-select-label {
    margin-bottom: 0.1rem;

}}