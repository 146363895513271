@use 'sass:math';

.reset-password.ant-card.ant-card-bordered, .reset-password.ant-card.ant-card-bordered:hover {
    $width: 788px;
    $height: 241px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: $width;
    height: $height;
    margin: math.div(-$height, 2) 0 0 math.div(-$width, 2);
    border: 0;

    .ant-card-body {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        justify-content: space-between;
        height: 100%;
        padding: 0;

        .reset-password-image {
            position: relative;
            width: 412px;
            padding: 40px 0 0 34px;
            background-image: url('/src/assets/images/reset-password-bg-412x241.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            > img {
                width: 101px;
            }
            .reset-password-title {
                position: absolute;
                bottom: 28px;
                left: 34px;

                .h1, .h3 {
                    margin: 0;
                    color: #fff;
                }
                .h3 {
                    opacity: 0.7;
                }
            }
            svg .logo-fill {
                fill: var(--sally-login-logo);
            }
        }
        .reset-password-form {
            width: 376px;
            padding: 26px 34px 0 34px;
            border: 1px solid #dfe2e5;
            border-left: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            .h1 {
                margin-bottom: 23px;
            }
            .form-error {
                margin-top: -20px;
                margin-bottom: 0px;
            }
            .ant-btn {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    &:hover {
        border: 0;
    }
}
