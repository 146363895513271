.contract-template-add-overview {
  .contract-template-none {
    font-style: italic;
  }

  .contract-template-add-review {
    .ant-btn-secondary {
      margin-top: 15px;
    }
  }
}
.contract-template-add-review {
  .subcard, .ant-row + .ant-row, .subcard h2.h2 + * {
    margin-top: 15px;
  }
}
.contract-template-add-employees {
  .user-image {
    float: left;
    margin-right: 10px;
  }

  .ant-row + .ant-row {
    margin-top: 20px;
  }
  .ant-row .employees-type {
    margin-top: 8px;
  }
}