body .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body .car-allowance-modal .ant-form .ant-row > div > .ant-row > div {
  padding: 0 10px;
}
body .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body .car-allowance-modal .ant-form .ant-row > div > .ant-row > div > .ant-row > div {
  padding: 0;
}

.car-allowance .title-menu a + *, .car-allowance .title-menu span + *, .car-allowance .title-menu .ant-btn + * {
  margin-left: 20px;
}
.car-allowance .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.car-allowance .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.car-allowance .title-menu a + a .ant-btn {
  padding-left: 20px;
  font-size: 14px;
}
.car-allowance .h1 {
  margin-bottom: 10px;
}
.car-allowance .h1 + p {
  font-size: 14px;
}
.car-allowance .h3 {
  line-height: inherit;
}
.car-allowance .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}

