.salary-registration {
  .title-menu {
    .ant-input-search.ant-input-affix-wrapper {
      width: inherit;

      .ant-input {
        width: 200px;
      }
    }
  }
  .h1 {
    margin-bottom: 10px;

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
    & + p {
      font-size: 14px;
    }
  }
  .h3 {
    line-height: inherit;

    > .user-image {
      float: left;
      margin: 3px 18px 0 0;
    }
  }
  .ant-table .ant-table-row {
    cursor: pointer;

    td > a {
      display: inline-block;
      padding: 5px 0;
      color: var(--sally-orange);
    }

    .salary-registration-hour-line {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }

    .salary-registration-cell-loading {
      font-style: italic;
    }
  }
}
