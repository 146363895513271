.account {
    .with-submenu-main {
        max-width: 1240px;
        margin-right: auto;
        margin-left: auto;
        padding: 55px 0 0 0;

        .ant-card.ant-card-bordered.terminate-account-card {
            .ant-card-body {
                background-position: 30px center;
                background-repeat: no-repeat;
                background-size: 40px 40px;

                p {
                    margin: 0;
                    font-size: 14px;
                }
            }
            .ant-card-body, &:hover .ant-card-body {
                padding: 25px 180px 25px 90px;
            }
        }
        .account-recovery-codes {
            & > div {
                float: left;
                border: 2px dashed var(--sally-code-border);
                border-radius: 5px;
                margin: 10px 20px 10px 0;
                padding: 5px 15px;
                font-family: 'Courier New', Courier, monospace;
                font-size: 16px;

                span {
                    font-size: 0;
                }
                &:last-child {
                    span {
                        display: none;
                    }
                }
            }
            &:after {
                content: ' ';
                display: block;
                clear: left;
            }
        }
    }
}
