.companies .submenu .companies-menu-group-name {
  position: absolute;
  display: inline-block;
  height: 50px;
  padding: 4px 4px;
  margin-right: 42px;
  line-height: 20px;
  width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.companies .submenu .companies-menu-group-name + a {
  margin-left: 175px;
}
.companies .submenu + .companies-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 55px 0 0 0;
}
.companies .h3 > .user-image {
  float: left;
  margin: 1px 18px 0 0;
}
.companies .h3 > a {
  line-height: 32px;
}
.companies a.table-hover-link {
  margin-left: 10px;
  color: var(--sally-subdued-link);
}
.companies a.table-hover-link .icon {
  display: inline-block;
  padding-top: 5px;
}
.companies a.table-hover-link .icon .icon-fill {
  fill: var(--sally-subdued-link);
}
.companies a.table-hover-link:hover {
  text-decoration: underline;
}

