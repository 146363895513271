@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group {
  display: inline-block;
  font-size: 12px;

  .ant-radio-wrapper {
    font-size: 12px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    margin-right: 8px;
    cursor: pointer;

    span.ant-radio {
      white-space: nowrap;
      outline: none;
      display: inline-block;
      position: relative;
      line-height: 1;
      vertical-align: text-bottom;
      cursor: pointer;

      .ant-radio-input {
        position: absolute;
        left: 0;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }

      .ant-radio-inner {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        border-width: 1px;
        border-style: solid;
        border-radius: 14px;
        border-color: var(--sally-input-border);
        background-color: var(--sally-input-background);
        transition: all 0.3s;

        &:after {
          position: absolute;
          width: 6px;
          height: 6px;
          top: 4px;
          left: 4px;
          border-radius: 4px;
          display: table;
          border-top: 0;
          border-left: 0;
          content: ' ';
          background-color: var(--sally-toggle-text);
          opacity: 0;
          -ms-transform: scale(0);
          transform: scale(0);
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: var(--sally-toggle-checked-border);
          background-color: var(--sally-toggle-checked-background);

          &:after {
            -ms-transform: scale(1);
            transform: scale(1);
            opacity: 1;
            transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          }
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid var(--sally-toggle-checked-text);
          content: '';
          animation: antRadioEffect 0.36s ease-in-out;
          animation-fill-mode: both;
          visibility: hidden;
        }

        &:hover {
          .ant-radio-inner {
            border-color: var(--sally-input-hover-border);
            background-color: var(--sally-toggle-hover-background);

            &:after {
              background-color: var(--sally-toggle-hover-text);
            }
          }
        }
      }

      &.ant-radio-disabled {
        .ant-radio-input {
          cursor: not-allowed;
        }

        .ant-radio-inner {
          border-color: var(--sally-input-disabled-border);
          background-color: var(--sally-input-disabled-background);

          &:after {
            background-color: var(--sally-input-disabled-text);
          }
        }

        & + span {
          color: var(--sally-input-disabled-text);
          cursor: not-allowed;
        }
      }

      & + * {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &:hover .ant-radio .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-focused .ant-radio-inner {
      border-color: var(--sally-input-hover-border);
    }

    .ant-radio:hover:after,
    &:hover .ant-radio:after {
      visibility: visible;
    }
  }

  .ant-radio-button-wrapper {
    margin: 0;
    height: var(--sally-input-m-height);
    line-height: 26px;
    color: var(--sally-button-secondary-text);
    display: inline-block;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid var(--sally-button-secondary-border);
    border-left: 0;
    background: var(--sally-button-secondary-background);
    padding: 0 16px;
    position: relative;

    a {
      color: var(--sally-button-secondary-text);
    }

    > .ant-radio-button {
      margin-left: 0;
      display: block;
      width: 0;
      height: 0;
    }

    &:not(:first-child)::before {
      content: "";
      display: block;
      top: 0;
      left: -1px;
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: var(--sally-button-secondary-border);
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-left: 1px solid var(--sally-button-secondary-border);
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:first-child:last-child {
      border-radius: 4px;
    }

    &:hover, .ant-radio-button-wrapper-focused {
      background-color: var(--sally-button-secondary-hover-background);
      position: relative;
    }
    .ant-radio-inner, input[type="checkbox"], input[type="radio"] {
      opacity: 0;
      filter: alpha(opacity=0);
      width: 0;
      height: 0;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: var(--sally-button-secondary-active-background);
      z-index: 1;
    }
    &.ant-radio-button-wrapper-disabled {
      color: var(--sally-button-secondary-disabled-text);
      background-color: var(--sally-button-secondary-disabled-background);
      cursor: not-allowed;
      &:first-child, &:hover {
        background-color: var(--sally-button-secondary-hover-background);
      }
      &.ant-radio-button-wrapper-checked {
        color: var(--sally-button-secondary-disabled-text);
        background-color: var(--sally-button-secondary-active-background);
      }
    }
  }

  * + & {
    margin-left: 10px;
  }
}