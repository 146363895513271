body .companies-add {
  margin-left: 16.66666667%;
  width: 66.66666667%;
}
body .companies-add .ant-card.ant-card-bordered .company-search {
  margin: 30px 0 0 0;
  padding-left: 120px;
}
@media screen and (max-width: 780px) {
  body .companies-add .ant-card.ant-card-bordered .company-search {
    margin-left: 0;
    padding-left: 30px;
  }
}
body .companies-add .ant-card.ant-card-bordered .company-search label {
  display: block;
  margin-bottom: 5px;
}
body .companies-add .ant-card.ant-card-bordered .company-search .autocomplete-wrapper {
  position: relative;
}
body .companies-add .ant-card.ant-card-bordered .company-search .autocomplete-wrapper .autocomplete-field {
  width: 100%;
}
body .companies-add .ant-card.ant-card-bordered .company-search .autocomplete-wrapper .autocomplete-field .ant-select-selection {
  border: 1px solid var(--sally-input-border);
}
body .companies-add .ant-card.ant-card-bordered .company-search .autocomplete-wrapper .autocomplete-field .ant-select-search {
  width: 100%;
}
body .companies-add .ant-card.ant-card-bordered .company-search .autocomplete-wrapper .ant-spin {
  position: absolute;
  top: 9px;
  right: 10px;
}
body .companies-add .ant-card.ant-card-bordered .company-search + * {
  margin-top: 30px;
}
body .companies-add .ant-card.ant-card-bordered .ant-form .ant-btn[type=submit].ant-btn-primary {
  margin-top: 0;
}
@media screen and (max-width: 780px) {
  body .companies-add .ant-card.ant-card-bordered .ant-form .ant-btn[type=submit].ant-btn-primary {
    width: 100%;
  }
}

