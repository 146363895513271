/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-upload {
  font-size: 12px;
  outline: 0;
}

.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}

.ant-upload input[type=file] {
  cursor: pointer;
}

.ant-upload.ant-upload-select {
  display: inline-block;
}

.ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 96px;
  height: 96px;
  border-radius: 4px;
  background-color: #fbfbfb;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: var(--sally-blue);
}

.ant-upload.ant-upload-drag {
  border: 1px dashed #d9d9d9;
  transition: border-color 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border: 2px dashed var(--sally-blue-faded);
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--sally-blue-faded);
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  height: 60px;
  margin-bottom: 24px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 80px;
  margin-top: -5px;
  color: var(--sally-blue-faded);
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 14px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.43);
}

.ant-upload.ant-upload-drag .anticon-plus {
  font-size: 30px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.25);
}

.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.43);
}

.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.43);
}

.ant-upload-list {
  overflow: hidden;
}

.ant-upload-list-item {
  overflow: hidden;
  margin-top: 8px;
  font-size: 12px;
  position: relative;
  height: 24px;
}

.ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px;
  width: 100%;
  display: inline-block;
}

.ant-upload-list-item-info {
  line-height: 24px;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}

.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  margin-right: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.43);
  position: absolute;
  top: 5.5px;
}

.ant-upload-list-item .anticon-cross {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9 ;
  -ms-transform: scale(0.83333333) rotate(0deg);
  transform: scale(0.83333333) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 4px;
  color: rgba(0, 0, 0, 0.43);
  line-height: 24px;
}

:root .ant-upload-list-item .anticon-cross {
  -webkit-filter: none;
  filter: none;
}

:root .ant-upload-list-item .anticon-cross {
  font-size: 12px;
}

.ant-upload-list-item .anticon-cross:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #ecf6fd;
}

.ant-upload-list-item:hover .anticon-cross {
  opacity: 1;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: var(--sally-red);
}

.ant-upload-list-item-error .anticon-cross {
  opacity: 1;
  color: var(--sally-red) !important;
}

.ant-upload-list-item-progress {
  line-height: 0;
  font-size: 12px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: 66px;
  position: relative;
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: var(--sally-red);
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
  border-radius: 4px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail.anticon:before,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail.anticon:before {
  line-height: 48px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.43);
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 8px;
  line-height: 44px;
  transition: all 0.3s;
  padding-left: 48px;
  padding-right: 8px;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 56px;
  margin-top: 0;
  bottom: 24px;
  width: calc(100% - 24px);
}

.ant-upload-list-picture .anticon-cross,
.ant-upload-list-picture-card .anticon-cross {
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 1;
}

.ant-upload-list-picture-card {
  display: inline;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 96px;
  height: 96px;
  margin: 0 8px 8px 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  content: " ";
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 16px;
  width: 16px;
  color: rgba(255, 255, 255, 0.91);
  margin: 0 4px;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  position: static;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fbfbfb;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.43);
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 0;
  bottom: 32px;
}

.ant-upload-list .ant-upload-success-icon {
  color: var(--sally-green);
  font-weight: bold;
}

.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateIn;
}

.ant-upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateOut;
}

.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}

.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}

@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
body .ant-upload.ant-upload-drag {
  border: 0;
}
body .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border: 0;
}

