.phone-page-wrapper {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  .ant-card {
    .h1 {
      padding-top: 50px;
    }

    p {
      margin: 20px 0;
      line-height: 18pt;
      font-size: 12pt;
    }

    .signing-fields {
      padding-top: 20px;
    }

    &.login-form {
      padding-top: 50px;

      &.logout-form {
        padding-top: 75px;
      }
    }

    &.login-form, .ant-form {
      label {
        font-size: 16pt;
      }
    }

    &.modal-form {
      textarea {
        height: 100px;
      }
    }
  }
  .ant-btn {
    &.btn-download, &.ant-btn-secondary {
      font-size: 1.5em;
      width: 100% !important;
      text-align: center;
    }
    &.btn-reject {
      background: #d73435;
      color: #fff;
    }
    &.btn-approve {
      background: var(--sally-green-shaded);
      color: #fff;
    }
  }
  .ant-table-small .ant-table-tbody > tr > td {
    padding: 2px 4px;
    word-break: normal;
  }
}