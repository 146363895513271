.responsive-note.ant-modal {
  margin: 0 auto;
  padding: 0 5px;
  width: 95% !important;

  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    .ant-card-body {
      padding: 15px 10px;

      .ant-btn {
        width: 100%;
      }
    }
  }
}