body .ant-table {
  tr.sub-project {
    td:first-child {
      padding-left: 50px;
    }
  }
  tr.main-project {
    & > td {
      background: linear-gradient(to bottom, #dfe2e5, #fff 10px);
    }
    &:hover > td {
      background: linear-gradient(to bottom, #dfe2e5, #fff 12px);
    }
  }
}