body .ant-table-wrapper {
  zoom: 1;
}
body .ant-table-wrapper:before, body .ant-table-wrapper:after {
  content: " ";
  display: table;
}
body .ant-table-wrapper:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
body .ant-table-wrapper .ant-table {
  color: var(--sally-table-text);
  background-color: var(--sally-table-background);
  border: 1px solid var(--sally-table-border);
  overflow: hidden;
  position: relative;
  border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
}
body .ant-table-wrapper .ant-table .ant-table-body {
  transition: opacity 0.3s ease;
}
body .ant-table-wrapper .ant-table .ant-table-body .ant-table-body-inner {
  height: 100%;
}
body .ant-table-wrapper .ant-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
  overflow: hidden;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th {
  background: var(--sally-table-header-background);
  font-weight: 500;
  transition: background 0.3s ease;
  text-align: left;
  color: var(--sally-table-header-text);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th[colspan] {
  text-align: center;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding-left: 16px;
  padding-right: 0;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr {
  transition: all 0.3s;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr.ant-table-row-hover > td, body .ant-table-wrapper .ant-table table .ant-table-thead > tr:hover > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr.ant-table-row-hover > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr:hover > td {
  background-color: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td {
  padding: 18px 15px;
  border-bottom: 1px solid var(--sally-table-divider);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th.no-break-column, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.no-break-column,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th.no-break-column,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.no-break-column {
  word-break: keep-all;
  white-space: nowrap;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th.ant-table-selection-column, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-selection-column,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th.ant-table-selection-column,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
  min-width: 62px;
  width: 62px;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-selection-column .ant-radio-wrapper,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th.ant-table-selection-column .ant-radio-wrapper,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th:first-child, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td:first-child,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th:first-child,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td:first-child {
  padding-left: 20px;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th:last-child, body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td:last-child,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th:last-child,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td:last-child {
  padding-right: 20px;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > th,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > th {
  font-size: 14px;
  font-weight: 700;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td {
  font-weight: 400;
  color: var(--sally-table-text);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td .table-hover,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td .table-hover {
  visibility: hidden;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td small,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td small {
  font-size: 13px;
  font-weight: 400;
  color: var(--sally-table-secondary-text);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-col-context,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-col-context {
  width: 1px;
  white-space: nowrap;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-col-context > div,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-col-context > div {
  display: inline-block;
  width: 22px;
  height: 20px;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-col-context > div > div,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-col-context > div > div {
  display: block;
  width: 42px;
  height: 40px;
  margin: -10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  cursor: pointer;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > td.ant-table-col-context > div > div.ant-popover-open,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td.ant-table-col-context > div > div.ant-popover-open {
  background-size: 11px 11px;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr:hover,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr:hover {
  background-color: inherit;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr:hover > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr:hover > td {
  background-color: inherit;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr:hover > td .table-hover,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr:hover > td .table-hover {
  visibility: visible;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr:last-child > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr > tr:not(.spanned-row-first) > td, body .ant-table-wrapper .ant-table table .ant-table-thead > tr tr.spanned-row-first > td[rowspan],
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr > tr:not(.spanned-row-first) > td,
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr tr.spanned-row-first > td[rowspan] {
  border-bottom: 1px solid var(--sally-table-divider);
}
body .ant-table-wrapper .ant-table table .ant-table-thead > tr:hover {
  background: none;
}
body .ant-table-wrapper .ant-table table .ant-table-tbody > tr.ant-table-row-selected {
  background: var(--sally-table-selected-row);
}
body .ant-table-wrapper .ant-table table tr.ant-table-expanded-row,
body .ant-table-wrapper .ant-table table tr.ant-table-expanded-row:hover {
  background: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
body .ant-table-wrapper .ant-table .ant-table-footer {
  padding: 16px 8px;
  background: var(--sally-table-background);
  border-radius: 0 0 var(--sally-radius-4) var(--sally-radius-4);
  position: relative;
}
body .ant-table-wrapper .ant-table .ant-table-footer:before {
  content: "";
  height: 1px;
  background: var(--sally-table-background);
  position: absolute;
  top: -1px;
  width: 100%;
  left: 0;
}
body .ant-table-wrapper .ant-table .ant-table-title {
  padding: 16px 0;
  position: relative;
  top: 1px;
  border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
}
body .ant-table-wrapper .ant-table .ant-table-title + .ant-table-content {
  position: relative;
  border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
  overflow: hidden;
}
body .ant-table-wrapper .ant-table.ant-table-without-column-header .ant-table-title + .ant-table-content,
body .ant-table-wrapper .ant-table.ant-table-without-column-header table {
  border-radius: 0;
}
body .ant-table-wrapper .ant-table .ant-table-header {
  background: var(--sally-table-background);
  overflow: hidden;
}
body .ant-table-wrapper .ant-table .ant-table-header table {
  border-radius: var(--sally-radius-4) var(--sally-radius-4) 0 0;
}
body .ant-table-wrapper .ant-table.ant-table-loading {
  position: relative;
}
body .ant-table-wrapper .ant-table.ant-table-loading .ant-table-body {
  background: var(--sally-table-loading-background);
  opacity: 0.5;
}
body .ant-table-wrapper .ant-table.ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  position: absolute;
}
body .ant-table-wrapper .ant-table.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
body .ant-table-wrapper .ant-table.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
body .ant-table-wrapper .ant-table.ant-table-small {
  border-radius: var(--sally-radius-4);
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-header > table,
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-body > table {
  border: 0;
  padding: 0 8px;
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
  background: var(--sally-table-header-background);
  border-bottom: 1px solid var(--sally-table-divider);
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 10px 8px;
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-title {
  border-bottom: 1px solid var(--sally-table-divider);
  top: 0;
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-header {
  background: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-placeholder,
body .ant-table-wrapper .ant-table.ant-table-small .ant-table-row:last-child td {
  border-bottom: 0;
}
body .ant-table-wrapper .ant-table .ant-table-column-sorter-toggle {
  cursor: pointer;
}
body .ant-table-wrapper .ant-table .ant-table-column-sorter {
  position: relative;
  top: 2px;
  margin-left: 4px;
  display: inline-block;
  width: 14px;
  vertical-align: middle;
  text-align: center;
}
body .ant-table-wrapper .ant-table .ant-table-pagination.ant-pagination {
  margin: 16px 0;
  float: right;
}
body .ant-table-wrapper .ant-table .ant-table-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 14px;
  border: 1px solid var(--sally-table-divider);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table .ant-table-row-expanded:after {
  content: "-";
}
body .ant-table-wrapper .ant-table .ant-table-row-collapsed:after {
  content: "+";
}
body .ant-table-wrapper .ant-table .ant-table-row-spaced {
  visibility: hidden;
}
body .ant-table-wrapper .ant-table .ant-table-row-spaced:after {
  content: ".";
}
body .ant-table-wrapper .ant-table.ant-table-clickable-rows table .ant-table-tbody > tr {
  cursor: pointer;
}
body .ant-table-wrapper .ant-table .ant-table-placeholder {
  text-align: center;
  padding-top: 8px;
  color: var(--sally-table-empty-text);
}
body .ant-table-wrapper .ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
body .ant-table-wrapper .ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
body .ant-table-wrapper a, body .ant-table-wrapper .dumb-link {
  font-weight: 700;
}
body .ant-table-wrapper .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
body .ant-table-wrapper .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: scroll;
  padding-bottom: 20px;
  margin-bottom: -20px;
}
body .ant-table-wrapper .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
body .ant-table-wrapper .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
body .ant-table-wrapper .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
body .ant-table-wrapper .ant-table-fixed-left,
body .ant-table-wrapper .ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
}
body .ant-table-wrapper .ant-table-fixed-left table,
body .ant-table-wrapper .ant-table-fixed-right table {
  width: auto;
  background: var(--sally-table-background);
}
body .ant-table-wrapper .ant-table-fixed-left {
  left: 0;
}
body .ant-table-wrapper .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
body .ant-table-wrapper .ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
body .ant-table-wrapper .ant-table-fixed-left table {
  border-radius: var(--sally-radius-4) 0 0 0;
}
body .ant-table-wrapper .ant-table-fixed-right {
  right: 0;
}
body .ant-table-wrapper .ant-table-fixed-right table {
  border-radius: 0 var(--sally-radius-4) 0 0;
}
body .ant-table-wrapper .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}

body .ant-card.ant-card-bordered .ant-card-body .ant-table {
  border: none;
}

