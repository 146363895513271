.dashboard .onboarding {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 300px;
  padding: 35px 0 0 0;
  background-color: var(--sally-background-layer-1);
  box-shadow: 0 1px 3px 0 rgba(20, 49, 26, 0.18);
}
.dashboard .onboarding .h1 {
  margin-bottom: 14px;
  text-align: center;
  font-size: 20px;
}
.dashboard .onboarding .onboarding-cards {
  position: absolute;
  bottom: 40px;
  left: 50%;
  overflow-x: hidden;
  max-width: calc(100% - (50% - 500px));
  margin-left: -500px;
  white-space: nowrap;
}
.dashboard .onboarding .onboarding-cards .onboarding-card {
  position: relative;
  display: inline-block;
  width: 310px;
  height: 215px;
  margin-right: 35px;
  padding: 30px 40px 0 40px;
  background-color: var(--sally-background);
  background-position: center 180px;
  background-repeat: no-repeat;
  background-size: 42px 13px;
  border-radius: 4px;
  vertical-align: top;
  text-align: center;
  white-space: normal;
  cursor: pointer;
}
.dashboard .onboarding .onboarding-cards .onboarding-card .onboarding-close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.7;
  cursor: pointer;
}
.dashboard .onboarding .onboarding-cards .onboarding-card .onboarding-icon {
  width: 31px;
  height: 31px;
}
.dashboard .onboarding .onboarding-cards .onboarding-card .h3 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 17px;
}
.dashboard .onboarding .onboarding-cards .onboarding-card p {
  line-height: 20px;
  font-size: 14px;
}
.dashboard .onboarding .onboarding-cards .onboarding-card.with-button {
  padding: 30px 0 0;
  background-image: none;
}
.dashboard .onboarding .onboarding-cards .onboarding-card.reduced-padding {
  padding: 30px 0 0;
}
.dashboard .onboarding .onboarding-cards .onboarding-card.reduced-padding p {
  margin: 0 40px;
}
.dashboard .onboarding .onboarding-cards .onboarding-card.reduced-spacing h3, .dashboard .onboarding .onboarding-cards .onboarding-card.reduced-spacing p {
  margin: 0;
}
.dashboard .onboarding .onboarding-arrow {
  position: absolute;
  bottom: 120px;
  width: 40px;
  height: 50px;
  background-color: var(--sally-dashboard-arrow-background);
  cursor: pointer;
}
.dashboard .onboarding .onboarding-arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -10px;
  border: 10px solid transparent;
}
.dashboard .onboarding .onboarding-arrow.onboarding-arrow-left {
  left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dashboard .onboarding .onboarding-arrow.onboarding-arrow-left:before {
  right: 20px;
  border-right-color: var(--sally-dashboard-arrow);
}
.dashboard .onboarding .onboarding-arrow.onboarding-arrow-right {
  right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dashboard .onboarding .onboarding-arrow.onboarding-arrow-right:before {
  left: 20px;
  border-left-color: var(--sally-dashboard-arrow);
}
.dashboard .onboarding + .dashboard-main {
  margin-top: 300px;
}
.dashboard .dashboard-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
}
.dashboard .dashboard-main .h3 {
  clear: none;
  margin: -7px 0 22px 0;
  font-size: 17px;
  font-weight: 700;
}
.dashboard .dashboard-main a {
  text-decoration: none;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card {
  height: 100%;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card .ant-card-body {
  height: 100%;
  min-height: 135px;
  padding: 20px 40px;
  line-height: 1;
  cursor: pointer;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card .ant-card-body .stat-large {
  display: inline-block;
  padding-top: 10px;
  padding-right: 20px;
  line-height: 30px;
  font-size: 30px;
  font-weight: 500;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card .ant-card-body .stat-small {
  margin-top: 5px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card .ant-card-body .stat-small em {
  font-style: normal;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-stats-card.stat-negative .ant-card-body .stat-small em {
  color: var(--sally-warning-text);
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body {
  padding-right: 24px;
  padding-left: 24px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body .dashboard-graph-inner {
  padding-right: 6px;
  padding-left: 6px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body .dashboard-graph-inner p {
  margin: 0 0 20px 0;
  font-size: 12px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body .dashboard-graph-inner .stat-large {
  display: inline-block;
  line-height: 40px;
  font-size: 40px;
  font-weight: 500;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body .dashboard-graph-inner .stat-small {
  margin-top: 5px;
  line-height: 19px;
  font-size: 14px;
  font-weight: 400;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-graph-card .ant-card-body .dashboard-graph-chart {
  margin-top: 50px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-calendar-card .ant-card-body, .dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-anniversaries-card .ant-card-body {
  padding-bottom: 20px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body {
  padding-bottom: 20px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body td, .dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body th {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body td:last-child, .dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body th:last-child {
  text-align: right;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-transfers-card .ant-card-body td {
  font-weight: 500;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body {
  padding-bottom: 20px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header {
  margin: -10px -30px 0 -30px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > * {
  float: left;
  padding: 0 10px 5px 10px;
  font-weight: 700;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > *:first-child {
  padding-left: 30px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > *:last-child {
  padding-right: 30px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > *.dashboard-registrations-header1 {
  width: 40%;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > *.dashboard-registrations-header2 {
  width: 30%;
  text-align: right;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header > *.dashboard-registrations-header3 {
  width: 30%;
  text-align: right;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-header:after {
  content: "";
  display: block;
  clear: left;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner {
  margin: 0 -30px -20px -30px;
  border-top: 1px solid var(--sally-table-divider);
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row {
  display: block;
  height: 50px;
  border-bottom: 1px solid var(--sally-table-divider);
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > * {
  float: left;
  padding: 15px 10px;
  line-height: 20px;
  font-size: 14px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *:first-child {
  padding-left: 30px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *:last-child {
  padding-right: 30px;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *.dashboard-registrations-col1 {
  width: 40%;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *.dashboard-registrations-col2 {
  width: 30%;
  text-align: right;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *.dashboard-registrations-col3 {
  width: 30%;
  text-align: right;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *.dashboard-registrations-col3 a {
  text-decoration: underline;
  font-weight: 700;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row > *.dashboard-registrations-col3 a.pending-approval {
  color: var(--sally-warning-text);
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner .dashboard-registrations-row:last-child {
  border-bottom: 0;
}
.dashboard .dashboard-main .ant-card.ant-card-bordered.dashboard-registrations-card .ant-card-body .dashboard-registrations-inner em {
  display: block;
  padding: 20px 30px 20px 30px;
  font-size: 14px;
}
.dashboard .dashboard-main em.empty-table-note {
  width: calc(100% + 60px);
  display: block;
  padding: 20px 30px 0 30px;
  margin-left: -30px;
  font-size: 14px;
  font-weight: normal;
  border-top: 1px solid var(--sally-table-divider);
}

.dashboard em, .company-dashboards em {
  color: var(--sally-paragraph-text);
}
.dashboard .dashboard-calendar-inner, .dashboard .dashboard-anniversaries-inner, .company-dashboards .dashboard-calendar-inner, .company-dashboards .dashboard-anniversaries-inner {
  margin: 0 -30px -20px -30px;
  border-top: 1px solid var(--sally-table-divider);
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row, .dashboard .dashboard-calendar-inner .dashboard-anniversaries-row, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row {
  display: block;
  min-height: 50px;
  border-bottom: 1px solid var(--sally-table-divider);
  clear: left;
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row > *, .dashboard .dashboard-calendar-inner .dashboard-anniversaries-row > *, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row > *, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row > *, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row > *, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row > *, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row > * {
  float: left;
  padding: 15px 10px;
  line-height: 20px;
  font-size: 14px;
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row:last-child, .dashboard .dashboard-calendar-inner .dashboard-anniversaries-row:last-child, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row:last-child, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row:last-child, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row:last-child, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row:last-child, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row:last-child, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row:last-child {
  border-bottom: 0;
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col1, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col1, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col1, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col1 {
  width: 35%;
  padding-left: 30px;
  font-weight: 500;
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col2, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col2, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col2, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col2 {
  width: 40%;
  text-align: right;
  font-weight: 500;
  line-height: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col3, .dashboard .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col3, .company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col3, .company-dashboards .dashboard-anniversaries-inner .dashboard-calendar-row > *.dashboard-calendar-col3 {
  width: 25%;
  padding-right: 30px;
  text-align: right;
  font-weight: 500;
}
.dashboard .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col1, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col1, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col1, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col1 {
  width: 40%;
  padding-left: 30px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col2, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col2, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col2, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col2 {
  width: 30%;
}
.dashboard .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col3, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col3, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-col3, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-col3 {
  width: 30%;
  text-align: right;
  padding-right: 30px;
}
.dashboard .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-fullcol, .dashboard .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-fullcol, .company-dashboards .dashboard-calendar-inner .dashboard-anniversaries-row > *.dashboard-calendar-fullcol, .company-dashboards .dashboard-anniversaries-inner .dashboard-anniversaries-row > *.dashboard-calendar-fullcol {
  padding-left: 30px;
  padding-right: 30px;
}
.dashboard .dashboard-calendar-inner em, .dashboard .dashboard-anniversaries-inner em, .company-dashboards .dashboard-calendar-inner em, .company-dashboards .dashboard-anniversaries-inner em {
  display: block;
  padding: 20px 30px 20px 30px;
  font-size: 14px;
}

.dashboard-notifications-card .h3 {
  clear: none;
  margin: -7px 0 22px 0;
  font-size: 17px;
}
.dashboard-notifications-card .dashboard-notifications-inner {
  margin: 0 -30px -20px -30px;
  border-top: 1px solid var(--sally-table-divider);
}
.dashboard-notifications-card .dashboard-notifications-inner .dashboard-notifications-row {
  background-repeat: no-repeat;
  background-position: 30px 50%;
  background-size: 25px 25px;
  display: block;
  padding-left: 75px;
  height: 50px;
  padding-top: 8px;
}
.dashboard-notifications-card .dashboard-notifications-inner .dashboard-notifications-row.dashboard-notifications-row-warning {
  color: var(--sally-warning-text);
}
.dashboard-notifications-card .dashboard-notifications-inner .dashboard-notifications-row.dashboard-notifications-row-error {
  color: var(--sally-error-text);
}

.reimbursement-feature-popup .h3 {
  margin-top: 20px;
}
.reimbursement-feature-popup p {
  line-height: 22px;
  font-size: 14px;
}

