.documents .title-menu a + *, .documents .title-menu span + *, .documents .title-menu .ant-btn + * {
  margin-left: 20px;
}
.documents .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.documents .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.documents .title-menu a + a .ant-btn {
  padding-left: 20px;
  font-size: 14px;
}
.documents .ant-table .ant-table-row td {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.documents .ant-table .ant-table-row td a {
  color: var(--sally-link);
}
.documents .ant-table .ant-table-row .document-table-name {
  max-width: 300px;
}
.documents .ant-table .ant-table-row .document-table-name .document-table-filename {
  font-weight: normal;
  font-style: italic;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.documents .ant-table .ant-table-row .document-table-signer, .documents .ant-table .ant-table-row .document-table-signed {
  max-width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.documents .ant-table .ant-table-row .employee-table-actions {
  max-width: 150px;
}

.document-add-signer {
  float: right;
  display: inline-block;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-link);
  cursor: pointer;
}
.document-add-signer .icon {
  margin: 0 2px -5px 0;
}

.document-remove-signer, .document-resend-signer {
  display: inline-block;
  margin-top: 25px;
  cursor: pointer;
}

body .ant-form .ant-row.document-signer-title + .ant-row {
  margin-top: 0;
}
body .ant-form .ant-row.document-signer-title .h2 {
  font-size: 12pt;
}
body .ant-form .ant-row.document-signer-order-note {
  margin-top: 0;
}
body .ant-form .ant-row.document-signer-order-note + .ant-row {
  margin-top: 0;
}

small.small-note {
  color: var(--sally-black-faded);
  font-weight: normal;
  font-style: italic;
}

