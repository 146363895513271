.reimbursement-vouchers .title-menu a + *, .reimbursement-vouchers .title-menu span + *, .reimbursement-vouchers .title-menu .ant-btn + * {
  margin-left: 20px;
}
.reimbursement-vouchers .title-menu .ant-btn {
  padding-right: 14px;
}
.reimbursement-vouchers .title-menu label {
  display: inline;
}
.reimbursement-vouchers .reimbursement-vouchers-table th, .reimbursement-vouchers .reimbursement-vouchers-table td {
  padding-right: 19px;
  padding-left: 19px;
  word-break: break-word;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td {
  padding-top: 29px;
  padding-bottom: 29px;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td.ant-table-col-thumbnail {
  width: 1px;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td:first-child {
  padding: 0 15px 0 15px;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td:first-child .voucher-image {
  max-width: 50px;
  height: 50px;
  border: 1px solid #dae1e8;
  border-radius: 2px;
  cursor: pointer;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td:first-child .voucher-image img {
  max-width: 50px;
  height: 50px;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td:nth-child(2) {
  padding-left: 85px;
  background-position: 25px center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td > div span {
  color: var(--sally-grey-shaded);
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td > div .normal {
  font-weight: normal;
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row td > span a {
  display: block;
  color: var(--sally-black);
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row.reimbursement-vouchers-table-row-success td:nth-child(2) {
  color: var(--sally-green-shaded);
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row.reimbursement-vouchers-table-row-warning td:nth-child(2) {
  color: var(--sally-yellow-shaded);
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row.reimbursement-vouchers-table-row-error td:nth-child(2) {
  color: var(--sally-red-shaded);
}
.reimbursement-vouchers .reimbursement-vouchers-table .ant-table-row.reimbursement-vouchers-table-row-clickable {
  cursor: pointer;
}
.reimbursement-vouchers .h2 + .reimbursement-vouchers-table {
  margin-top: 15px;
}

.reimbursement-voucher-modal .reimbursement-voucher-image img {
  display: block;
  width: 440px;
  max-height: 600px;
  border: 1px solid #dae1e8;
  border-radius: 4px;
}
.reimbursement-voucher-modal .ant-form .ant-form-item .ant-form-item-control .ant-radio-group {
  margin-top: 0;
  margin-bottom: 0;
}

.one-time-pay-button {
  margin-top: 2em;
}

