.account .with-submenu-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 55px 0 0 0;
}
.account .with-submenu-main .ant-card.ant-card-bordered.terminate-account-card .ant-card-body {
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.account .with-submenu-main .ant-card.ant-card-bordered.terminate-account-card .ant-card-body p {
  margin: 0;
  font-size: 14px;
}
.account .with-submenu-main .ant-card.ant-card-bordered.terminate-account-card .ant-card-body, .account .with-submenu-main .ant-card.ant-card-bordered.terminate-account-card:hover .ant-card-body {
  padding: 25px 180px 25px 90px;
}
.account .with-submenu-main .account-recovery-codes > div {
  float: left;
  border: 2px dashed var(--sally-code-border);
  border-radius: 5px;
  margin: 10px 20px 10px 0;
  padding: 5px 15px;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
}
.account .with-submenu-main .account-recovery-codes > div span {
  font-size: 0;
}
.account .with-submenu-main .account-recovery-codes > div:last-child span {
  display: none;
}
.account .with-submenu-main .account-recovery-codes:after {
  content: " ";
  display: block;
  clear: left;
}

