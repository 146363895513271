.company-time-registration-integration .company-time-registration-integration-overview .integration-search, .company-time-registration-integration .company-time-registration-integration-overview .connection-search {
  margin: 30px 0 0 0;
  padding-right: 118px;
  padding-left: 120px;
  background-repeat: no-repeat;
}
.company-time-registration-integration .company-time-registration-integration-overview .integration-search label, .company-time-registration-integration .company-time-registration-integration-overview .connection-search label {
  display: block;
  margin-bottom: 5px;
}
.company-time-registration-integration .company-time-registration-integration-overview .integration-search + *, .company-time-registration-integration .company-time-registration-integration-overview .connection-search + * {
  margin-top: 30px;
}
.company-time-registration-integration .company-time-registration-integration-overview .integration-search.integration-search, .company-time-registration-integration .company-time-registration-integration-overview .connection-search.integration-search {
  background-position: 41px center;
  background-size: 45px 45px;
}
.company-time-registration-integration .company-time-registration-integration-overview .integration-search.connection-search, .company-time-registration-integration .company-time-registration-integration-overview .connection-search.connection-search {
  background-position: 43px center;
  background-size: 40px 45px;
}
.company-time-registration-integration .company-time-registration-integration-overview .ant-input-group.ant-input-group-compact .ant-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.company-time-registration-integration .company-time-registration-integration-overview .ant-input-group.ant-input-group-compact .ant-input + .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.company-time-registration-integration .company-time-registration-integration-overview .ant-input-group.ant-input-group-compact .ant-select > .ant-select-selection {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.company-time-registration-integration .company-time-registration-integration-overview .ant-input-group.ant-input-group-compact .ant-select + .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

