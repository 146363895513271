.employee-dimensions-add-row {
  display: block;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-link);
  cursor: pointer;
  clear: both;
}
.employee-dimensions-add-row .icon {
  margin: 0 2px -5px 0;
}

.employee-dimension-values .ant-input:not(:first-child) {
  margin-top: 5px;
}

