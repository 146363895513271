body .employees-work-hours-tab .work-hours-week-form .work-hours-week-form-hours-week {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
body .employees-work-hours-tab .work-hours-week-form .work-hours-week-form-hours-week .work-hours-week-form-hours-day {
  flex: 1 1 0;
}
body .employees-work-hours-tab .work-hours-week-form .work-hours-week-form-week-day {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
body .employees-work-hours-tab .work-hours-week-form .work-hours-week-form-week-day .work-hours-week-form-leave-note {
  font-size: 8pt;
  font-style: italic;
}
body .employees-work-hours-tab .work-week-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body .employees-work-hours-tab .work-week-summary {
  text-align: center;
  font-size: 13pt;
  color: var(--sally-grey-shaded);
}
body .employees-work-hours-tab .work-week-summary span {
  font-weight: bold;
}
body .employees-work-hours-tab .work-week-template {
  margin-bottom: 10px;
}
body .employees-work-hours-tab .work-week-detailed-button {
  text-align: right;
}
body .employees-work-hours-tab .ant-row .text-cell {
  padding-top: 8px;
}
body .employees-work-hours-tab .employees-work-hours-tab-violation-message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body .employees-work-hours-tab .employees-work-hours-tab-violation-message p {
  color: var(--sally-white);
}

