.support-user-field {
  cursor: help;
  overflow: hidden;
  border: 2px dashed var(--sally-red);
  border-radius: 5px;
}
.support-user-field .support-user-field-hide {
  cursor: pointer;
}
span.support-user-field .support-user-field-hide i {
  position: relative;
  top: 6px;
}

div.support-user-field .support-user-field-hide i {
  float: right;
}

