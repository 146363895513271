.ant-tooltip {
  position: absolute;
  z-index: 1060;
  display: block;
  visibility: visible;
  font-size: 13px;
  line-height: 18px;
}
.ant-tooltip.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip .ant-tooltip-inner {
  max-width: 250px;
  padding: 8px 10px;
  color: var(--sally-tooltip-text);
  text-decoration: none;
  background-color: var(--sally-tooltip-background);
  border-radius: 6px;
  min-height: 34px;
  text-align: center;
}
.ant-tooltip .ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.ant-tooltip.ant-tooltip-placement-top, .ant-tooltip.ant-tooltip-placement-topLeft, .ant-tooltip.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 4px;
  border-width: 5px 5px 0;
  border-top-color: var(--sally-tooltip-background);
}
.ant-tooltip.ant-tooltip-placement-right, .ant-tooltip.ant-tooltip-placement-rightTop, .ant-tooltip.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip.ant-tooltip-placement-right .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-rightTop .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 4px;
  border-width: 5px 5px 5px 0;
  border-right-color: var(--sally-tooltip-background);
}
.ant-tooltip.ant-tooltip-placement-bottom, .ant-tooltip.ant-tooltip-placement-bottomLeft, .ant-tooltip.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 4px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--sally-tooltip-background);
}
.ant-tooltip.ant-tooltip-placement-left, .ant-tooltip.ant-tooltip-placement-leftTop, .ant-tooltip.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip.ant-tooltip-placement-left .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-leftTop .ant-tooltip-arrow, .ant-tooltip.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 4px;
  border-width: 5px 0 5px 5px;
  border-left-color: var(--sally-tooltip-background);
}
.ant-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}
.ant-tooltip.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -5px;
}
.ant-tooltip.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}
.ant-tooltip.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}
.ant-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}
.ant-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}
.ant-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}

