.lock {
    position: relative;
    display: inline-block;
    opacity: 0.5;
    cursor: pointer;

    &.setting-lock {
        cursor: not-allowed;
        width: 100%;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    &:hover * {
        text-decoration: none;
    }
}
