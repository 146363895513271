.ant-input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: var(--sally-input-m-height);
  font-size: 14px;
  line-height: 1.5;
  color: var(--sally-input-text);
  background-color: var(--sally-input-background);
  background-image: none;
  border: 1px solid var(--sally-input-border);
  border-radius: var(--sally-radius-4);
  transition: all .3s;
  font-weight: 400;

  &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
    color: var(--sally-input-placeholder-text);
    opacity: 1;
  }

  // override the browser's autofill with our default background
  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px var(--sally-input-background) inset;
  }

  &:hover {
    border-color: var(--sally-input-hover-border)
  }
  &:focus {
    border-color: var(--sally-input-focus-border);
    box-shadow: 0 2px 4px 0 var(--sally-input-focus-shadow);
    outline: 0;
  }
  &.ant-input-disabled {
    color: var(--sally-input-disabled-text);
    background-color: var(--sally-input-disabled-background);
    border-color: var(--sally-input-disabled-border);
  }

  &.ant-input-xl {
    padding: 8px 12px 8px 12px;
    height: var(--sally-input-xl-height);
  }
  &.ant-input-l {
    padding: 8px 12px 8px 12px;
    height: var(--sally-input-l-height);
  }
}
.ant-input-search {
  .ant-input-search-icon {
    cursor: pointer;
    transition: all .3s;
    font-size: 14px;
  }
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  transition: all .3s, height 0s;
}
.ant-input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  &[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
  > [class*="col-"] {
    padding-right: 8px;

    &:last-child {
      padding-right: 0;
    }
  }
}
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
  > * {
    display: block !important;
  }
}
.ant-input-group {
  height: var(--sally-input-m-height);

  .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    height: var(--sally-input-m-height);

    &:focus {
      z-index: 1;
    }
  }
  > .ant-input:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    .ant-select .ant-select-selection {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  > .ant-input:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    .ant-select .ant-select-selection {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.ant-input-group-l {
    height: var(--sally-input-l-height);

    .ant-input, .ant-select-selection--single {
      height: var(--sally-input-l-height);
    }
  }
  &.ant-input-group-xl {
    height: var(--sally-input-xl-height);

    .ant-input, .ant-select-selection--single {
      height: var(--sally-input-xl-height);
    }
  }
  .ant-input-affix-wrapper {
    display: table-cell;
    width: 100%;
    float: left;
  }
  &.ant-input-group-compact {
    display: block;
    zoom: 1;

    &:before, &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
      visibility: hidden;
      font-size: 0;
      height: 0;
    }
    > * {
      border-radius: 0;
      border-right-width: 0;
      vertical-align: top;
      float: none;
      display: inline-block;
    }
    .ant-input {
      float: none;
      z-index: auto;
    }
    > .ant-select > .ant-select-selection,
    > .ant-calendar-picker .ant-input,
    > .ant-select-auto-complete .ant-input,
    > .ant-cascader-picker .ant-input,
    > .ant-mention-wrapper .ant-mention-editor,
    > .ant-time-picker .ant-time-picker-input {
      border-radius: 0;
      border-right-width: 0;
    }
    > *:first-child,
    > .ant-select:first-child > .ant-select-selection,
    > .ant-calendar-picker:first-child .ant-input,
    > .ant-select-auto-complete:first-child .ant-input,
    > .ant-cascader-picker:first-child .ant-input,
    > .ant-mention-wrapper:first-child .ant-mention-editor,
    > .ant-time-picker:first-child .ant-time-picker-input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    > *:last-child,
    > .ant-select:last-child > .ant-select-selection,
    > .ant-calendar-picker:last-child .ant-input,
    > .ant-select-auto-complete:last-child .ant-input,
    > .ant-cascader-picker:last-child .ant-input,
    > .ant-mention-wrapper:last-child .ant-mention-editor,
    > .ant-time-picker:last-child .ant-time-picker-input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right-width: 1px;
    }
  }
}
.ant-input-group-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: var(--sally-input-background);

  .ant-input {
    z-index: 2;
    background: transparent;
    min-height: 100%;

    &.ant-input-right-align {
      text-align: right;

      &:not(:last-child) {
        padding-right: 48px;
      }
    }
  }

  &:hover .ant-input:not(.ant-input-disabled) {
    border-color: var(--sally-input-border);
  }
  .ant-input-prefix, .ant-input-suffix {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    line-height: 0;
    color: var(--sally-input-text);
  }
  .ant-input-prefix {
    left: 7px;
  }
  .ant-input-suffix {
    right: 7px;
  }
  .ant-input:not(:first-child) {
    padding-left: 24px;
  }
  .ant-input:not(:last-child) {
    padding-right: 24px;
  }
}