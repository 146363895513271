.ant-tooltip {
  position: absolute;
  z-index: 1060;
  display: block;
  visibility: visible;
  font-size: 13px;
  line-height: 18px;

  &.ant-tooltip-hidden {
    display: none;
  }
  .ant-tooltip-inner {
    max-width: 250px;
    padding: 8px 10px;
    color: var(--sally-tooltip-text);
    text-decoration: none;
    background-color: var(--sally-tooltip-background);
    border-radius: 6px;
    min-height: 34px;
    text-align: center;
  }
  .ant-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  &.ant-tooltip-placement-top,
  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-topRight {
    padding-bottom: 8px;

    .ant-tooltip-arrow {
      bottom: 4px;
      border-width: 5px 5px 0;
      border-top-color: var(--sally-tooltip-background);
    }
  }
  &.ant-tooltip-placement-right,
  &.ant-tooltip-placement-rightTop,
  &.ant-tooltip-placement-rightBottom {
    padding-left: 8px;

    .ant-tooltip-arrow {
      left: 4px;
      border-width: 5px 5px 5px 0;
      border-right-color: var(--sally-tooltip-background);
    }
  }
  &.ant-tooltip-placement-bottom,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottomRight {
    padding-top: 8px;

    .ant-tooltip-arrow {
      top: 4px;
      border-width: 0 5px 5px;
      border-bottom-color: var(--sally-tooltip-background);
    }
  }
  &.ant-tooltip-placement-left,
  &.ant-tooltip-placement-leftTop,
  &.ant-tooltip-placement-leftBottom {
    padding-right: 8px;

    .ant-tooltip-arrow {
      right: 4px;
      border-width: 5px 0 5px 5px;
      border-left-color: var(--sally-tooltip-background);
    }
  }
  &.ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    margin-left: -5px;
  }
  &.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 16px;
  }
  &.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 16px;
  }
  &.ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    margin-top: -5px;
  }
  &.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 8px;
  }
  &.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 8px;
  }
  &.ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    margin-top: -5px;
  }
  &.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 8px;
  }
  &.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 8px;
  }
  &.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    margin-left: -5px;
  }
  &.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 16px;
  }
  &.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 16px;
  }
}