.companies  {
	.submenu {
		.companies-menu-group-name {
			position: absolute;
			display: inline-block;
			height: 50px;
			padding: 4px 4px;
			margin-right: 42px;
			line-height: 20px;
			width: 175px;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.companies-menu-group-name + a {
			margin-left: 175px;
		}
		& + .companies-main {
			max-width: 1240px;
			margin-right: auto;
			margin-left: auto;
			padding: 55px 0 0 0;
		}
	}
	.h3 > .user-image {
		float: left;
		margin: 1px 18px 0 0;
	}
	.h3 > a {
		line-height: 32px;
	}
	a.table-hover-link {
		margin-left: 10px;
		color: var(--sally-subdued-link);

		.icon {
			display: inline-block;
			padding-top: 5px;

			.icon-fill {
				fill: var(--sally-subdued-link);
			}
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
