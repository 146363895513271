.sally-calendar {
  .ant-col {
    padding: 0;
    margin: 0;
  }

  .ant-row {
    & + .ant-row {
      margin-top: 10px;
    }
  }

  .sally-calendar-nav {
    display: flex;
    justify-content: space-between;

    .btn-previous, .btn-next {
      background-repeat: no-repeat;
      background-position-y: 50%;
      background-size: auto 50%;

      &.btn-previous {
        padding-left: 30px;
        background-position-x: 1%;
      }

      &.btn-next {
        padding-right: 30px;
        background-position-x: 99%;
      }
    }
  }

  .sally-calendar-filters {
    text-align: right;

    & > * {
      margin-left: 10px;
    }
  }

  .sally-calendar-legend {
    display: flex;
    justify-content: flex-end;

    div.legend {
      display: flex;
      margin-left: 20px;

      div.colour {
        display: inline-block;
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 5px;
        border-radius: 2px;
      }
    }
  }

  .sally-calendar-help {
    text-align: right;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .table-sally-calendar {
    width: 100%;
    margin-top: 10px;

    th, td {
      border-radius: 2px;
    }

    thead {
      tr {
        th {
          color: var(--sally-black);

          &.header-label {
            text-align: right;
            padding-right: 5px;
          }
        }

        &.header-month-row {
          th {
            font-size: 16pt;
          }
        }

        &.header-date-row, &.header-week-row, &.header-day-row {
          th:not(.header-date-filler) {
            &:nth-child(odd):not(:nth-child(1)) {
              background: var(--sally-white-faded);
            }

            &:nth-child(even) {
              background: #e0e6e9;
            }
          }

          &.header-week-odd {
            th:not(.header-date-filler) {
              &:nth-child(odd):not(:nth-child(1)) {
                background: #e0e6e9;
              }

              &:nth-child(even) {
                background: var(--sally-white-faded);
              }
            }
          }
        }

        &.header-date-row, &.header-day-row {
          th:not(.header-label) {
            width: 2.7%;
          }
        }
      }
    }

    tbody tr {
      th:not(.header-label) {
        text-align: left;
      }

      &.employee-row {
        height: 22px;

        th {
          font-weight: normal;
          padding: 0 5px;

          a {
            font-size: 9pt;

            &.employee-link {
              color: var(--sally-black);
            }
          }
        }

        td {
          text-align: center;
          font-size: 8pt;
          padding: 0;

          &.employee-other.selected {
            background-color: var(--sally-orange);
          }
        }
      }
    }

    tbody {
      &:not(.selecting) tr.employee-row:hover, &.selecting tr.employee-row.active {
        td.employee-other:not(.selected), th {
          background-color: var(--sally-white-faded);
        }
      }

      &.selecting th {
        user-select: none;
      }
    }
  }

  .table-sally-calendar tbody tr.employee-row td {
    &.interactive {
      cursor: pointer;
    }
  }

  .table-sally-calendar tbody tr.employee-row td,
  .sally-calendar-legend div.colour,
  &.sally-single-calendar .table-sally-calendar tbody td {
    &.need-approval {
      box-shadow: 0 0 1px 2px var(--sally-orange);
    }

    &.employee-holiday {
      background: var(--sally-white-shaded);
    }

    &.outside-month {
      color: var(--sally-grey-faded);
    }

    &.employee-not-employed {
      background: var(--sally-grey);
    }

    &.employee-time {
      background: var(--sally-yellow);
    }

    &.employee-leave {
      background: var(--sally-blue-faded);

      &.remote {
        background: var(--sally-green-faded);
      }

      &.sick {
        background: var(--sally-red);
      }

      &.day-off {
        background: var(--sally-yellow);
      }

      &.leave-day {
        background: var(--sally-grey-shaded);
        color: var(--sally-white-shaded);
      }

      &.personal, &.optional, &.unpaid-day-off {
        border-bottom: 5px solid;

        &.personal {
          border-bottom-color: var(--sally-orange);
        }

        &.optional {
          border-bottom-color: #a800cf;
        }

        &.unpaid-day-off {
          border-bottom-color: var(--sally-black);
        }
      }
    }
  }

  &.sally-single-calendar {
    table-layout: fixed;

    h2.h2 {
      margin-top: 15px;
      text-align: center;
      font-weight: bold;
    }

    .table-sally-calendar {
      background: var(--sally-black-faded);
      border-radius: 3px;

      th, td {
        background: var(--sally-white);
      }

      tbody td {
        height: 50px;
        padding: 4px 6px;

        &.week-number {
          width: 75px;
          color: var(--sally-grey-shaded);
        }

        &.date-cell {
          width: 13%;
          vertical-align: top;

          .date-cell-grid {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 25px auto;
            grid-template-areas:
              "date-number"
              "regs";

            .date-number {
              grid-area: date-number;

              .new-reg {
                float: right;
                cursor: pointer;
                color: var(--sally-orange);
                border: var(--sally-grey-faded) 1px solid;
                border-radius: 10px;
                height: 14px;
                width: 14px;
                display: block;
                text-align: center;
                line-height: 12.5px;

                &:hover {
                  color: var(--sally-orange-shaded);
                }
              }
            }

            ul {
              grid-area: regs;
              list-style: circle;
              padding-left: 10px;

              li {
                cursor: pointer;
                color: var(--sally-black);

                &:hover {
                  color: var(--sally-black-faded);
                }
              }
            }
          }

          &:not(.outside-month) {
            .date-number {
              color: var(--sally-grey-shaded);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}