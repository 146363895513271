body .ant-table tr.sub-project td:first-child {
  padding-left: 50px;
}
body .ant-table tr.main-project > td {
  background: linear-gradient(to bottom, #dfe2e5, #fff 10px);
}
body .ant-table tr.main-project:hover > td {
  background: linear-gradient(to bottom, #dfe2e5, #fff 12px);
}

