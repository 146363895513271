.request-password {
  padding: 20px 34px 20px 34px;
}
.request-password .h2 {
  padding-left: 30px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.request-password > p {
  margin: 5px 0 20px 0;
  max-width: 100%;
  line-height: 22px;
  font-size: 14px;
  color: var(--sally-grey-shaded);
}
.request-password .ant-btn[type=submit] {
  margin-top: 15px;
}
.request-password.request-password-completed {
  padding-top: 80px;
  padding-bottom: 25px;
  background-position: center 28px;
  background-repeat: no-repeat;
  background-size: 43px 43px;
  text-align: center;
  font-weight: 500;
  color: var(--sally-black);
}

