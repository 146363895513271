/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-modal {
  position: relative;
  width: auto;
  margin: 0 auto;
  top: 100px;
  padding-bottom: 24px;
}
.ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.ant-modal-title {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  background-clip: padding-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color .3s ease;
  color: rgba(0, 0, 0, 0.43);
  outline: 0;
}
.ant-modal-close-x {
  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}
.ant-modal-close-x:before {
  content: "\e633";
  display: block;
  font-family: "anticon" !important;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #444;
  text-decoration: none;
}
.ant-modal-header {
  padding: 13px 16px;
  border-radius: 4px 4px 0 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e9e9e9;
}
.ant-modal-body {
  padding: 16px;
  font-size: 12px;
  line-height: 1.5;
}
.ant-modal-footer {
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px 10px 10px;
  text-align: right;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-left: 8px;
  margin-bottom: 0;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  animation-duration: 0.3s;
  -ms-transform: none;
      transform: none;
  opacity: 0;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
@media (max-width: 768px) {
  .ant-modal {
    width: auto !important;
    margin: 10px;
  }
  .vertical-center-modal .ant-modal {
    -ms-flex: 1;
        flex: 1;
  }
}
.ant-confirm .ant-modal-header {
  display: none;
}
.ant-confirm .ant-modal-close {
  display: none;
}
.ant-confirm .ant-modal-body {
  padding: 30px 40px;
}
.ant-confirm-body-wrapper {
  zoom: 1;
}
.ant-confirm-body-wrapper:before,
.ant-confirm-body-wrapper:after {
  content: " ";
  display: table;
}
.ant-confirm-body-wrapper:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.ant-confirm-body .ant-confirm-title {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  font-size: 14px;
}
.ant-confirm-body .ant-confirm-content {
  margin-left: 42px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}
.ant-confirm-body > .anticon {
  font-size: 24px;
  margin-right: 16px;
  padding: 0 1px;
  float: left;
}
.ant-confirm .ant-confirm-btns {
  margin-top: 30px;
  float: right;
}
.ant-confirm .ant-confirm-btns button + button {
  margin-left: 10px;
  margin-bottom: 0;
}
.ant-confirm-error .ant-confirm-body > .anticon {
  color: var(--sally-red);
}
.ant-confirm-warning .ant-confirm-body > .anticon,
.ant-confirm-confirm .ant-confirm-body > .anticon {
  color: var(--sally-yellow);
}
.ant-confirm-info .ant-confirm-body > .anticon {
  color: var(--sally-blue);
}
.ant-confirm-success .ant-confirm-body > .anticon {
  color: var(--sally-green);
}

body {
  .ant-modal-mask {
    z-index: 1030;
    background-color: rgba(19, 24, 32, 0.7);
  }
  .ant-modal-wrap {
    z-index: 1030;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal {
      top: 0;
      max-height: 100%;

      .ant-modal-content {
        min-height: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: -2px 1px 8px 0 rgba(0, 0, 0, 0.2);

        .ant-modal-body {
          padding: 0;
          line-height: inherit;
          font-size: inherit;
          background-color: var(--sally-background-layer-1);
          border-radius: var(--sally-radius-6);

          .ant-input-group-wrapper {
            display: block;
            width: 100%;
          }
          .ant-btn[type="submit"].ant-btn-primary {
            display: block;
            width: 100%;
            margin-top: 20px;
            padding-right: 0;
            padding-left: 0;

            &:after {
              content: '';
              display: none;
            }
          }
        }
      }
      .ant-modal-close {
        top: 16px;
        right: 16px;

        .ant-modal-close-x {
          width: 20px;
          height: 20px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 20px 20px;

          &:before {
            content: '';
          }
        }
      }
    }
  }
}
