.package-container {
  margin-bottom: 60px;
}
.package-container .package-addons {
  margin-top: 30px;
}

.campaign-code-activation .h2 {
  font-size: 12pt;
  margin: -5px 0 0;
}
.campaign-code-activation p {
  margin: 0;
}

