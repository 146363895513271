.header {
    z-index: 1040;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    background: var(--sally-header-background);
    box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);
    height: 54px;
    margin: 0;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .main-menu {
        width: 100%;
        height: 54px;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;

        .header-logo, .header-logo-multiple {
            color: var(--sally-logo-text);
            margin-right: 20px;
        }
        .header-logo {
            svg {
                width: 150px;
                height: auto;

                .logo-fill {
                    fill: var(--sally-logo-text);
                }
            }
        }
        .header-logo-multiple {
            text-decoration: 1px underline;
            border-bottom: 2px solid var(--sally-header-background);
            font-weight: bold;
            line-height: 52px;
            font-size: 16px;
            padding-left: 12px;
            padding-right: 12px;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ant-menu.ant-menu-horizontal {
            background-color: var(--sally-header-background);
            line-height: var(--sally-header-line-height);
            font-size: 16px;
            font-weight: var(--sally-header-weight);
            color: var(--sally-header-text);
            border-color: var(--sally-header-background);

            > .ant-menu-item {
                padding: 0;
                border: none;

                & > a, & > div {
                    margin: var(--sally-header-item-vertical-margin) var(--sally-header-item-horizontal-margin);
                    padding: 0 var(--sally-header-item-horizontal-padding);
                    border-radius: var(--sally-header-item-radius);
                    text-decoration: none;
                    color: var(--sally-header-text);
                    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

                    .icon-fill {
                        fill: var(--sally-header-text);
                        transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                }
            }
        }

        .ant-menu.ant-menu-horizontal > .ant-menu-item:hover,
        .header-logo-multiple:hover {
            & > a, & > div {
                background-color: var(--sally-header-item-hover-background);
                color: var(--sally-header-item-hover-text);
                text-decoration: none;

                .icon-fill {
                    fill: var(--sally-header-item-hover-text);
                }
            }
        }

        .ant-menu.ant-menu-horizontal > .ant-menu-item-active,
        .ant-menu.ant-menu-horizontal > .ant-menu-item-selected {
            & > a, & > div {
                background-color: var(--sally-header-item-selected-background);
            }
        }

        .ant-menu.ant-menu-horizontal > .ant-menu-item > a:hover {
            text-decoration: none;
        }
        .ant-menu.ant-menu-horizontal > .ant-menu-item .icon {
            margin: 0 8px -4px 0;
        }
    }

    .right-menu {
        width: 180px;
        display: flex;
        justify-content: flex-end;

        .drop-down-menu, .header-user-menu-button {
            cursor: pointer;

            .icon-fill {
                fill: var(--sally-header-text);
            }
        }
        .drop-down-menu {
            margin-right: 15px;
            padding: 18px 5px 0;

            &.ant-popover-open {
                background-color: var(--sally-header-item-selected-background);
            }
        }
    }
    .header-user-menu-button .user-image {
        background-color: var(--sally-header-user-image-background);
        color: var(--sally-header-user-image-text);
        margin-top: 8px;
    }

    .notification-button {
        position: relative;
        display: inline-block;
        margin-top: 10px;
        margin-right: 20px;
        padding: 6px 10px;
        vertical-align: top;
        cursor: pointer;

        .icon-fill {
            fill: var(--sally-header-text);
        }

        .notification-badge {
            position: absolute;
            top: 3px;
            right: 7px;
            padding: 0 3px;
            background-color: var(--sally-alert-background);
            border-radius: 50%;
            font-size: 8px;
            font-weight: 700;
            color: var(--sally-alert-text);
        }
    }
}
.header-user-menu-popover {
    right: 0;
    left: auto !important;
    margin-top: 0;
    padding-top: 6px;

    .ant-popover-content {
        margin-top: -10px;
    }
}
.header-dropdown-menu-popover {
    .ant-popover-content {
        margin-top: -12px;
    }
}
.header-user-menu-popover, .header-dropdown-menu-popover {
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        background-color: transparent;
        background-clip: border-box;
        border-radius: 0;
        box-shadow: none;
    }
    .ant-popover-inner-content {
        padding: 0;
    }
}
.header-user-menu.ant-menu {
    overflow-x: hidden;

    .ant-menu-item {
        height: 44px;
        line-height: 44px;
        padding: 0 24px 0 20px;

        & > a {
            text-decoration: none;
            font-size: 14px;
            font-weight: 700;
            color: var(--sally-header-text);

            .icon-fill {
                fill: var(--sally-header-text);
            }

            .icon, .user-image {
                float: left;
                display: block;
            }
            .icon {
                margin: 12px 9px 0 2px;
            }
            .user-image {
                margin: 10px 7px 0 0;
            }
        }
    }
}
.header-user-menu.ant-menu {
    min-width: 350px;
    max-height: 500px;
    background-color: var(--sally-header-background);
    border-right: 0;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);

    .ant-menu-item {
        .search-field {
            .icon {
                margin: 0 10px -5px 0;

                .icon-fill {
                    fill: var(--sally-header-text);
                }
            }

            .ant-input {
                width: 200px;
                height: 28px;
                padding: 8px 8px;
            }
        }

        .cvr {
            float: right;
            padding-left: 10px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
        }
        &:hover, &.ant-menu-item-active {
            background-color: var(--sally-header-item-hover-background);

            .icon-fill {
                fill: var(--sally-header-item-hover-text);
                transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            > a, > div {
                color: var(--sally-header-item-hover-text);
                background-color: transparent;
            }
        }
        &.ant-menu-item-selected {
            background-color: var(--sally-header-item-selected-background);

            > a, > div {
                background-color: transparent;
            }
        }
    }

    .ant-menu-item-divider {
        height: 14px;
        margin: 0;
        border-top: 1px solid var(--sally-header-submenu-divider);
        background-color: var(--sally-header-submenu-divider);
    }
}
body .header-dropdown-menu.ant-menu {
    overflow-x: hidden;
    min-width: 150px;
    background-color: var(--sally-header-background);
    line-height: var(--sally-header-line-height);
    font-size: 16px;
    font-weight: var(--sally-header-weight);
    color: var(--sally-header-text);
    border-color: var(--sally-header-background);

    .ant-menu-item {
        padding: 0 24px 0 20px;
        line-height: var(--sally-header-line-height);
        font-size: 16px;
        height: auto;

        .icon {
            margin: 0 8px -4px 0;
        }

        & > a {
            margin: var(--sally-header-item-vertical-margin) var(--sally-header-item-horizontal-margin);
            padding: 0 var(--sally-header-item-horizontal-padding);
            border-radius: var(--sally-header-item-radius);
            text-decoration: none;
            color: var(--sally-header-text);

            .icon-fill {
                fill: var(--sally-header-text);
                transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }

        &.ant-menu-item-active,
        &:hover {
            background: inherit;

            & > a {
                background-color: var(--sally-header-item-hover-background);
                color: var(--sally-header-item-hover-text);
                text-decoration: none;

                .icon-fill {
                    fill: var(--sally-header-item-hover-text);
                }
            }
        }
        &.ant-menu-item-selected {
            & > a {
                background-color: var(--sally-header-item-selected-background);
            }
        }
    }
    .ant-menu-item-divider {
        height: 14px;
        margin: 0;
        border-top: 1px solid var(--sally-header-submenu-divider);
        background-color: var(--sally-header-submenu-divider);
    }
}