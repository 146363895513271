/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-cascader {
  font-size: 12px;
}

.ant-cascader-input.ant-input {
  background-color: transparent !important;
  cursor: pointer;
  width: 100%;
  display: block;
}

.ant-cascader-picker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  background-color: var(--sally-input-background);
  border-radius: 4px;
  outline: 0;
}

.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}

.ant-cascader-picker-disabled {
  cursor: not-allowed;
  background: var(--sally-input-disabled-background);
  color: var(--sally-input-disabled-text);
}

.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}

.ant-cascader-picker:focus .ant-cascader-input {
  outline: 0;
}

.ant-cascader-picker-label {
  position: absolute;
  left: 0;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 12px 0 8px;
}

.ant-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  right: 8px;
  z-index: 2;
  background: var(--sally-input-background);
  top: 50%;
  font-size: 12px;
  color: var(--sally-input-text);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.ant-cascader-picker-clear:hover {
  color: var(--sally-switch-hover-background);
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}

.ant-cascader-picker-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  color: var(--sally-input-text);
  display: inline-block;
  font-size: 12px;
  font-size: 9px \9 ;
  -ms-transform: scale(0.75) rotate(0deg);
  transform: scale(0.75) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
}

:root .ant-cascader-picker-arrow {
  -webkit-filter: none;
  filter: none;
}

:root .ant-cascader-picker-arrow {
  font-size: 12px;
}

.ant-cascader-picker-arrow:before {
  transition: transform 0.2s ease;
}

.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
}

.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand:before {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-cascader-menus {
  font-size: 12px;
  background: var(--sally-input-background);
  position: absolute;
  z-index: 1050;
  border-radius: 4px;
  box-shadow: 0 1px 6px var(--sally-input-shadow);
  white-space: nowrap;
}

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}

.ant-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 180px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid var(--sally-input-border);
  overflow: auto;
}

.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}

.ant-cascader-menu:last-child {
  border-right-color: transparent;
  margin-right: -1px;
  border-radius: 0 4px 4px 0;
}

.ant-cascader-menu:only-child {
  border-radius: 4px;
}

.ant-cascader-menu-item {
  padding: 7px 8px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s;
}

.ant-cascader-menu-item:hover {
  background-color: var(--sally-select-item-hover-background);
}

.ant-cascader-menu-item-disabled {
  cursor: not-allowed;
  color: var(--sally-input-disabled-text);
}

.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: var(--sally-select-item-selected-background);
  font-weight: 600;
}

.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}

body .ant-cascader-picker .ant-cascader-picker-label {
  padding: 0 35px 0 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-input-text);
}
body .ant-cascader-picker .ant-cascader-picker-arrow {
  right: 6px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
body .ant-cascader-picker .ant-cascader-picker-arrow:before {
  content: "";
}
body .ant-cascader-picker .ant-cascader-picker-label span,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span {
  display: inline-block;
  padding-left: 6px;
}
body .ant-cascader-picker .ant-cascader-picker-label span:first-child,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span:first-child {
  padding-left: 0;
}
body .ant-cascader-picker .ant-cascader-picker-label span:last-child,
body .ant-cascader-menus .ant-cascader-menu .ant-cascader-menu-item span:last-child {
  padding-right: 0;
  background: none;
}

.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft {
  border: 1px solid var(--sally-input-border);
  box-shadow: 0 2px 4px 0 rgba(116, 141, 166, 0.12);
  font-size: 14px;
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu {
  border-right: 1px solid var(--sally-input-border);
  padding: var(--sally-select-padding);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item {
  padding: 7px 8px;
  font-weight: 500;
  color: var(--sally-input-text);
  border-radius: var(--sally-select-item-radius);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item:not(:last-child) {
  border-bottom: 1px solid var(--sally-select-item-divider);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  background-color: var(--sally-select-item-selected-background);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item:hover {
  background-color: var(--sally-select-item-hover-background);
  color: var(--sally-select-item-hover-text);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .ant-cascader-menu-item:hover .icon-fill {
  fill: var(--sally-select-item-hover-text);
}
.ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-menu .icon, .ant-cascader-menus.ant-cascader-menus-placement-bottomLeft .ant-cascader-picker-label .icon {
  margin-left: 5px;
}

