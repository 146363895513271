.preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);

    .loading-overlay {
        background-color: transparent;
    }
    iframe {
        position: absolute;
        top: 50px;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: scroll;
        z-index: 3;
        width: 100%;
        height: calc(100% - 50px);
        background-color: transparent;
        border: 0;
    }
    .preview-header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 4;
        width: 100%;
        height: 50px;

        .h2 {
            line-height: 50px;
            text-align: center;
            font-weight: 500;
            color: #fff;
        }
        .preview-download {
            position: absolute;
            top: 15px;
            left: 30px;
            display: inline-block;
            padding-left: 30px;
            background-position: center left;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            text-decoration: none;
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            cursor: pointer;
        }
        .preview-close {
            position: absolute;
            top: 15px;
            right: 30px;
            display: inline-block;
            padding-left: 30px;
            background-position: center left;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            cursor: pointer;
        }
    }
}