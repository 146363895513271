/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox-wrapper {
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
}
.ant-checkbox-wrapper .ant-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: text-bottom;
}
.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--sally-input-border);
  border-radius: 2px;
  background-color: var(--sally-input-background);
  transition: all 0.3s;
}
.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner:after {
  -ms-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid var(--sally-input-border);
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid var(--sally-toggle-checked-text);
  border-top: 0;
  border-left: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  background-color: var(--sally-toggle-focus-background);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner:after {
  border-color: var(--sally-toggle-focus-text);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--sally-toggle-checked-background);
  border-color: var(--sally-toggle-checked-border);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  content: " ";
  -ms-transform: scale(1);
  transform: scale(1);
  position: absolute;
  left: 2px;
  top: 5px;
  width: 8px;
  height: 1px;
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: var(--sally-input-disabled-text);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-input:focus + .ant-checkbox-inner {
  background-color: var(--sally-toggle-focus-background);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-input:focus + .ant-checkbox-inner:after {
  border-color: var(--sally-toggle-focus-text);
}
.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  animation-name: none;
  border-color: var(--sally-input-disabled-text);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: var(--sally-input-disabled-border);
  background-color: var(--sally-input-disabled-background);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner:after {
  animation-name: none;
  border-color: var(--sally-input-disabled-border);
}
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-disabled + span {
  color: var(--sally-input-disabled-text);
  cursor: not-allowed;
}
.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--sally-input-hover-border);
}
.ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--sally-toggle-focus-border);
}
.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:hover .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner,
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate:hover .ant-checkbox-inner {
  background-color: var(--sally-toggle-hover-background);
}
.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after,
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:hover .ant-checkbox-inner:after, .ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-indeterminate:hover .ant-checkbox-inner:after {
  border-color: var(--sally-toggle-hover-text);
}
.ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: visible;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper + span,
.ant-checkbox-wrapper .ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-checkbox-wrapper + span span,
.ant-checkbox-wrapper .ant-checkbox + span span {
  padding-left: 29px;
}

.ant-checkbox-group {
  font-size: 12px;
}
.ant-checkbox-group .ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group .ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

