.ant-select-selection-selected-value {
  .integration-features {
    display: none;
  }
}
.ant-select-dropdown-menu-item {
  .integration-features {
    float: right;
  }
}
.integration-import-button-row {
  margin-bottom: 20px;

  .ant-btn {
    &:not(:first-of-type) {
      margin-left: 25px;
    }
  }
}
.employees-import-list, .time-registration-integration-import-list, .leave-registration-integration-import-list {
  .ant-table {
    th, td {
      white-space: normal;
      word-break: normal;

      &.include {
        max-width: 25px;
        padding-left: 12px !important;
        padding-right: 0;
      }
    }
    .ant-table-body .ant-table-tbody > tr > td {
      padding-top: 10px;
      padding-bottom: 10px;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &.email {
        max-width: 150px;
      }
    }
    .employees-import-error, .time-registration-integration-error, .leave-registration-integration-error {
      color: var(--sally-status-failure-text-on-white);
      .icon-fill {
        fill: var(--sally-status-failure-text-on-white);
      }
    }
    .employees-import-warning {
      color: var(--sally-status-warning-text-on-white);
      .icon-fill {
        fill: var(--sally-status-warning-text-on-white)
      }
    }
    .time-registration-integration-note, .leave-registration-integration-note {
      color: var(--sally-status-info-text-on-white);
      .icon-fill {
        fill: var(--sally-status-warning-text-on-white)
      }
    }
  }
}
.data-integration-container {
  .title-menu {
    display: flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-between;
    align-items: flex-end;

    .asynchronous-task-selector {
      width: 400px;
    }
  }
  .h1 {
    margin-bottom: calc(24px + 15px);
  }
  .button-row-wrapper {
    display: flex;
    align-items: baseline;

    .ant-btn {
      & + .ant-btn {
        margin-left: 10px;
      }
    }
  }
}