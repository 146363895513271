.package-upgrade-deal-subtitle {
  margin-top: 12px;
  font-size: 20px;
  color: var(--sally-ad-modal-subtitle);
  padding-top: 1em;
  width: 80%;
  text-align: center;
  margin: auto;
}

.package-upgrade-deal-price {
  font-size: 100px;
  font-weight: 200;
  color: var(--sally-ad-highlight-price);
}

.package-upgrade-deal-price-old {
  text-decoration: line-through;
  font-size: 40px;
  padding-top: 0;
  margin-top: 0;
  text-decoration-thickness: 2px;
  color: var(--sally-ad-modal-subtitle);
}

.package-upgrade-deal-price-details {
  font-size: 16px;
  color: var(--sally-ad-modal-subtitle);
}

.package-upgrade-deal-price-details-extra {
  font-size: 16px;
  color: var(--sally-ad-modal-subtitle);
}
.package-upgrade-deal-price-details-extra:first-of-type {
  padding-top: 1em;
}
.package-upgrade-deal-price-details-extra:last-child {
  margin-bottom: 2em;
}

.package-upgrade-deal-button {
  margin-top: 3em;
}

.package-upgrade-deal-rows p {
  font-size: 16px;
}

