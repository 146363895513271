.approve-tab .title-menu a + *, .approve-tab .title-menu span + *, .approve-tab .title-menu .ant-btn + * {
  margin-left: 20px;
}
.approve-tab .title-menu label {
  display: inline;
}
.approve-tab .approve-box .h1 {
  font-size: 14pt;
  margin-bottom: 20px;
}
.approve-tab .approve-box span.approve-link {
  color: var(--sally-link);
  cursor: pointer;
  float: right;
}
.approve-tab .approve-box span.approve-link:hover {
  text-decoration: underline;
}
.approve-tab tr > th, .approve-tab tr > td {
  word-break: break-word;
}
.approve-tab td.ant-table-col-thumbnail {
  width: 1px;
}
.approve-tab td.ant-table-col-thumbnail .voucher-image {
  max-width: 50px;
  height: 50px;
  cursor: pointer;
}
.approve-tab td.ant-table-col-thumbnail .voucher-image img {
  max-width: 50px;
  height: 50px;
}

