/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-timeline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-timeline .ant-timeline-item {
  position: relative;
  padding: 0 0 12px;
  list-style: none;
  margin: 0;
}
.ant-timeline .ant-timeline-item .ant-timeline-item-tail {
  position: absolute;
  left: 5px;
  top: 0;
  height: 100%;
  border-left: 2px solid var(--sally-card-border);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-head {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--sally-background);
  border-radius: 100px;
  border: 2px solid transparent;
}
.ant-timeline .ant-timeline-item .ant-timeline-item-head.ant-timeline-item-head-awaiting {
  border-color: var(--sally-status-awaiting-system-text);
  color: var(--sally-status-awaiting-system-text);
  background-color: var(--sally-status-awaiting-system-background);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-head.ant-timeline-item-head-error {
  border-color: var(--sally-status-failure-text);
  color: var(--sally-status-failure-text);
  background-color: var(--sally-status-failure-background);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-head.ant-timeline-item-head-error {
  border-color: var(--sally-status-warning-text);
  color: var(--sally-status-warning-text);
  background-color: var(--sally-status-warning-background);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-head.ant-timeline-item-head-success {
  border-color: var(--sally-status-success-text);
  color: var(--sally-status-success-text);
  background-color: var(--sally-status-success-background);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-content {
  padding: 0 0 10px 24px;
  font-size: 12px;
  position: relative;
  top: -3px;
  color: var(--sally-text);
}
.ant-timeline .ant-timeline-item .ant-timeline-item-content span {
  display: block;
  font-weight: 400;
  color: var(--sally-paragraph-text);
}
.ant-timeline .ant-timeline-item:last-child .ant-timeline-item-tail {
  border: none;
}

