.responsive-note.ant-modal {
  margin: 0 auto;
  padding: 0 5px;
  width: 95% !important;
}
.responsive-note.ant-modal .ant-modal-close {
  display: none;
}
.responsive-note.ant-modal .ant-modal-body .ant-card-body {
  padding: 15px 10px;
}
.responsive-note.ant-modal .ant-modal-body .ant-card-body .ant-btn {
  width: 100%;
}

