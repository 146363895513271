.notifications {
    .h3 {
        line-height: inherit;

        small {
            max-width: 500px;
            word-break: normal;
        }
    }
    .ant-table .ant-table-row {
        td {
            padding-top: 29px;
            padding-bottom: 29px;

            &:first-child {
                padding-left: 95px;
                background-position: 35px center;
                background-repeat: no-repeat;
                background-size: 32px 32px;
            }
        }
        &.notifications-table-row-success {
            td:first-child {
                color: var(--sally-status-success-text-on-white);
            }
        }
        &.notifications-table-row-warning {
            td:first-child {
                color: var(--sally-status-awaiting-user-text-on-white);
            }
        }
        &.notifications-table-row-error {
            td:first-child {
                color: var(--sally-status-failure-text-on-white);
            }
        }
        &.notifications-table-row-clickable {
            cursor: pointer;
        }
    }
}
