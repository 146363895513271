.employees .title-menu a + *, .employees .title-menu span + *, .employees .title-menu .ant-btn + * {
  margin-left: 20px;
}
.employees .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.employees .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.employees .title-menu a + a .ant-btn {
  padding-left: 20px;
  font-size: 14px;
}
.employees .h3 {
  line-height: inherit;
}
.employees .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.employees .employees-search-remuneration-types .ant-radio-wrapper {
  margin: 0 5px 0 0;
  padding: 0;
}
.employees .employees-search-remuneration-types .ant-radio-wrapper .ant-radio, .employees .employees-search-remuneration-types .ant-radio-wrapper span {
  padding: 0;
  margin: 0 0 0 5px;
}
.employees .employees-terminated {
  color: var(--sally-grey-shaded);
}
.employees .employees-toggle {
  display: inline-block;
  margin-top: 16px;
  padding: 0 20px 0 0;
  background-repeat: no-repeat;
  background-position: right 4px;
  background-size: 12px 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-grey-shaded);
  cursor: pointer;
}
.employees .employees-table {
  overflow-y: hidden;
  max-height: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.employees .employees-table.employees-table-visible {
  max-height: 5600px;
}

