.sally-calendar .ant-col {
  padding: 0;
  margin: 0;
}
.sally-calendar .ant-row + .ant-row {
  margin-top: 10px;
}
.sally-calendar .sally-calendar-nav {
  display: flex;
  justify-content: space-between;
}
.sally-calendar .sally-calendar-nav .btn-previous, .sally-calendar .sally-calendar-nav .btn-next {
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: auto 50%;
}
.sally-calendar .sally-calendar-nav .btn-previous.btn-previous, .sally-calendar .sally-calendar-nav .btn-next.btn-previous {
  padding-left: 30px;
  background-position-x: 1%;
}
.sally-calendar .sally-calendar-nav .btn-previous.btn-next, .sally-calendar .sally-calendar-nav .btn-next.btn-next {
  padding-right: 30px;
  background-position-x: 99%;
}
.sally-calendar .sally-calendar-filters {
  text-align: right;
}
.sally-calendar .sally-calendar-filters > * {
  margin-left: 10px;
}
.sally-calendar .sally-calendar-legend {
  display: flex;
  justify-content: flex-end;
}
.sally-calendar .sally-calendar-legend div.legend {
  display: flex;
  margin-left: 20px;
}
.sally-calendar .sally-calendar-legend div.legend div.colour {
  display: inline-block;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
  border-radius: 2px;
}
.sally-calendar .sally-calendar-help {
  text-align: right;
}
.sally-calendar .sally-calendar-help p {
  padding: 0;
  margin: 0;
}
.sally-calendar .table-sally-calendar {
  width: 100%;
  margin-top: 10px;
}
.sally-calendar .table-sally-calendar th, .sally-calendar .table-sally-calendar td {
  border-radius: 2px;
}
.sally-calendar .table-sally-calendar thead tr th {
  color: var(--sally-black);
}
.sally-calendar .table-sally-calendar thead tr th.header-label {
  text-align: right;
  padding-right: 5px;
}
.sally-calendar .table-sally-calendar thead tr.header-month-row th {
  font-size: 16pt;
}
.sally-calendar .table-sally-calendar thead tr.header-date-row th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)), .sally-calendar .table-sally-calendar thead tr.header-week-row th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)), .sally-calendar .table-sally-calendar thead tr.header-day-row th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)) {
  background: var(--sally-white-faded);
}
.sally-calendar .table-sally-calendar thead tr.header-date-row th:not(.header-date-filler):nth-child(even), .sally-calendar .table-sally-calendar thead tr.header-week-row th:not(.header-date-filler):nth-child(even), .sally-calendar .table-sally-calendar thead tr.header-day-row th:not(.header-date-filler):nth-child(even) {
  background: #e0e6e9;
}
.sally-calendar .table-sally-calendar thead tr.header-date-row.header-week-odd th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)), .sally-calendar .table-sally-calendar thead tr.header-week-row.header-week-odd th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)), .sally-calendar .table-sally-calendar thead tr.header-day-row.header-week-odd th:not(.header-date-filler):nth-child(odd):not(:nth-child(1)) {
  background: #e0e6e9;
}
.sally-calendar .table-sally-calendar thead tr.header-date-row.header-week-odd th:not(.header-date-filler):nth-child(even), .sally-calendar .table-sally-calendar thead tr.header-week-row.header-week-odd th:not(.header-date-filler):nth-child(even), .sally-calendar .table-sally-calendar thead tr.header-day-row.header-week-odd th:not(.header-date-filler):nth-child(even) {
  background: var(--sally-white-faded);
}
.sally-calendar .table-sally-calendar thead tr.header-date-row th:not(.header-label), .sally-calendar .table-sally-calendar thead tr.header-day-row th:not(.header-label) {
  width: 2.7%;
}
.sally-calendar .table-sally-calendar tbody tr th:not(.header-label) {
  text-align: left;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row {
  height: 22px;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row th {
  font-weight: normal;
  padding: 0 5px;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row th a {
  font-size: 9pt;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row th a.employee-link {
  color: var(--sally-black);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td {
  text-align: center;
  font-size: 8pt;
  padding: 0;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-other.selected {
  background-color: var(--sally-orange);
}
.sally-calendar .table-sally-calendar tbody:not(.selecting) tr.employee-row:hover td.employee-other:not(.selected), .sally-calendar .table-sally-calendar tbody:not(.selecting) tr.employee-row:hover th, .sally-calendar .table-sally-calendar tbody.selecting tr.employee-row.active td.employee-other:not(.selected), .sally-calendar .table-sally-calendar tbody.selecting tr.employee-row.active th {
  background-color: var(--sally-white-faded);
}
.sally-calendar .table-sally-calendar tbody.selecting th {
  user-select: none;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.interactive {
  cursor: pointer;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.need-approval,
.sally-calendar .sally-calendar-legend div.colour.need-approval, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.need-approval {
  box-shadow: 0 0 1px 2px var(--sally-orange);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-holiday,
.sally-calendar .sally-calendar-legend div.colour.employee-holiday, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-holiday {
  background: var(--sally-white-shaded);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.outside-month,
.sally-calendar .sally-calendar-legend div.colour.outside-month, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.outside-month {
  color: var(--sally-grey-faded);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-not-employed,
.sally-calendar .sally-calendar-legend div.colour.employee-not-employed, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-not-employed {
  background: var(--sally-grey);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-time,
.sally-calendar .sally-calendar-legend div.colour.employee-time, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-time {
  background: var(--sally-yellow);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave,
.sally-calendar .sally-calendar-legend div.colour.employee-leave, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave {
  background: var(--sally-blue-faded);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.remote,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.remote, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.remote {
  background: var(--sally-green-faded);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.sick,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.sick, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.sick {
  background: var(--sally-red);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.day-off,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.day-off, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.day-off {
  background: var(--sally-yellow);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.leave-day,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.leave-day, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.leave-day {
  background: var(--sally-grey-shaded);
  color: var(--sally-white-shaded);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.personal, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.optional, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.unpaid-day-off,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.personal,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.optional,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.unpaid-day-off, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.personal, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.optional, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.unpaid-day-off {
  border-bottom: 5px solid;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.personal.personal, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.optional.personal, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.unpaid-day-off.personal,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.personal.personal,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.optional.personal,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.unpaid-day-off.personal, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.personal.personal, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.optional.personal, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.unpaid-day-off.personal {
  border-bottom-color: var(--sally-orange);
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.personal.optional, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.optional.optional, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.unpaid-day-off.optional,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.personal.optional,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.optional.optional,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.unpaid-day-off.optional, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.personal.optional, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.optional.optional, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.unpaid-day-off.optional {
  border-bottom-color: #a800cf;
}
.sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.personal.unpaid-day-off, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.optional.unpaid-day-off, .sally-calendar .table-sally-calendar tbody tr.employee-row td.employee-leave.unpaid-day-off.unpaid-day-off,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.personal.unpaid-day-off,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.optional.unpaid-day-off,
.sally-calendar .sally-calendar-legend div.colour.employee-leave.unpaid-day-off.unpaid-day-off, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.personal.unpaid-day-off, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.optional.unpaid-day-off, .sally-calendar.sally-single-calendar .table-sally-calendar tbody td.employee-leave.unpaid-day-off.unpaid-day-off {
  border-bottom-color: var(--sally-black);
}
.sally-calendar.sally-single-calendar {
  table-layout: fixed;
}
.sally-calendar.sally-single-calendar h2.h2 {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
}
.sally-calendar.sally-single-calendar .table-sally-calendar {
  background: var(--sally-black-faded);
  border-radius: 3px;
}
.sally-calendar.sally-single-calendar .table-sally-calendar th, .sally-calendar.sally-single-calendar .table-sally-calendar td {
  background: var(--sally-white);
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td {
  height: 50px;
  padding: 4px 6px;
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.week-number {
  width: 75px;
  color: var(--sally-grey-shaded);
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell {
  width: 13%;
  vertical-align: top;
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 25px auto;
  grid-template-areas: "date-number" "regs";
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid .date-number {
  grid-area: date-number;
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid .date-number .new-reg {
  float: right;
  cursor: pointer;
  color: var(--sally-orange);
  border: var(--sally-grey-faded) 1px solid;
  border-radius: 10px;
  height: 14px;
  width: 14px;
  display: block;
  text-align: center;
  line-height: 12.5px;
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid .date-number .new-reg:hover {
  color: var(--sally-orange-shaded);
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid ul {
  grid-area: regs;
  list-style: circle;
  padding-left: 10px;
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid ul li {
  cursor: pointer;
  color: var(--sally-black);
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell .date-cell-grid ul li:hover {
  color: var(--sally-black-faded);
}
.sally-calendar.sally-single-calendar .table-sally-calendar tbody td.date-cell:not(.outside-month) .date-number {
  color: var(--sally-grey-shaded);
  font-weight: bold;
}

