@charset "UTF-8";
.react-datepicker-container .react-datepicker-input-wrapper {
  border: 1px solid var(--sally-input-border);
  font-size: 14px;
  font-weight: 500;
  color: var(--sally-input-text);
  height: 38px;
  padding: 8px 15px;
  border-radius: 4px;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
}
.react-datepicker-container .react-datepicker-input-wrapper.react-datepicker-instructions .react-datepicker-instructions-text {
  text-align: center;
  flex-grow: 2;
}
.react-datepicker-container .react-datepicker-input-wrapper input {
  flex-grow: 2;
  border: none;
  outline: none;
}
.react-datepicker-container .react-datepicker-input-wrapper input:hover, .react-datepicker-container .react-datepicker-input-wrapper input:focus-visible, .react-datepicker-container .react-datepicker-input-wrapper input:focus {
  border: none;
  outline: none;
}
.react-datepicker-container .react-datepicker-input-wrapper.react-datepicker-input-range .picker-separator {
  margin: 0 15px;
}
.react-datepicker-container .react-datepicker-input-wrapper.react-datepicker-input-range input {
  text-align: center;
  max-width: 35%;
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons {
  width: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons .picker-icon {
  float: right;
  width: 20px;
  height: 20px;
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons .picker-icon .icon-fill {
  fill: var(--sally-input-text);
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons .picker-clear {
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  margin-top: 4px;
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons .picker-clear .icon-fill {
  fill: var(--sally-input-text);
}
.react-datepicker-container .react-datepicker-input-wrapper .picker-icons .picker-clear:hover {
  color: var(--sally-datepicker-clear-hover);
}
.react-datepicker-container:hover .picker-icons .picker-clear {
  opacity: 1;
}
.react-datepicker-container .react-datepicker-popper {
  position: absolute;
  z-index: 1050;
  background-clip: padding-box;
  background-color: var(--sally-datepicker-background);
  border: 1px solid var(--sally-datepicker-background);
  border-radius: 4px;
  box-shadow: 0 1px 6px var(--sally-datepicker-shadow);
  font-size: 12px;
  line-height: 1.5;
  list-style: none;
  outline: none;
  text-align: left;
}
.react-datepicker-container .react-datepicker-popper.two-months-shown {
  width: 504px;
  left: calc((100% - 504px) /2) !important;
}
.react-datepicker-container .react-datepicker-popper:not(.two-months-shown) {
  width: 252px;
  left: calc((100% - 252px) / 2) !important;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 251px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .react-datepicker__navigation {
  color: var(--sally-link);
  font-weight: 700;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  width: 50px;
  z-index: 1051;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .react-datepicker__navigation span {
  display: none;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .react-datepicker__navigation.react-datepicker__navigation--previous:before {
  content: "‹";
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .react-datepicker__navigation.react-datepicker__navigation--next:after {
  content: "›";
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .react-datepicker__navigation:hover {
  color: var(--sally-link-hover);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__header .react-datepicker__header-wrapper .datepicker-select {
  background-color: var(--sally-input-background);
  border-radius: 4px;
  border: 1px solid var(--sally-input-border);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container {
  float: left;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header {
  text-align: center;
  position: relative;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  color: var(--sally-input-text);
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  padding: 0 2px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown {
  padding: 2px 30px 4px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container, .react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
  margin-bottom: 4px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container, .react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container.react-datepicker__month-dropdown-container {
  float: left;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container.react-datepicker__year-dropdown-container, .react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container {
  float: right;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__month-dropdown-container select, .react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select {
  background: var(--sally-input-background);
  border-radius: 2px;
  border: 1px solid var(--sally-input-border);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
  border-top: 1px solid var(--sally-datepicker-border);
  clear: both;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day, .react-datepicker-container .react-datepicker-popper .react-datepicker__day-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--outside-month, .react-datepicker-container .react-datepicker-popper .react-datepicker__day-name.react-datepicker__day--outside-month {
  color: var(--sally-datepicker-outside-month);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day-name {
  text-transform: uppercase;
  color: var(--sally-datepicker-day-name);
  font-size: 12px;
  font-weight: 700;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day-name:first-child, .react-datepicker-container .react-datepicker-popper .react-datepicker__week-number {
  display: inline-block;
  width: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  margin: 0.1rem;
  font-size: 10px;
  color: var(--sally-datepicker-week-number);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day {
  color: var(--sally-datepicker-text);
  cursor: pointer;
  border-radius: 16px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day:hover {
  color: var(--sally-datepicker-day-hover-text);
  background-color: var(--sally-datepicker-day-hover-background);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day:hover.react-datepicker__day--outside-month {
  color: var(--sally-datepicker-day-hover-outside-month-text);
  background-color: var(--sally-datepicker-day-hover-outside-month-background);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--in-selecting-range {
  background: var(--sally-datepicker-day-range-background);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--selected, .react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--in-range {
  color: var(--sally-datepicker-day-selected-text);
  background: var(--sally-datepicker-day-selected-background);
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--today {
  font-weight: bold;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--disabled {
  color: var(--sally-datepicker-day-disabled);
  font-weight: normal;
  border: 0;
  cursor: not-allowed;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__day.react-datepicker__day--disabled:hover {
  background: inherit;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__today-button {
  border-top: 1px solid var(--sally-button-tertiary-border);
  color: var(--sally-link);
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  clear: both;
  padding-top: 10px;
  height: 40px;
}
.react-datepicker-container .react-datepicker-popper .react-datepicker__today-button:hover {
  text-decoration: underline;
}

