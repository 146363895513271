div .ant-modal-wrap {
  display: flex;
  align-items: flex-start;
  top: 10%;
  position: fixed;



  .ant-modal-content {
    border-radius: var(--sally-radius-10);
    background-color: transparent;
    text-decoration: none;


    .global-search {
      input {
        text-decoration: none;
        height: var(--sally-input-xl-height);
        border-radius: var(--sally-radius-10);
        font-family: var(--sally-font-family);
        font-size: 16px;
      }

      .global-search-item {
        a {
          text-decoration: none;
        }


        .ant-row {
          border-radius: var(--sally-radius-10);
          font-family: var(--sally-font-family);
        }

        .global-search-item-title h2 {
          font-size: 15px;
          font-weight: bold;
          color: var(--sally-text);
          text-decoration: none;
        }

        .global-search-item-text p {
          color: var(--sally-paragraph-text);
          text-decoration: none;
          font-weight: 400;
        }

        &.global-search-selected {
          background-color: var(--sally-toggle-checked-background);

          p {
            color: var(--sally-text);
            text-decoration: none;
          }

          a .global-search-item-title.h2 {
            color: var(--sally-text);
            text-decoration: none;
          }
        }
      }
    }

  }
}