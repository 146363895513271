body .work-hours {
  .title-menu {
    a, span, div, .ant-btn {
      & + * {
        margin-left: 20px;
      }
    }
    .ant-btn {
      padding-right: 14px;
    }
    label {
      display: inline;
    }
    .ant-input-search.ant-input-affix-wrapper {
      width: inherit;

      .ant-input {
        width: 200px;
      }
    }
  }
  .h2 {
    text-align: center;
  }
  .h3 {
    line-height: inherit;

    > .user-image {
      float: left;
      margin: 3px 18px 0 0;
    }
  }
  .violations-icon {
    fill: var(--sally-status-warning-background);
  }
}
.ant-tooltip-inner {
  p {
    color: var(--sally-background);

    &:last-child {
      margin-bottom: 0;
    }
  }
}