body {
  .employee-search-search-results {
    .ant-row {
      margin-left: -25px;
      margin-right: -25px;
    }

    .employee-search-search-result-container {
      .employee-search-employee {
        position: relative;
        width: 100%;
        padding: 8px 8px 8px 16px;
        border: 1px solid #e1e6eb;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(57, 85, 106, 0.02);
        font-size: 14px;
        font-weight: 500;
        color: var(--sally-black);
        margin-top: 8px;

        &:first-child {
          margin-top: 0;
        }

        & button {
          padding-right: 12px;
        }

        .message {
          font-weight: bold;
        }

        .employee-search-select, .employee-search-deselect {
          padding-left: 2px;
          padding-right: 2px;

          .icon {
            margin-right: 0;
          }

          &.employee-search-select {
            float: right;
          }
        }
      }

      .employee-search-deselect {

      }
    }
  }
  .employee-search-search {
    .ant-switch-wrapper {
      .ant-switch-text {
        font-weight: normal;
      }
    }
  }
}