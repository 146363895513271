/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable declaration-bang-space-before */
/* stylelint-disable declaration-bang-space-before */
.ant-spin {
  color: var(--sally-blue);
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  font-size: 12px;
  display: none;
}

.ant-spin-spinning {
  opacity: 1;
  position: static;
  display: inline-block;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  height: 100%;
  max-height: 320px;
  width: 100%;
  z-index: 4;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 6px;
}

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 3px;
}

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 12px;
}

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}

.ant-spin-container {
  position: relative;
}

.ant-spin-blur {
  overflow: hidden;
  opacity: 0.7;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  /* autoprefixer: off */
  filter: progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false);
  -webkit-transform: translateZ(0);
}

.ant-spin-blur:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  transition: all 0.3s;
  z-index: 10;
}

.ant-spin-tip {
  color: rgba(0, 0, 0, 0.43);
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}

.ant-spin-dot i {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: var(--sally-blue);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  display: block;
  position: absolute;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.ant-spin-dot i:nth-child(1) {
  left: 0;
  top: 0;
}

.ant-spin-dot i:nth-child(2) {
  right: 0;
  top: 0;
  animation-delay: 0.4s;
}

.ant-spin-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}

.ant-spin-dot i:nth-child(4) {
  left: 0;
  bottom: 0;
  animation-delay: 1.2s;
}

.ant-spin-sm .ant-spin-dot {
  width: 14px;
  height: 14px;
}

.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}

.ant-spin-lg .ant-spin-dot {
  width: 32px;
  height: 32px;
}

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
body .ant-spin-nested-loading {
  margin-bottom: 32px;
}
body .ant-spin-nested-loading + .ant-pagination {
  margin-top: -16px;
}

