.header {
  z-index: 1040;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  background: var(--sally-header-background);
  box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);
  height: 54px;
  margin: 0;
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header .main-menu {
  width: 100%;
  height: 54px;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}
.header .main-menu .header-logo, .header .main-menu .header-logo-multiple {
  color: var(--sally-logo-text);
  margin-right: 20px;
}
.header .main-menu .header-logo svg {
  width: 150px;
  height: auto;
}
.header .main-menu .header-logo svg .logo-fill {
  fill: var(--sally-logo-text);
}
.header .main-menu .header-logo-multiple {
  text-decoration: 1px underline;
  border-bottom: 2px solid var(--sally-header-background);
  font-weight: bold;
  line-height: 52px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header .main-menu .ant-menu.ant-menu-horizontal {
  background-color: var(--sally-header-background);
  line-height: var(--sally-header-line-height);
  font-size: 16px;
  font-weight: var(--sally-header-weight);
  color: var(--sally-header-text);
  border-color: var(--sally-header-background);
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item {
  padding: 0;
  border: none;
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item > a, .header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item > div {
  margin: var(--sally-header-item-vertical-margin) var(--sally-header-item-horizontal-margin);
  padding: 0 var(--sally-header-item-horizontal-padding);
  border-radius: var(--sally-header-item-radius);
  text-decoration: none;
  color: var(--sally-header-text);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item > a .icon-fill, .header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item > div .icon-fill {
  fill: var(--sally-header-text);
  transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item:hover > a, .header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item:hover > div,
.header .main-menu .header-logo-multiple:hover > a,
.header .main-menu .header-logo-multiple:hover > div {
  background-color: var(--sally-header-item-hover-background);
  color: var(--sally-header-item-hover-text);
  text-decoration: none;
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item:hover > a .icon-fill, .header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item:hover > div .icon-fill,
.header .main-menu .header-logo-multiple:hover > a .icon-fill,
.header .main-menu .header-logo-multiple:hover > div .icon-fill {
  fill: var(--sally-header-item-hover-text);
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item-active > a, .header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item-active > div,
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item-selected > a,
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item-selected > div {
  background-color: var(--sally-header-item-selected-background);
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item > a:hover {
  text-decoration: none;
}
.header .main-menu .ant-menu.ant-menu-horizontal > .ant-menu-item .icon {
  margin: 0 8px -4px 0;
}
.header .right-menu {
  width: 180px;
  display: flex;
  justify-content: flex-end;
}
.header .right-menu .drop-down-menu, .header .right-menu .header-user-menu-button {
  cursor: pointer;
}
.header .right-menu .drop-down-menu .icon-fill, .header .right-menu .header-user-menu-button .icon-fill {
  fill: var(--sally-header-text);
}
.header .right-menu .drop-down-menu {
  margin-right: 15px;
  padding: 18px 5px 0;
}
.header .right-menu .drop-down-menu.ant-popover-open {
  background-color: var(--sally-header-item-selected-background);
}
.header .header-user-menu-button .user-image {
  background-color: var(--sally-header-user-image-background);
  color: var(--sally-header-user-image-text);
  margin-top: 8px;
}
.header .notification-button {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  margin-right: 20px;
  padding: 6px 10px;
  vertical-align: top;
  cursor: pointer;
}
.header .notification-button .icon-fill {
  fill: var(--sally-header-text);
}
.header .notification-button .notification-badge {
  position: absolute;
  top: 3px;
  right: 7px;
  padding: 0 3px;
  background-color: var(--sally-alert-background);
  border-radius: 50%;
  font-size: 8px;
  font-weight: 700;
  color: var(--sally-alert-text);
}

.header-user-menu-popover {
  right: 0;
  left: auto !important;
  margin-top: 0;
  padding-top: 6px;
}
.header-user-menu-popover .ant-popover-content {
  margin-top: -10px;
}

.header-dropdown-menu-popover .ant-popover-content {
  margin-top: -12px;
}

.header-user-menu-popover .ant-popover-arrow, .header-dropdown-menu-popover .ant-popover-arrow {
  display: none;
}
.header-user-menu-popover .ant-popover-inner, .header-dropdown-menu-popover .ant-popover-inner {
  background-color: transparent;
  background-clip: border-box;
  border-radius: 0;
  box-shadow: none;
}
.header-user-menu-popover .ant-popover-inner-content, .header-dropdown-menu-popover .ant-popover-inner-content {
  padding: 0;
}

.header-user-menu.ant-menu {
  overflow-x: hidden;
}
.header-user-menu.ant-menu .ant-menu-item {
  height: 44px;
  line-height: 44px;
  padding: 0 24px 0 20px;
}
.header-user-menu.ant-menu .ant-menu-item > a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: var(--sally-header-text);
}
.header-user-menu.ant-menu .ant-menu-item > a .icon-fill {
  fill: var(--sally-header-text);
}
.header-user-menu.ant-menu .ant-menu-item > a .icon, .header-user-menu.ant-menu .ant-menu-item > a .user-image {
  float: left;
  display: block;
}
.header-user-menu.ant-menu .ant-menu-item > a .icon {
  margin: 12px 9px 0 2px;
}
.header-user-menu.ant-menu .ant-menu-item > a .user-image {
  margin: 10px 7px 0 0;
}

.header-user-menu.ant-menu {
  min-width: 350px;
  max-height: 500px;
  background-color: var(--sally-header-background);
  border-right: 0;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(20, 49, 26, 0.18);
}
.header-user-menu.ant-menu .ant-menu-item .search-field .icon {
  margin: 0 10px -5px 0;
}
.header-user-menu.ant-menu .ant-menu-item .search-field .icon .icon-fill {
  fill: var(--sally-header-text);
}
.header-user-menu.ant-menu .ant-menu-item .search-field .ant-input {
  width: 200px;
  height: 28px;
  padding: 8px 8px;
}
.header-user-menu.ant-menu .ant-menu-item .cvr {
  float: right;
  padding-left: 10px;
}
.header-user-menu.ant-menu .ant-menu-item:last-child {
  border-bottom-left-radius: 4px;
}
.header-user-menu.ant-menu .ant-menu-item:hover, .header-user-menu.ant-menu .ant-menu-item.ant-menu-item-active {
  background-color: var(--sally-header-item-hover-background);
}
.header-user-menu.ant-menu .ant-menu-item:hover .icon-fill, .header-user-menu.ant-menu .ant-menu-item.ant-menu-item-active .icon-fill {
  fill: var(--sally-header-item-hover-text);
  transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header-user-menu.ant-menu .ant-menu-item:hover > a, .header-user-menu.ant-menu .ant-menu-item:hover > div, .header-user-menu.ant-menu .ant-menu-item.ant-menu-item-active > a, .header-user-menu.ant-menu .ant-menu-item.ant-menu-item-active > div {
  color: var(--sally-header-item-hover-text);
  background-color: transparent;
}
.header-user-menu.ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: var(--sally-header-item-selected-background);
}
.header-user-menu.ant-menu .ant-menu-item.ant-menu-item-selected > a, .header-user-menu.ant-menu .ant-menu-item.ant-menu-item-selected > div {
  background-color: transparent;
}
.header-user-menu.ant-menu .ant-menu-item-divider {
  height: 14px;
  margin: 0;
  border-top: 1px solid var(--sally-header-submenu-divider);
  background-color: var(--sally-header-submenu-divider);
}

body .header-dropdown-menu.ant-menu {
  overflow-x: hidden;
  min-width: 150px;
  background-color: var(--sally-header-background);
  line-height: var(--sally-header-line-height);
  font-size: 16px;
  font-weight: var(--sally-header-weight);
  color: var(--sally-header-text);
  border-color: var(--sally-header-background);
}
body .header-dropdown-menu.ant-menu .ant-menu-item {
  padding: 0 24px 0 20px;
  line-height: var(--sally-header-line-height);
  font-size: 16px;
  height: auto;
}
body .header-dropdown-menu.ant-menu .ant-menu-item .icon {
  margin: 0 8px -4px 0;
}
body .header-dropdown-menu.ant-menu .ant-menu-item > a {
  margin: var(--sally-header-item-vertical-margin) var(--sally-header-item-horizontal-margin);
  padding: 0 var(--sally-header-item-horizontal-padding);
  border-radius: var(--sally-header-item-radius);
  text-decoration: none;
  color: var(--sally-header-text);
}
body .header-dropdown-menu.ant-menu .ant-menu-item > a .icon-fill {
  fill: var(--sally-header-text);
  transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
body .header-dropdown-menu.ant-menu .ant-menu-item.ant-menu-item-active, body .header-dropdown-menu.ant-menu .ant-menu-item:hover {
  background: inherit;
}
body .header-dropdown-menu.ant-menu .ant-menu-item.ant-menu-item-active > a, body .header-dropdown-menu.ant-menu .ant-menu-item:hover > a {
  background-color: var(--sally-header-item-hover-background);
  color: var(--sally-header-item-hover-text);
  text-decoration: none;
}
body .header-dropdown-menu.ant-menu .ant-menu-item.ant-menu-item-active > a .icon-fill, body .header-dropdown-menu.ant-menu .ant-menu-item:hover > a .icon-fill {
  fill: var(--sally-header-item-hover-text);
}
body .header-dropdown-menu.ant-menu .ant-menu-item.ant-menu-item-selected > a {
  background-color: var(--sally-header-item-selected-background);
}
body .header-dropdown-menu.ant-menu .ant-menu-item-divider {
  height: 14px;
  margin: 0;
  border-top: 1px solid var(--sally-header-submenu-divider);
  background-color: var(--sally-header-submenu-divider);
}

