.ant-select-selection-selected-value .integration-features {
  display: none;
}

.ant-select-dropdown-menu-item .integration-features {
  float: right;
}

.integration-import-button-row {
  margin-bottom: 20px;
}
.integration-import-button-row .ant-btn:not(:first-of-type) {
  margin-left: 25px;
}

.employees-import-list .ant-table th, .employees-import-list .ant-table td, .time-registration-integration-import-list .ant-table th, .time-registration-integration-import-list .ant-table td, .leave-registration-integration-import-list .ant-table th, .leave-registration-integration-import-list .ant-table td {
  white-space: normal;
  word-break: normal;
}
.employees-import-list .ant-table th.include, .employees-import-list .ant-table td.include, .time-registration-integration-import-list .ant-table th.include, .time-registration-integration-import-list .ant-table td.include, .leave-registration-integration-import-list .ant-table th.include, .leave-registration-integration-import-list .ant-table td.include {
  max-width: 25px;
  padding-left: 12px !important;
  padding-right: 0;
}
.employees-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td, .time-registration-integration-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td, .leave-registration-integration-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.employees-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td.email, .time-registration-integration-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td.email, .leave-registration-integration-import-list .ant-table .ant-table-body .ant-table-tbody > tr > td.email {
  max-width: 150px;
}
.employees-import-list .ant-table .employees-import-error, .employees-import-list .ant-table .time-registration-integration-error, .employees-import-list .ant-table .leave-registration-integration-error, .time-registration-integration-import-list .ant-table .employees-import-error, .time-registration-integration-import-list .ant-table .time-registration-integration-error, .time-registration-integration-import-list .ant-table .leave-registration-integration-error, .leave-registration-integration-import-list .ant-table .employees-import-error, .leave-registration-integration-import-list .ant-table .time-registration-integration-error, .leave-registration-integration-import-list .ant-table .leave-registration-integration-error {
  color: var(--sally-status-failure-text-on-white);
}
.employees-import-list .ant-table .employees-import-error .icon-fill, .employees-import-list .ant-table .time-registration-integration-error .icon-fill, .employees-import-list .ant-table .leave-registration-integration-error .icon-fill, .time-registration-integration-import-list .ant-table .employees-import-error .icon-fill, .time-registration-integration-import-list .ant-table .time-registration-integration-error .icon-fill, .time-registration-integration-import-list .ant-table .leave-registration-integration-error .icon-fill, .leave-registration-integration-import-list .ant-table .employees-import-error .icon-fill, .leave-registration-integration-import-list .ant-table .time-registration-integration-error .icon-fill, .leave-registration-integration-import-list .ant-table .leave-registration-integration-error .icon-fill {
  fill: var(--sally-status-failure-text-on-white);
}
.employees-import-list .ant-table .employees-import-warning, .time-registration-integration-import-list .ant-table .employees-import-warning, .leave-registration-integration-import-list .ant-table .employees-import-warning {
  color: var(--sally-status-warning-text-on-white);
}
.employees-import-list .ant-table .employees-import-warning .icon-fill, .time-registration-integration-import-list .ant-table .employees-import-warning .icon-fill, .leave-registration-integration-import-list .ant-table .employees-import-warning .icon-fill {
  fill: var(--sally-status-warning-text-on-white);
}
.employees-import-list .ant-table .time-registration-integration-note, .employees-import-list .ant-table .leave-registration-integration-note, .time-registration-integration-import-list .ant-table .time-registration-integration-note, .time-registration-integration-import-list .ant-table .leave-registration-integration-note, .leave-registration-integration-import-list .ant-table .time-registration-integration-note, .leave-registration-integration-import-list .ant-table .leave-registration-integration-note {
  color: var(--sally-status-info-text-on-white);
}
.employees-import-list .ant-table .time-registration-integration-note .icon-fill, .employees-import-list .ant-table .leave-registration-integration-note .icon-fill, .time-registration-integration-import-list .ant-table .time-registration-integration-note .icon-fill, .time-registration-integration-import-list .ant-table .leave-registration-integration-note .icon-fill, .leave-registration-integration-import-list .ant-table .time-registration-integration-note .icon-fill, .leave-registration-integration-import-list .ant-table .leave-registration-integration-note .icon-fill {
  fill: var(--sally-status-warning-text-on-white);
}

.data-integration-container .title-menu {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-between;
  align-items: flex-end;
}
.data-integration-container .title-menu .asynchronous-task-selector {
  width: 400px;
}
.data-integration-container .h1 {
  margin-bottom: calc(24px + 15px);
}
.data-integration-container .button-row-wrapper {
  display: flex;
  align-items: baseline;
}
.data-integration-container .button-row-wrapper .ant-btn + .ant-btn {
  margin-left: 10px;
}

