body .accounting-vouchers {
  .title-menu {
    display: flex;
    flex-direction: row;

    & > a, & > span, & > .ant-btn, & > div {
      & + * {
        margin-left: 20px;
      }
    }
    label {
      margin-top: 8px;
      display: inline-block;
    }
  }
  .voucher-row {
    display: flex;

    .ant-btn {
      min-height: 32px;
      padding-top: 5px;
      padding-right: 25px;

      .icon {
        margin-left: -10px;
      }
      .icon + span {
        margin-left: -5px;
      }
    }
  }
  .ant-tooltip-close, .ant-tooltip-open {
    cursor: help;
  }
}