.ant-menu {
  outline: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  z-index: 1050;
  box-shadow: 0 1px 6px var(--sally-menu-shadow);
  color: var(--sally-menu-item-text);
  background: var(--sally-menu-background);
  line-height: 46px;
  transition: background .3s, width .2s;

  &.ant-menu-hidden {
    display: none;
  }
  .ant-menu-item,
  .ant-menu-submenu,
  .ant-menu-submenu-title {
    cursor: pointer;
  }
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-submenu-title {
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: var(--sally-menu-item-active);
  }
  .ant-menu-submenu .ant-menu-sub {
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item > a {
    display: block;
    color: var(--sally-menu-item-text);
    .icon-fill {
      fill: var(--sally-menu-item-text);
    }

    &:focus {
      text-decoration: none;
    }

    &:before {
      position: absolute;
      background-color: transparent;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
    }
  }
  .ant-menu-item .ant-menu .ant-menu-item:not(.ant-menu-item-selected) > a {
    color: var(--sally-menu-submenu-item-text);
    .icon-fill {
      fill: var(--sally-menu-submenu-item-text);
    }
  }
  .ant-menu-item > a:hover,
  .ant-menu-item .ant-menu .ant-menu-item:not(.ant-menu-item-selected):hover > a,
  .ant-menu-item .ant-menu .ant-menu-item:not(.ant-menu-item-selected) > a:hover {
    background-color: var(--sally-menu-item-hover);
    color: var(--sally-menu-item-hover-text);
    .icon-fill {
      fill: var(--sally-menu-item-hover-text);
    }
  }
  .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    background-color: var(--sally-menu-divider);
    line-height: 0;
  }
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background-color: var(--sally-menu-item-hover);
    color: var(--sally-menu-item-hover-text);
    .icon-fill {
      fill: var(--sally-menu-item-hover-text);
    }
  }
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active {
    color: var(--sally-menu-item-active-text);
    .icon-fill {
      fill: var(--sally-menu-item-active-text);
    }
  }
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    z-index: 1050;
  }
  &.ant-menu-horizontal .ant-menu-item,
  &.ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px;
  }
  &.ant-menu-horizontal > .ant-menu-item:hover,
  &.ant-menu-horizontal > .ant-menu-item-active,
  &.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
  }
  &.ant-menu-horizontal {
    border: 0;
    border-bottom: 2px solid var(--sally-menu-item);
    box-shadow: none;
    z-index: 0;
  }
  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    float: left;
    border-bottom: 2px solid transparent;
  }
  &.ant-menu-horizontal > .ant-menu-item:hover,
  &.ant-menu-horizontal > .ant-menu-submenu:hover {
    border-bottom: 2px solid var(--sally-menu-item-hover-text);
    color: var(--sally-menu-item-hover-text);

    .icon-fill {
      fill: var(--sally-menu-item-hover-text);
    }
  }
  &.ant-menu-horizontal > .ant-menu-item-active,
  &.ant-menu-horizontal > .ant-menu-submenu-active,
  &.ant-menu-horizontal > .ant-menu-item-open,
  &.ant-menu-horizontal > .ant-menu-submenu-open,
  &.ant-menu-horizontal > .ant-menu-item-selected,
  &.ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid var(--sally-menu-item-active-text);
    color: var(--sally-menu-item-active-text);
    .icon-fill {
      fill: var(--sally-menu-item-active-text);
    }
  }
  &.ant-menu-horizontal > .ant-menu-item > a:hover,
  &.ant-menu-horizontal > .ant-menu-submenu > a:hover {
    color: var(--sally-menu-item-hover-text);
    .icon-fill {
      fill: var(--sally-menu-item-hover-text);
    }
  }
  &.ant-menu-horizontal:after {
    content: "\20";
    display: block;
    height: 0;
    clear: both;
  }
  &.ant-menu-horizontal,
  .ant-menu-inline,
  .ant-menu-vertical {
    z-index: auto;
  }
}
.ant-menu-item-selected,
.ant-menu-item-selected > a,
.ant-menu-item-selected:hover,
.ant-menu-item-selected:hover > a,
.ant-menu-item-selected > a:hover {
  background-color: var(--sally-menu-item-active);
  color: var(--sally-menu-item-active-text);
  .icon-fill {
    fill: var(--sally-menu-item-active-text);
  }
}
.ant-menu-inline,
.ant-menu-vertical {
  border-right: 1px solid var(--sally-menu-divider);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
  margin-left: -1px;
  left: 1px;
  position: relative;
  z-index: 1;
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 3px solid var(--sally-menu-divider);
  -ms-transform: scaleY(0.0001);
      transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-vertical.ant-menu-sub {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item:last-child,
.ant-menu-vertical.ant-menu-sub > .ant-menu-item-group:last-child > .ant-menu-item-group-list:last-child > .ant-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item:only-child {
  border-radius: 4px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected:after,
.ant-menu-inline .ant-menu-item-selected:after {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
  -ms-transform: scaleY(1);
      transform: scaleY(1);
}
.ant-menu-submenu-horizontal > .ant-menu {
  top: 100%;
  left: 0;
  position: absolute;
  min-width: 100%;
  margin-top: 7px;
  z-index: 1050;
}
.ant-menu-submenu-vertical {
  z-index: 1;
}
.ant-menu-submenu-vertical > .ant-menu {
  top: 0;
  left: 100%;
  position: absolute;
  min-width: 160px;
  margin-left: 4px;
  z-index: 1050;
}
.ant-menu-item,
.ant-menu-submenu-title {
  margin: 0;
  padding: 0 20px;
  position: relative;
  display: block;
  white-space: nowrap;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 8px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: var(--sally-menu-divider);
}
.ant-menu-submenu {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  background-color: var(--sally-menu-background);
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title:after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  font-family: "anticon" !important;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  content: "\e61d";
  right: 16px;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -ms-transform: rotate(270deg) scale(0.75);
      transform: rotate(270deg) scale(0.75);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:after {
  font-family: "anticon" !important;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  content: "\e61d";
  right: 16px;
  top: 0;
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  -ms-transform: scale(0.66666667) rotate(0deg);
      transform: scale(0.66666667) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
}
:root .ant-menu-submenu-inline > .ant-menu-submenu-title:after {
  -webkit-filter: none;
          filter: none;
}
:root .ant-menu-submenu-inline > .ant-menu-submenu-title:after {
  font-size: 12px;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -ms-transform: rotate(180deg) scale(0.75);
      transform: rotate(180deg) scale(0.75);
}
.ant-menu-vertical .ant-menu-submenu-selected {
  color: var(--sally-menu-item-active-text);

  .icon-fill {
    fill: var(--sally-menu-item-active-text);
  }
}
.ant-menu-vertical .ant-menu-submenu-selected > a {
  color: var(--sally-menu-item-active-text);

  .icon-fill {
    fill: var(--sally-menu-item-active-text);
  }
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 16px;
  font-size: 12px;
  line-height: 42px;
  height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline-collapsed {
  width: 64px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  text-overflow: clip;
  padding: 0 24px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item:after,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item:after,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:after {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  font-size: 16px;
  line-height: 42px;
  margin: 0;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: var(--sally-text);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4px;
  padding-right: 4px;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-vertical.ant-menu-sub {
  padding: 0;
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
  list-style-type: disc;
  list-style-position: inside;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
  background: none;
  border-color: transparent !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}