.ant-radio-group.ant-radio-navigator {
  border-radius: 12px;
  background-color: var(--sally-navigator-background);
  color: var(--sally-navigator-text);
  font-weight: 700;
  padding: 4px;
  line-height: 18px;
  font-size: 14px;

  .ant-radio-button-wrapper {
    border-radius: 12px;
    background-color: var(--sally-navigator-background);
    height: var(--sally-input-m-height);
    color: var(--sally-navigator-text);
    border: none;
    padding-top: 2px;

    .icon-fill {
      fill: var(--sally-navigator-text);
    }

    &:not(:first-child)::before {
      display: none;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: var(--sally-navigator-selected-background);
      color: var(--sally-navigator-selected-text);

      .icon-fill {
        fill: var(--sally-navigator-selected-text);
      }
    }

    &:hover {
      background-color: var(--sally-navigator-hover-background);
      color: var(--sally-navigator-hover-text);

      .icon-fill {
        fill: var(--sally-navigator-hover-text);
      }
    }
  }

  &.ant-radio-navigator-l {
    .ant-radio-button-wrapper {
      height: var(--sally-input-l-height);
      padding-top: 6px;
    }
  }
}